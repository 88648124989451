import { useRef } from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import { diet_summary_texts, FoodRecomendation } from './constants';
import { ArrowDownCircleFill } from 'react-bootstrap-icons';

export type DietSummaryItemProps = {
  recommendationName: 'Prefiere' | 'Reduce' | 'Evita',
  foods: string[],
  classProp: FoodRecomendation,
  maxAvoid: string[]
}


function DietSummaryItem(props: DietSummaryItemProps) {
  const {
    recommendationName,
    foods,
    classProp,
    maxAvoid
  } = props;

  const scrollableRef = useRef<HTMLDivElement>(null);

  const scrollHandler = () => {
    if (scrollableRef.current) {
      scrollableRef.current.scrollBy({
        top: scrollableRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className="summary-card mb-4 pb-2" key={'card-recomendation-' + classProp}>
      <div className='summary-item'>
        <div className={"d-flex justfy-content-center title " + classProp} >
          <span className={"title-box p-2 m-1"}>
            {recommendationName}
          </span>
        </div>
        <div className='d-flex flex-column justify-content-between align-items-center'>
          <div className='fill-text'>
            <Markdown remarkPlugins={[remarkGfm]}>{diet_summary_texts[classProp]?.description}</Markdown>
          </div>
          <div className='d-flex justify-content-center' style={{ position: 'relative', width: '90%' }}>
            <div className='item-container' ref={scrollableRef}>
              {Object.keys(diet_summary_texts[classProp]).includes('subsubtitle')
                && maxAvoid.length > 0 &&
                <div className='sub-text'>
                  {diet_summary_texts[classProp]?.subsubtitle}
                </div>
              }
              {maxAvoid.length > 0 && <>
                <div className='wrapper'>
                  {maxAvoid.map((meal: string, index) => (<div className={classProp + '-text'} key={meal + index}>{meal}</div>))}
                </div>
              </>}
              {Object.keys(diet_summary_texts[classProp]).includes('subtitle') &&
                <div className='sub-text'>
                  {diet_summary_texts[classProp]?.subtitle}
                </div>
              }
              <div className='wrapper'>
                {foods.map((meal: string, index: number) => {
                  return (<div className={classProp + '-text'} key={meal + index}>{meal}</div>)
                })}
              </div>
            </div>
            {foods.length > 5 &&
              <div className='scroll-arrow'>
                <ArrowDownCircleFill size={30} className='color-gray' onClick={scrollHandler} />
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default DietSummaryItem;