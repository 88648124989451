import Stack from "react-bootstrap/esm/Stack";

import { ArrowDownShort, ArrowUpShort, CheckAll } from "react-bootstrap-icons";

import { adaptationLevels } from "../../constants";

interface AdaptationHeaderProps {
  level: adaptationLevels;
  title: string;
  inverseIcon?: boolean;
  showIcon: boolean
}


export default function AdaptationHeaderComponent(props: AdaptationHeaderProps) {
  const { level, title, inverseIcon, showIcon } = props;

  return (
    <div className="adaptation-header-component">
      <Stack direction="horizontal" gap={2} className="div-stack">
        {showIcon ?
          <div className="level">
            {level && [adaptationLevels.VL, adaptationLevels.L].includes(level)
              ? <> {!inverseIcon ? <ArrowUpShort /> : <ArrowDownShort />} </>
              : [adaptationLevels.VH, adaptationLevels.H].includes(level)
                ? <> {!inverseIcon ? <ArrowDownShort /> : <ArrowUpShort />} </>
                : <CheckAll />
            }
          </div>
          : null}
        <div> {title} </div>
      </Stack>
    </div>
  )
}