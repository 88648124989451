export type VideoComponentProps = {
  src?: string;
  videoTitle?: string;
  headerText?: string;
  subHeaderText?: string;
  bottomText1?: string;
  bottomText2?: string;

}

export default function VideoComponent(props: VideoComponentProps) {
  const {
    src,
    videoTitle,
    headerText,
    subHeaderText,
    bottomText1,
    bottomText2
  } = props;

  return (
    <div className='video-component'>
      <div className="video-text-container p-2">
        <div className="video-header main-text">{headerText}</div>
        <div className="video-subheader sub-text">{subHeaderText}</div>
      </div>

      <div className="flex-c p-0 m-0">
        <div className='wrapper'>
          <iframe
            src={src}
            title={videoTitle}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
        <div className="video-subtext-container">
          {bottomText1 && <div className="sub-text video-bottom-text capitalized spaced-2">{bottomText1}</div>}
          {bottomText2 && <div className="sub-text video-bottom-text spaced-3">{bottomText2}</div>}
        </div>
      </div>
    </div>
  )
}