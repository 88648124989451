import molecule from "@/assets/icons/landing-icons/lab-info/molecule.png";
import engineer from "@/assets/icons/landing-icons/lab-info/engineer.png";
import team from "@/assets/icons/landing-icons/lab-info/team.png";

import { GeoAltFill } from 'react-bootstrap-icons';

export default function InfoLab() {
  return (
    <div className="info-lab">
      <div className='flex-c align-items-start'>
        <div className="d-flex align-items-center pill capitalized spaced-2">
          <GeoAltFill className='m-2' />
          <div>Laboratorio en chile</div>
        </div>

        <div className="info-lab-subtitle subsub-text flex-container flex-c align-items-start">
          SECUENCIACIÓN LOCAL. MÁS RÁPIDO, MÁS BARATO, MÁS SEGURO
        </div>

      </div>
      <div className="flex-container margin-negative">
        <div className="bg-p1 width-responsive">
          <div className='flex-c p-2 cards'>
            <div className="info-lab-title">laboratorio i+d</div>
            <div className='flex-container content-center'>
              <img src={molecule} alt="atom" className='icon m-2' />
              <div className="info-lab-text flex-c">
                <div className="text-content">Última tecnología de secuenciación</div>
                <div className="text-content">Programa bioinformático</div>
                <div className="text-content">desarrollado internamente por Mynu</div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-p2 width-responsive">
          <div className='flex-c p-2 cards'>
            <div className="info-lab-title">equipo</div>
            <div className='flex-container content-center'>
              <div className="info-lab-text flex-c content-center">
                <img src={engineer} alt="atom" className='icon m-2' />
                <div className="text-content">+15</div>
                {
                  ["Científicos", "Nutricionistas", "Ingenieros"].map((text, index) => (
                    <div className="text-content" key={index}>{text}</div>
                  ))
                }
              </div>
              <div className="info-lab-text flex-c content-center">
                <img src={team} alt="atom" className='icon m-2' />
                <div className="text-content">+7</div>
                <div className="text-content">Científicos</div>
                <div className="text-content">PhD</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}