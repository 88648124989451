import { useEffect } from "react";
import { useStateStore } from "@/store/store";
import { loadDietSummary } from "@/store/loader";

import DietSummaryItem from "./DietSummaryItem";
import Spinner from "@/components/Spinner/Spinner";

import { isEmptyOrUndefined } from "@/utils";
import { FoodRecomendation } from "./constants";

import './DietSummaryComponent.scss';

export type NutritionalRecommendation = {
  preferTags: string[],
  reduceTags: string[],
  avoidTags: string[],
  maxAvoidTags: string[],
}

export default function DietSummaryComponent({ setNotificationText }: { setNotificationText: (t: string) => void }) {
  const store = useStateStore();
  const {
    isLoadingDietSummary,
    isLoadingDiet,
    dietSummary,
  } = store;

  const summaryNotAvailable = (d: NutritionalRecommendation | null): d is null => {
    return isEmptyOrUndefined(d, 'obj');
  }

  useEffect(() => {
    const fetchSummary = async () => {
      try {
        await loadDietSummary(store);
      } catch {
        setNotificationText('Ha ocurrido un error cargando tu Guía Nutricional');
      }
    }

    if (!isLoadingDiet || summaryNotAvailable(dietSummary)) {
      fetchSummary();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <div className='summary-container'>
      {isLoadingDietSummary || summaryNotAvailable(dietSummary) ?
        <div className="summary-loading">
          <Spinner size="medium" />
        </div>
        :
        <>
          <DietSummaryItem
            classProp={FoodRecomendation.PREFER}
            recommendationName="Prefiere"
            foods={dietSummary.preferTags}
            maxAvoid={[]}
          />
          <DietSummaryItem
            classProp={FoodRecomendation.REDUCE}
            recommendationName="Reduce"
            foods={dietSummary.reduceTags}
            maxAvoid={[]}
          />
          <DietSummaryItem
            classProp={FoodRecomendation.AVOID}
            recommendationName="Evita"
            foods={dietSummary.avoidTags}
            maxAvoid={dietSummary.maxAvoidTags}
          />
        </>
      }
    </div>
  )
}