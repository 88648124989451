import { StateCreator } from 'zustand';
import { ResultsStore } from '../interfaces/results';
import { AppState } from '../interfaces';
import { InterpretedResults } from '@/models';

export const createResultsSlice: StateCreator<
  AppState,
  [],
  [],
  ResultsStore
> = (set, get) => ({
  resultsData: null,
  setResultsData: (r: InterpretedResults | null) => set(() => ({ resultsData: r})),
  isLoadingResults: false,
  setIsLoadingResults: (l: boolean) => set(() => ({ isLoadingResults: l })),
  resultStatus: '',
  setResultStatus: (s: string) => set(() => ({ resultStatus: s })),
  resultMealsRestrictions: { allowed: [], reduced: [], avoided: [] },
  setResultMealsRestrictions: (r: string[][]) => set(() => ({ resultMealsRestrictions: { allowed: r[0], reduced: r[1], avoided: r[2] } })),
  hasResults: () => !!get().resultsData,
});