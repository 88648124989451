import { FesMeal } from "@/models/fes-model";
import { XCircleFill } from "react-bootstrap-icons";
import RenderRecipeScore from "./RenderRecipeScore";

export const RenderFoodRecommendation = (
  { selectedIds, meals, group, handleClick }:
    {
      meals: FesMeal[],
      selectedIds: string[],
      group: string,
      handleClick: (id: string) => void
    }
) => {
  return (
    <div className="fes-search-component-body" >
      {selectedIds.map((id, index) => {
        let meal = meals.find(meal => meal.recipeId === id);
        let foodName = meal?.recipeName;
        let foodGroup1 = meal?.foodGroup1;
        let foodGroup2 = meal?.foodGroup2;
        let foodGroup3 = meal?.foodGroup3;
        if (foodName && foodName.length > 0
          && ([foodGroup1, foodGroup2, foodGroup3].includes(group) || group === 'Todo' || !group)
        ) {
          return (
            meal ?
              <div className="fes-portions-container selected-item mynu-outline" key={`${foodName}-${group}-${index}`} onClick={() => handleClick(id)} >
                <div className="text-capitalize recipe-name">
                  {foodName}
                </div>

                <RenderRecipeScore score={meal.score} size={'15px'} />

                <div className="portion-amount">
                  <XCircleFill className="mynu-stars point" size={20} />
                </div>
              </div>
              : null
          )
        }
        return null;
      })}
    </div>
  )
}