import { axiosInstance } from "@/api/http";
import { Results } from "@/models";

export async function getResults(userId: string, userToken: string): Promise<Results | null> {
  const headers = {
    "Authorization": `Bearer ${userToken}`,
  }

  try {
    const response = await axiosInstance.get(`/results/${userId}`, { headers });
    const results: Results = response.data;
    return results;
  } catch (e) {
    return null;
  }
}