import { FadeLoader } from 'react-spinners';

import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Button from 'react-bootstrap/esm/Button';

import { plans } from '@/constants/'

import PlanIncludedImage from '@/assets/icons/plan-included.svg';
import PlanNotIncludedImage from '@/assets/icons/plan-not-included.svg';

import './PlanDetails.scss';
import { PlanDetail, PricingDetail } from '@/models';

type PlanDetailsProps = {
  type: string;
  highlight?: boolean;
  periodicity: string;
  isLoading: boolean;
  onAccept: (planId: number) => void;
}

function PlanDetails({ type, highlight, periodicity, isLoading, onAccept }: PlanDetailsProps) {
  return (
    <div className={`plan-details ${(type === 'premium-kit' || highlight) && 'highlighted-plan'}`}>
      <div className="plan-details-header">
        {type.includes('premium') && (
          <div className="badge badge-premium mb-3">
            <span className="badge-text">{plans[type].badge}</span>
          </div>
        )}
        {!type.includes('premium') && (
          <div className="badge mb-3">
            <span className="badge-text">{plans[type].badge}</span>
          </div>
        )}
        <p className="plan-details-title">{plans[type].title}</p>
        <Row className='plan-details-pricing'>
          {plans[type].pricing[periodicity].map((price: PricingDetail) => {
            return (
              <Col className='plan-details-pricing-info' xl={12 / plans[type].pricing[periodicity].length} key={type + periodicity + price.description}>
                <Row className='plan-details-pricing-row'><p className='plan-details-pricing-price'>{price.price}</p></Row>
                <Row className='plan-details-pricing-row'><p className='plan-details-pricing-details'>{price.description}</p></Row>
              </Col>
            )
          })}
        </Row>
      </div>
      <div className="plan-details-body">
        <div className="plan-details-body-list">
          <ul>
            {plans[type].details.map((item: PlanDetail) => {
              return (
                <li key={item.description}>
                  <span className='icon'><img src={item.included ? PlanIncludedImage : PlanNotIncludedImage} alt="included" width={20} /></span>
                  <span className="badge-text">{item.description}</span>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
      <div className="plan-details-footer">
        {isLoading && (
          <FadeLoader
            color="#34B391"
            loading={true}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        )}
        {!isLoading && (
          <Button
            variant={(type === 'premium-kit' || highlight) ? "primary" : "outline-primary"}
            className="plan-details-body-button-button"
            onClick={() => onAccept(plans[type].identifiers[periodicity])}
          >
            Comprar
          </Button>
        )}
      </div>
    </div>
  )
}

export default PlanDetails;
