import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

export type TableRowComponentProps = {
  title: string,
  id?: string,
  value?: string | JSX.Element,
  hr?: boolean
}

export const TableRowComponent: React.ComponentType<TableRowComponentProps> = (props) => {
  const { title, value, hr=false, id='' } = props;
  return(
    <>
      <Row id={id}>
        <Col sm="6" xs={6}> <span>{title}</span> </Col>
        <Col sm="6" xs={6}>
          { value && typeof value === 'string' ?
            <span className="text-disabled" >{value}</span>
            : value
          }
        </Col>
      </Row>
      { hr && <hr/>}
    </>
  )
}