import { useMemo } from "react";
import Card from "react-bootstrap/esm/Card";
import AdaptationHeaderComponent from "./AdaptationHeader";
import { adaptationLevels } from "../../constants";

interface DailyConsumptionscoreProps {
  score: string;
  lackingSubcategories: string[]
}

export default function DailyConsumptionScoreComponent(props: DailyConsumptionscoreProps) {
  const { score, lackingSubcategories } = props;
  let scoreNumber = parseFloat(score);

  const r = 40;
  const border = 15;
  const c = useMemo(() => 2 * Math.PI * r, []);
  const sLength = useMemo(() => scoreNumber / 10 * c, [scoreNumber, c])
  const rLength = useMemo(() => c - sLength, [c, sLength])

  return (
    <Card className="mynu-shadow">
      <Card.Header>
        Puntuación total del día
      </Card.Header>
      <Card.Body>
        <div className="daily-consumption-score">
          <div className="donut-container">
            <div className="donut">
              <svg viewBox="0 0 100 100">
                {/* magic */}
                <filter id="inset-shadow" x="-50%" y="-50%" width="200%" height="200%">
                  <feComponentTransfer in="SourceAlpha">
                    <feFuncA type="table" tableValues="1 0" />
                  </feComponentTransfer>
                  <feGaussianBlur stdDeviation="4" />
                  <feOffset dx="0" dy="5" result="offsetblur" />
                  <feFlood floodColor="rgb(0, 0, 0, 0.3)" result="color" />
                  <feComposite in2="offsetblur" operator="in" />
                  <feComposite in2="SourceAlpha" operator="in" />
                  <feMerge>
                    <feMergeNode in="SourceGraphic" />
                    <feMergeNode />
                  </feMerge>
                </filter>

                <circle
                  id={'donut-inner'} cx="50%" cy="50%" r={r}
                  strokeWidth={border}
                  fill="transparent"
                  stroke="#fff" strokeDasharray="360, 100"
                  filter="url(#inset-shadow)"
                />

                <circle
                  id={'donut-percentage'} cx="50%" cy="50%" r={r}
                  strokeWidth={border}
                  fill="transparent" strokeDasharray={`${sLength}, ${rLength}`}
                />
              </svg>
            </div>
            <div className="score-text">
              <h2> {(scoreNumber).toLocaleString('es-CL')} </h2>
              <h5 style={{ margin: '-10% 0 0' }}> /10 </h5>
            </div>
          </div>

          <div className="recomended-improvement">
            <AdaptationHeaderComponent
              level={lackingSubcategories && lackingSubcategories.length > 0 ?
                adaptationLevels.L : adaptationLevels.M}
              title={'Busca mejorar en'}
              showIcon
            />
            <div className="improve">
              {lackingSubcategories && lackingSubcategories.length > 0 ?
                lackingSubcategories.map((sc, i) =>
                  <div key={`lacks-${sc}-${i}`}>
                    <div>
                      {sc}
                    </div>
                  </div>
                ) : null}
            </div>
          </div>
        </div>

      </Card.Body>
    </Card>
  )
};
