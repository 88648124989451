import { fesNutrientReportType } from "@/models/fes-model";
import { objectives, objectivesMap } from "../../constants";
import { useMemo, useState } from "react";
import { adaptationLevelFromBoundsAndValue } from "@/utils/fes";
import { isEmptyOrUndefined } from "@/utils";
import Card from "react-bootstrap/esm/Card";
import AdaptationHeaderComponent from "./AdaptationHeader";
import ToggleButtonComponent from "@/components/ToggleButton/ToggleButton";
import { EyeFill, EyeSlashFill } from "react-bootstrap-icons";
import Collapse from "react-bootstrap/esm/Collapse";
import { MacronutrientsChart } from "../MacroChart/MacroNutrientsChart";

interface CaloriesComponentProps {
  goal?: objectives;
  calories?: fesNutrientReportType;
}

export default function CaloriesComponent(props: CaloriesComponentProps) {
  const {
    goal,
    calories
  } = props;
  
  const [isVisible, setIsVisible] = useState(false);

  const { value, dailyLb, dailyUb } = calories ?? { value: 0, dailyLb: 0, dailyUb: 0 };
  const dailyConsumptionInterval = useMemo(() => adaptationLevelFromBoundsAndValue(value, dailyLb, dailyUb), [value, dailyLb, dailyUb]);

  return (
    goal && !isEmptyOrUndefined(calories, 'obj') && dailyConsumptionInterval &&
      objectivesMap[goal].calories[dailyConsumptionInterval] ?
      <Card className="mynu-shadow">
        <Card.Header>
          Calorías
        </Card.Header>
        <Card.Body>
          <div className="d-flex flex-column align-items-center">
            <AdaptationHeaderComponent
              level={dailyConsumptionInterval}
              title={objectivesMap[goal].calories[dailyConsumptionInterval].title}
              inverseIcon
              showIcon
            />
            <div className="mb-4">
              <ToggleButtonComponent
                value={isVisible}
                changeValue={setIsVisible}
                on={<EyeFill className="mynu-stars" size={'1rem'} />}
                off={<EyeSlashFill className="mynu-stars" size={'1rem'} />}
              />
            </div>
            <div className="w-100">
              <Collapse in={isVisible}>
                <div>
                  <MacronutrientsChart
                    name={calories.name}
                    value={calories.value}
                    unit={calories.unit}
                    dailyLb={calories.dailyLb}
                    dailyUb={calories.dailyUb}
                    level={dailyConsumptionInterval}
                  />
                </div>
              </Collapse>
            </div>
            {objectivesMap[goal].calories[dailyConsumptionInterval].description !== null ?
              <span className="m-2">
                {objectivesMap[goal].calories[dailyConsumptionInterval].description}
              </span>
              : null}
          </div>
        </Card.Body>
      </Card>
      : null
  )
}
