import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';

import { suggestions } from "./default-messages";

type SuggestedMessagesProps = {
  hasMessages: boolean;
  sendSuggestion: (question: string) => void;
}

function SuggestedMessages({ hasMessages, sendSuggestion }: SuggestedMessagesProps) {

  if (hasMessages) {
    return (
      <div>
        <Container className="summary-suggestion-container">
          <span className="summary-suggestion-text title">Sugerencia de mensajes</span>
          <div>
            {suggestions?.map((suggestion) => {
              return (
                <div
                  key={suggestion.summary}
                  role="button"
                  className="summary-message summary-suggestion-text"
                  onClick={() => sendSuggestion(suggestion.question)}
                >
                  {suggestion.summary}
                </div>
              )
            })}
          </div>
        </Container>
      </div>
    )
  }

  return (
    <div className="suggestion-container pt-5 text-center px-3">
      <Container className="suggested-messages px-0">
        <h2 className="suggestion-title">Sugerencias de mensajes:</h2>
        {suggestions?.map((suggestion) => {
          return (
            <Card
              role="button"
              className="d-block p-2 suggestion-button"
              key={suggestion.summary}
              onClick={() => sendSuggestion(suggestion.question)}
            >
              <span className="button-title">{suggestion.question}</span>
              <br />
              <span className="button-description">{suggestion.description}</span>
            </Card>
          )
        })}
      </Container>
    </div>
  )
}

export default SuggestedMessages;
