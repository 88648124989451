import { Container } from "react-bootstrap";

import InitialLayout from "@/components/InitialLayout/InitialLayout";
import BackButton from "@/components/BackButton/BackButtton";

import MynuBlueLogo from "@/assets/images/mynu-blue-logo.png";

export const NotFoundPage = () => {
  
  return <InitialLayout>
    <Container>
      <div className="mb-2 py-3">
        <BackButton text="Volver atrás" />
      </div>
      <div className="text-center d-lg-none p-3">
          <img src={MynuBlueLogo} className="mobile-logo" alt="" width={100}/>
      </div>
      <div className="mt-3 p-3">
        <div className="mb-2">
          <h1>Ups!</h1>
        </div>
        <div className="mb-2">
          <p>La página que buscas no existe.</p>
          <p>Ir al <a href='/'>Inicio</a></p>
        </div>
      </div>
    </Container>
  </InitialLayout>;
}