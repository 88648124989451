import { useState } from 'react';
import { useLocation } from 'react-router';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import SuccessMessage from '@/components/SuccessMessage/SuccessMessage';
import InitialLayout from '@/components/InitialLayout/InitialLayout';
import FormButton from '@/components/FormButton/FormButton';

import { ConfirmationData } from '@/models';
import { resendConfirmationCode, sendConfirmationCode } from '@/services/auth/auth-service';

import './CodeConfirmation.scss';

const { Group, Label, Control } = Form;

function CodeConfirmation() {
  const [email, setEmail] = useState(localStorage.getItem('userEmail') || '');
  const [code, setCode] = useState('');
  const [verificationError, setVerificationError] = useState('');
  const [success, setSuccess] = useState(false);
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const data: ConfirmationData = {
      username: email,
      code,
    }
    try {
      await sendConfirmationCode(data);
      setSuccess(true);
      if (urlParams.get('testId')) {
        // mandar asociación
      }
    } catch (error: any) {
      setVerificationError(error.message);
    }
  }

  const resendCode = async () => {
    try {
      await resendConfirmationCode({
        username: email
      })
    } catch (error) {
      console.log(error)
    }
  }

  if (success) {
    let redirectUrl = '/login';
    if (urlParams.get('testId')) {
      redirectUrl = `/login?testId=${urlParams.get('testId')}`;
    }
    return (
      <InitialLayout>
        <SuccessMessage text="¡Verificación exitosa!" to={redirectUrl} />
      </InitialLayout>
    )
  }

  return (
    <InitialLayout>
      <div className="section d-flex">
        <div className='alert'>
          <Alert variant="success" className='font-avenir-bold'>
            Hemos enviado el código de verificación a tu correo electrónico.
          </Alert>
        </div>
        <div className="form-container pt-2">
          <h1 className="text-center mb-3">Ingresa código de verificación de cuenta</h1>
          <p className="text-center">Hemos enviado un código de 6 dígitos a tu dirección de correo electrónico registrada.</p>
          <Form noValidate onSubmit={handleSubmit} className="mt-4">
            {(!localStorage.getItem('userEmail')) && (
              <Group className="mb-2" controlId='verificationEmail'>
                <Label className="mb-1">Email:</Label>
                <Control
                  required
                  name="email"
                  type="email"
                  placeholder="Ej: ejemplo@mail.cl"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </Group>
            )}
            <Group className="mb-2" controlId='verificationCode'>
              <Label className="mb-1 ">Ingresa el código</Label>
              <Control
                required
                name="code"
                type="text"
                placeholder="Ej: 123456"
                value={code}
                onChange={(event) => setCode(event.target.value)}
                isInvalid={verificationError ? true : false}
                className={success ? 'is-valid' : ''}
              />
              <Control.Feedback type="invalid">
                Código incorrecto. Por favor, inténtalo de nuevo.
              </Control.Feedback>
            </Group>
            <div className="text-center">
              <FormButton text="Verificar" isDisabled={!code || !email} />
            </div>
          </Form>
          <div className="text-center">
            <p className="note mt-3 mb-2">
              En caso no recibas un mensaje con el código, por favor verificar el correo ingresado y tratar nuevamente.
            </p>
            <Button variant="link" className="resendCodeButton" onClick={resendCode}>
              Reenviar código
            </Button>
          </div>
        </div>
      </div>
    </InitialLayout>

  );
}

export default CodeConfirmation;
