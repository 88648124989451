import { useEffect } from "react";
import { isEmptyOrUndefined } from "@/utils";
import MainLayout from "@/components/MainLayout/MainLayout";
import { useStateStore } from "@/store/store";
import { loadUserInfo } from "@/store/loader";
import { useCurrentUser } from "@/components/AuthContext";
import Card from "react-bootstrap/esm/Card";
import Container from "react-bootstrap/esm/Container";
import DietPortionsComponent from "../components/DietPortionsComponent";


export default function DietPortionsPage() {
  const store = useStateStore();
  const currentUser = useCurrentUser();

  const {
    dietInformation,
    isLoadingDiet,
    dietCreationFailed
  } = store;
  
  useEffect(() => {
    if (currentUser && isEmptyOrUndefined(dietInformation, 'arr')) {
      loadUserInfo(store);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  return <MainLayout>
    <div className="fes-page pt-2 pt-lg-4 px-0">
      <Container className="page-container">

        <Card className="p-4 mb-4 diet-header">
          <h1 className="diet-title">Glosario de porciones</h1>
          <p className="text">
            Aquí encontrarás las definiciones de las unidades de medida de las secciones de My Menú y My Pauta.
          </p>
        </Card>
        {
          !isLoadingDiet && !dietCreationFailed &&
          <Card className="py-4 px-2 mb-4 diet-header">
            <DietPortionsComponent/>
          </Card>
        }
      </Container>
    </div>
  </MainLayout>
}