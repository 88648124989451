  import React, { useState } from "react";

import Accordion from "react-bootstrap/Accordion";

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Row from 'react-bootstrap/Row';
import Stack from 'react-bootstrap/Stack';

import {
  breakfastSnacks, dailyPortionsType, FesMeal,
  FoodGroups, lunchDinner, RecommendedFoodsType
} from "@/models/fes-model";

import { CalculatorFill, DashCircle, PencilSquare, PlusCircleFill, X } from 'react-bootstrap-icons';

import RenderRecipeScore from "./RenderRecipeScore";
import Spinner from "@/components/Spinner/Spinner";
import FesSearchComponent from "./FesSearch";

import { LOWERCASE_DAY, MEAL } from "@/utils/constants";

export interface FesCardProps {
  accordionElementId: string,
  day: LOWERCASE_DAY;
  mealType: MEAL;
  recommendedMealGroups: RecommendedFoodsType[];
  meals: FesMeal[];
  portions: dailyPortionsType;
  canEdit?: boolean;
  calculatePortions?: (day: LOWERCASE_DAY, mealType: MEAL, meals?: string[]) => void;
  loadingPortions: { mealType: MEAL | null, day: LOWERCASE_DAY | null, loading: boolean };
  setMealEditable?: (day: LOWERCASE_DAY, mealType: MEAL) => void;
  changeRecommendedGroups: (group: string, mealType: MEAL, extra: boolean, add: boolean) => void;
  allgroups?: string[] | null,
  extraMealGroups: RecommendedFoodsType[] | null,
}

export default function FesCard(props: FesCardProps) {
  const {
    accordionElementId,
    day,
    mealType,
    recommendedMealGroups,
    meals,
    calculatePortions,
    portions,
    canEdit = true,
    loadingPortions,
    setMealEditable,
    changeRecommendedGroups,
    allgroups,
    extraMealGroups
  } = props;

  const [selectedIds, setSelectedIds] = useState<{ [group: string]: string[] } | null>(null);
  const [extraSelectedIds, setExraSelectedIds] = useState<{ [group: string]: string[] } | null>(null);

  const handleClick = (groupItem: string, clickedItemId: string, extra?: boolean) => {

    if (selectedIds?.[groupItem]?.includes(clickedItemId)) {
      setSelectedIds({
        ...selectedIds,
        [groupItem]: selectedIds[groupItem].filter(id => id !== clickedItemId)
      });
    } else {
      setSelectedIds({
        ...selectedIds,
        [groupItem]: [...(selectedIds?.[groupItem] ?? []), clickedItemId]
      });
    }
  }

  const handleClickExtra = (groupItem: string, clickedItemId: string, extra?: boolean) => {
    if (extraSelectedIds?.[groupItem]?.includes(clickedItemId)) {
      setExraSelectedIds({
        ...extraSelectedIds,
        [groupItem]: extraSelectedIds[groupItem].filter(id => id !== clickedItemId)
      });
    } else {
      setExraSelectedIds({
        ...extraSelectedIds,
        [groupItem]: [...(extraSelectedIds?.[groupItem] ?? []), clickedItemId]
      });
    }
  }

  const isValid = (sIds: { [group: string]: string[] } | null) => {
    let flattenedIds = Object.values(sIds ?? {}).flat();
    return flattenedIds.length > 0;
  }

  const handleCalculatePortions = () => {
    let selectedMeals = [
      ...Object.values(selectedIds ?? {}).flat(),
      ...Object.values(extraSelectedIds ?? {}).flat()
    ];
    calculatePortions && calculatePortions(day, mealType, selectedMeals);
  }

  const handleSkipMeal = () => {
    if (window.confirm('Estas seguro/a que deseas omitir esta comida?')) {
      setSelectedIds(null);
      calculatePortions && calculatePortions(day, mealType);
    }
  }

  const filterMeals = (mealType: MEAL, group: FoodGroups): FesMeal[] => {
    return meals.filter(m => {
      let mealFoodGroups = [m.foodGroup1, m.foodGroup2, m.foodGroup3] as FoodGroups[];

      if ((!m.mealTypes.includes(mealType)) || (!mealFoodGroups.includes(group))) return false;

      const isLunchOrDinner = [MEAL.LUNCH, MEAL.DINNER].includes(mealType);

      const mealInBreakfastOrSnacks = breakfastSnacks.includes(group);
      const mealInLunchOrDinner = lunchDinner.includes(group);

      return (isLunchOrDinner && mealInLunchOrDinner) || (!isLunchOrDinner && mealInBreakfastOrSnacks);
    })
  }

  const checkExtraGroupsIncludeSelection = (group: string) => {
    return extraMealGroups && extraMealGroups.find(g => g.mealType === mealType)?.recommendedGroups.includes(group);
  }

  const handleEditMeal = (day: LOWERCASE_DAY, mealType: MEAL) => {
    let newSelectedIds: Record<string, string[]> = {};

    if (portions[day]?.[mealType]?.portions !== null && portions[day]?.[mealType]?.portions !== undefined) {

      let pastPortions = portions[day][mealType].portions;

      if (pastPortions && pastPortions.length > 0) {
        let groups = recommendedMealGroups.map(rg => rg.recommendedGroups).flat();

        let inGroup: string[] = [];

        if (groups && Array.isArray(pastPortions)) {
          groups.forEach(g => {
            if (g) {
              let selected = pastPortions?.map(p => {
                if (!(inGroup.includes(p.recipeId)) && (p.foodGroup1 === g || p.foodGroup2 === g || p.foodGroup3 === g)) {
                  inGroup.push(p.recipeId);
                  return p.recipeId;
                }
                return null;
              })
                .filter((s): s is string => s !== null);
              newSelectedIds[g] = selected ?? [];
            }
          })
        }

        pastPortions.forEach(p => {
          const isInAnyGroup = groups.some(g => p.foodGroup1 === g || p.foodGroup2 === g || p.foodGroup3 === g);

          if (!isInAnyGroup) {
            // Add portion's recipeId to the "Todo" group
            newSelectedIds['Todo'] = [...newSelectedIds['Todo'], p.recipeId];
          }
        });
      }
    }

    setSelectedIds(() => {
      if (setMealEditable) {
        setMealEditable(day, mealType);
      }
      return newSelectedIds;
    })
  }

  return (
    <Accordion id={accordionElementId} key={mealType} className='fes-card-container mynu-shadow mb-3'>
      <Accordion className={`meal-card ${translateToEs(mealType).toLowerCase()}`}>
        <Accordion.Header>
          <div className="w-100 d-flex align-items-center">
            <div className="mx-2 meal-icon" aria-hidden="true" />
            <span className="mx-2 meal-mealType text-capitalize font-avenir-bold" style={{ fontSize: '1.2rem' }}>
              {translateToEs(mealType)}
            </span>
          </div>
        </Accordion.Header>
        <Accordion.Body className="px-2 pb-4">
          {loadingPortions.day === day && loadingPortions.mealType === mealType &&
            loadingPortions.loading ?
            <div className="d-flex flex-row justify-content-center align-items-center bg-white mynu-shadow p-1">
              <Spinner size='small' rotate={true} />
              <div className="font-avenir-bold" style={{ fontSize: '1rem' }}>
                Cargando Porciones...
              </div>
            </div>
            : recommendedMealGroups.length > 0 &&
              !(portions?.[day]?.[mealType]?.skipped) &&
              !(portions?.[day]?.[mealType]?.portions) &&
              canEdit ?
              <div className="mt-2 mb-3">
                {
                  canEdit && [MEAL.LUNCH, MEAL.DINNER].includes(mealType) ?
                    <div className="w-100 mb-3 px-3 d-flex"><i>Recuerda añadir Aceite y Aderezos si los vas a agregar a tus ensaladas.</i></div>
                    : null
                }
                {recommendedMealGroups.map((mealGroup, i) =>
                  mealGroup.recommendedGroups.map((group, j) =>
                    <FesSearchComponent
                      id={`fes-search-component-${i}`}
                      key={`fes-search-component-${group}-${i}-${j}`}
                      group={group}
                      options={group === 'Todo' ? meals : filterMeals(mealType, group as FoodGroups)}
                      rootSelectedIds={selectedIds ?? {}}
                      handleRootSelection={handleClick}
                      changeRecommendedGroups={(group, add) => changeRecommendedGroups(group, mealType, false, add)}
                      extra={false}
                    />
                  )
                )}

                {/* unfiltered */}
                {extraMealGroups && extraMealGroups.map((mealGroup, i) => {
                  if (mealType === mealGroup.mealType) {
                    return (mealGroup.recommendedGroups.map((group, j) =>
                      <FesSearchComponent
                        id={`fes-search-component-unfiltered-${i}`}
                        key={`fes-search-component-extra-${group}-${i}-${j}`}
                        group={group}
                        options={meals.filter(m => (m.foodGroup1 === group || m.foodGroup2 === group || m.foodGroup3 === group))}
                        rootSelectedIds={extraSelectedIds ?? {}}
                        handleRootSelection={handleClickExtra}
                        changeRecommendedGroups={(group, add) => changeRecommendedGroups(group, mealType, true, add)}
                        extra={true}
                      />
                    ))
                  }
                  return null
                }
                )}

                <div className="mb-3 dropdown-container">
                  {allgroups ?
                    <DropdownButton
                      variant='outline-primary'
                      className="m-1 font-avenir-bold mynu-shadow"
                      title={
                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none">
                          <path d="M12.5 2L12.5 22" strokeWidth="3" strokeLinecap="round" />
                          <path d="M2 11.5L22 11.5" strokeWidth="3" strokeLinecap="round" />
                        </svg>
                      }
                      drop={'up-centered'}
                    >
                      <div className="mx-1 px-1" style={{ maxHeight: 250, overflowY: 'scroll' }}>
                        {allgroups.map(g =>
                          <Dropdown.Item
                            key={`dropdown-foodgroup-${g}-meal-${mealType}`}
                            onClick={() => checkExtraGroupsIncludeSelection(g) ?
                              changeRecommendedGroups(g, mealType, true, false) : changeRecommendedGroups(g, mealType, true, true)
                            }
                            className={`mb-2 mynu-outline mynu-stars ${checkExtraGroupsIncludeSelection(g) ? 'selected' : undefined}`}
                          >
                            <Stack direction="horizontal" gap={2}>
                              <div> {g} </div>
                              {checkExtraGroupsIncludeSelection(g) ?
                                <DashCircle size={20} className="ms-auto" />
                                :
                                <PlusCircleFill size={20} className="ms-auto" />
                              }
                            </Stack>
                          </Dropdown.Item>
                        )}
                      </div>
                    </DropdownButton>
                    : null
                  }
                </div>

                <div className="fes-button-row mx-2 font-avenir-bold">
                  <Button
                    id={`${accordionElementId}-skip-calculate-portions`}
                    variant="outline-secondary"
                    className="m-1 fes-button"
                    onClick={handleSkipMeal}
                  >
                    <div className="mx-2"> Omitir </div>
                    <X size={24} />
                  </Button>
                  <Button
                    id={`${accordionElementId}-calculate-portions`}
                    variant="primary"
                    onClick={handleCalculatePortions}
                    disabled={!isValid(selectedIds) && !isValid(extraSelectedIds)}
                    className="m-1 fes-button"
                  >
                    <div className="mx-2"> Calcular </div>
                    <CalculatorFill size={24} />
                  </Button>
                </div>
              </div>
              : !recommendedMealGroups ?
                <div className="d-flex justify-content-center">
                  <span>Agrega más comidas para continuar </span>
                </div>
                :
                <div className="mynu-shadow" style={{ backgroundColor: '#fff' }} >
                  <Container className="py-4">
                    {portions && mealType &&
                      (portions?.[day]?.[mealType] !== undefined) &&
                      !(portions?.[day]?.[mealType]?.skipped) &&
                      (portions?.[day]?.[mealType]?.portions?.length ?? 0) > 0 ?
                      portions?.[day]?.[mealType]?.portions?.map((portion, index) =>
                        <div className="fes-portions-container mynu-outline" key={`${portion.recipeId}-${index}`}>
                          <div className="recipe-name">
                            {portion.recipeName}
                          </div>
                          <RenderRecipeScore score={portion.score} size={'15px'} />
                          <div className="portion-amount">{portion.amountUnits} {portion.unit}</div>
                        </div>
                      )
                      : // meal skipped
                      <div className="d-flex justify-content-center">
                        <span>Omitiste esta comida</span>
                      </div>
                    }
                    {canEdit ?
                      <Row className="justify-content-center">
                        <Col xs={6}>
                          <Button
                            id={`${accordionElementId}-edit-portions`}
                            variant="outline-secondary"
                            className="fes-button m-auto my-2"
                            onClick={() => handleEditMeal(day, mealType as MEAL)}
                          >
                            <Stack direction="horizontal" gap={2}>
                              <PencilSquare size={24} />
                              <span className="mx-2">Editar</span>
                            </Stack>
                          </Button>
                        </Col>
                      </Row>
                      : null
                    }
                  </Container>
                </div>
          }
        </Accordion.Body>
      </Accordion>
    </Accordion >
  );
}

function translateToEs(meal: string) {
  switch (meal) {
    case 'breakfast':
      return 'Desayuno';
    case 'lunch':
      return 'Almuerzo';
    case 'morning-snack':
      return 'Media mañana (snack)';
    case 'afternoon-snack':
      return 'Media tarde (snack)';
    case 'dinner':
      return 'Cena';
    default:
      return 'Desayuno';
  }
}


