import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useCurrentUser } from '@/components/AuthContext';

import Container from 'react-bootstrap/Container';

import './PreLoginPage.scss';
import OKImage from '@/assets/images/ok-image.png';

function PreLoginPage() {
  const [ urlParam, setUrlParam ] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const currentUser = useCurrentUser();
  
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    if (urlParams.get('testId')) {
      setUrlParam(`?testId=${urlParams.get('testId')}`)
    }
    if (currentUser) {
      navigate(`/?testId=${urlParams.get('testId')}`)
    }
  }, [location.search, currentUser, navigate, urlParam]);

  return (
    <Container className="pre-login-page">
      <div className="pre-login-container">
        <img src={OKImage} alt="" />
        <p className="pre-login-text my-5 my-lg-4">¿Ya estás registrado?</p>
        <div className="d-flex justify-content-between">
          <Link className="btn btn-outline-primary px-4" to={`/signup${urlParam}`} >No, registrarme</Link>
          <Link className="btn btn-primary px-4" to={`/login${urlParam}`} >Sí, iniciar sesión</Link>
        </div>
      </div>
    </Container>
  )
}

export default PreLoginPage;
