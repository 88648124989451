import { useMemo, useState } from "react";
import { Card } from "react-bootstrap";

import { MacronutrientsChart } from "../MacroChart/MacroNutrientsChart";

import { fesNutrientReportType } from "@/models/fes-model";

import { macroNutrients, nutrientTypes, objectives, objectivesMap } from "../../constants";
import { adaptationLevelFromBoundsAndValue, translateMacroToEs } from "@/utils/fes";

interface MacroNutrientsProps {
  goal: objectives;
  protein: fesNutrientReportType | null;
  carbs: fesNutrientReportType | null;
  fat: fesNutrientReportType | null;
}

export default function Macronutrients (props: MacroNutrientsProps) {

  const {
    goal,
    protein,
    carbs,
    fat,
  } = props;

  const macros: macroNutrients[] = [
    macroNutrients.PROTEINS,
    macroNutrients.CARBS,
    macroNutrients.FAT
  ];
  const [selectedMacro, setSelectedMacro] = useState<fesNutrientReportType | null>(protein);

  const handleClick = (macro: macroNutrients) => {
    setSelectedMacro(
      macro === macroNutrients.PROTEINS ? protein
        : macro === macroNutrients.CARBS ? carbs
          : fat
    )
  }

  const adaptation = useMemo(() => {
    if (!selectedMacro) return null;

    const actual = selectedMacro.value;
    const lb = selectedMacro.dailyLb;
    const ub = selectedMacro.dailyUb;

    return adaptationLevelFromBoundsAndValue(actual, lb, ub);
  }, [selectedMacro]);

  return (
    protein && carbs && fat ?
      <Card className="mynu-shadow card-bottom">

        <Card.Header> Macronutrientes </Card.Header>

        <Card.Body className="macro-body">
          {/* macro selector */}
          <div className="buttons-container">
            {macros.map((macro: macroNutrients, i) => {
              let name = translateMacroToEs(macro);
              return (name ?
                <div
                  key={`macro-button-${macro}`}
                  className={`mb-2 ${selectedMacro?.name === macro ? 'selected' : 'macro-button'}`}
                  onClick={() => handleClick(macro)}
                >
                  {name.charAt(0).toUpperCase() + name.slice(1)}
                </div>
                : null
              )
            })
            }
          </div>

          {selectedMacro && adaptation &&
            objectivesMap[goal][nutrientTypes.MACROS][selectedMacro.name as macroNutrients][adaptation] ?
            <div className="h-100 macro-body-content">
              {/* charts */}
              <div className="mb-2">
                {selectedMacro ?
                  <MacronutrientsChart
                    name={selectedMacro.name}
                    value={selectedMacro.value}
                    dailyLb={selectedMacro.dailyLb}
                    dailyUb={selectedMacro.dailyUb}
                    unit={selectedMacro.unit}
                    level={adaptation}
                  />
                  : null
                }
              </div>
              {/* Text box */}
              <div className={`${adaptation}`}>
                <div className="font-avenir-bold">
                  {objectivesMap[goal].macros[selectedMacro.name as macroNutrients][adaptation].title}
                </div>
                <div className="h-50 d-flex color-text">
                  {objectivesMap[goal].macros[selectedMacro.name as macroNutrients][adaptation].description}
                </div>
              </div>
            </div>
            : null
          }
        </Card.Body >
      </Card >
      : null
  )
}
