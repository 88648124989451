import { useState } from "react";
import { Link } from "react-router-dom";

import BackButton from "@/components/BackButton/BackButtton";
import InitialLayout from "@/components/InitialLayout/InitialLayout";
import Notification from "@/components/Notification/Notification";
import SuccessMessage from "@/components/SuccessMessage/SuccessMessage";
import SendEmailForm from "./SendEmailForm";
import NewPasswordForm from "./NewPasswordForm";

import { changePassword, requestPasswordChange } from "@/services/auth/auth-service";
import { ChangePasswordData } from "@/models";

import './PasswordRecovery.scss';

function PasswordRecovery() {
  const [email, setEmail] = useState('');
  const [isSendedEmail, setIsSendedEmail] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handleClickSend = async (emailUser: string) => {
    setEmail(emailUser)
    try {
      await requestPasswordChange(emailUser);
      setIsSendedEmail(true);
    } catch (error) {
      setIsSendedEmail(true);
    }
  }

  const handleClickVerify = async (code: string, password: string) => {
    const data: ChangePasswordData = {
      username: email,
      password,
      code,
    }
    try {
      await changePassword(data);
      setSuccess(true);
    } catch (e: any) {
      setError(e.message);
    }
  }

  if (success) {
    return (
      <InitialLayout>
        <SuccessMessage text="¡Felicitaciones tu contraseña ha sido restablecida!" description="" to="/login" textLink='Continuar' />
      </InitialLayout>
    );
  }

  return (
    <InitialLayout>

      <Notification
        show={error.length > 0 ? true : false}
        variant={'danger'}
        text={[error]}
        onClose={() => setError('')}
      />

      <div className='recovery-password-container d-flex'>
        {isSendedEmail ? (
          <NewPasswordForm handleClick={handleClickVerify} handleRecovery={handleClickSend} email={email} />
        ) : (
          <>
            <div className="py-4">
              <BackButton text="Atrás" />
            </div>
            <SendEmailForm handleClick={handleClickSend} />
            <div className="text-center redirect-note pb-5">
              <span className="font-avenir-bold">¿Aún no tienes cuenta? </span>
              <Link to="../signup">Ingresa aquí</Link>
            </div>
          </>
        )}
      </div>
    </InitialLayout>
  )
}

export default PasswordRecovery;
