import { useEffect, useRef, useState } from 'react';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from "react-bootstrap/Row";

// state & context
import { useCurrentUser } from "@/components/AuthContext";

// models
import { DisplayChatMessage, MessageRate } from "@/models";

// services
import {
  getMessageHistory,
  sendMessageToChat,
  saveMessage,
  changeDailyMenu,
} from "@/services/chat/chat-services";
import { getDiet } from '@/services/diet';

// utils
import { validSubscription } from '@/utils';
import { calculateBackoffTime } from "@/utils";

// my components
import MessagesList from './MessagesList';
import SuggestedMessages from "./SuggestedMessages";
import MainLayout from "@/components/MainLayout/MainLayout";
import Spinner from "@/components/Spinner/Spinner";
import Notification from "@/components/Notification/Notification";
import SubscriptionPlanModal from '@/components/SubscriptionPlanModal/SubscriptionPlanModal';

import { days } from '@/utils/constants';
import { useStateStore } from '@/store/store';
import { loadUserInfo } from '@/store/loader';
import { rateMessage } from '@/services/chat/chat-services';

// assets
import {
  SUGGESTION_CHANGE_DIET,
  SUGGESTION_GENETIC_RESULTS_QUESTION,
  WELCOME_MESSAGE_CONTENT
} from "./default-messages";

import './ChatPage.scss';

import EnabledSendIcon from '@/assets/images/send-icon-enabled.svg';
import DisabledSendIcon from '@/assets/images/send-icon-disabled.svg';
import { InfoCircle } from 'react-bootstrap-icons';

function ChatPage() {
  const currentUser = useCurrentUser();
  const store = useStateStore();
  const { isLoadingDiet } = store;

  const [messages, setMessages] = useState<DisplayChatMessage[]>([]);
  const [allMessagesFetched, setAllMessagesFetched] = useState<boolean>(false);
  const [currentMessage, setCurrentMessage] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingNewMessages, setIsLoadingNewMessages] = useState<boolean>(false);
  const [showSubscriptionPlanModal, setShowSubscriptionPlanModal] = useState(false);
  const [dots, setDots] = useState('');

  useEffect(() => {
    const userToken = localStorage.getItem('userToken');
    if (currentUser && userToken) {
      loadUserInfo(store);

      setIsLoadingNewMessages(true);
      getMessageHistory(userToken, 0, 5)
        .then((messageHistory) => {
          const { foundMessages, allFetched } = messageHistory;

          setMessages(foundMessages.reverse());
          setAllMessagesFetched(allFetched);
        })
        .catch((error) => console.log(error))
        .finally(() => {
          setIsLoading(false);
          setIsLoadingNewMessages(false);
          divRef.current?.scrollIntoView({ behavior: 'smooth' });
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  useEffect(() => {
    const welcomeMessage: DisplayChatMessage = {
      role: 'assistant',
      content: WELCOME_MESSAGE_CONTENT
    }
    if (allMessagesFetched) {
      if (messages.length === 0) {
        setMessages([welcomeMessage, ...messages]);
      }
      else {
        if (messages[0].content !== WELCOME_MESSAGE_CONTENT) {
          setMessages([welcomeMessage, ...messages]);
        }
      }
    }
  }, [allMessagesFetched, messages])

  useEffect(() => {
    divRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [currentMessage]);

  const loadMoreMessages = (): void => {
    const userToken = localStorage.getItem('userToken');
    if (userToken) {
      setIsLoadingNewMessages(true);
      getMessageHistory(userToken, 0, messages.length + 5)
        .then((messageHistory) => {
          const { foundMessages, allFetched } = messageHistory;
          setAllMessagesFetched(allFetched);
          setMessages(foundMessages.reverse());
        })
        .catch((error) => console.log(error))
        .finally(() => {
          setIsLoading(false);
          setIsLoadingNewMessages(false);
        });
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setDots(prevDots => prevDots.length < 3 ? prevDots + '.' : '');
    }, 300);
    return () => clearInterval(interval);
  }, []);


  const sendMessage = async (newMessage: string, event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event?.preventDefault();
    setIsDisabled(true);
    const userMessage: DisplayChatMessage = {
      role: 'user',
      content: newMessage
    }

    const userToken = localStorage.getItem('userToken');

    if (currentUser?.subscription && !validSubscription(currentUser?.subscription)) {
      const noSubscriptionMessage1: DisplayChatMessage = {
        role: 'assistant',
        content: 'Ya no tienes más consultas disponibles'
      }
      const noSubscriptionMessage2: DisplayChatMessage = {
        role: 'assistant',
        content: 'Si quieres seguir conversando conmigo suscríbete'
      }
      const noSubscriptionButton: DisplayChatMessage = {
        role: 'assistant',
        content: '',
        type: 'subscribe'
      }
      const updatedMessages = [
        ...messages,
        userMessage,
        noSubscriptionMessage1,
        noSubscriptionMessage2,
        noSubscriptionButton
      ];
      setMessages(updatedMessages);
      setCurrentMessage('');

      setIsDisabled(false);
    }
    else if (!currentUser?.resultsDate && userMessage.content.includes(SUGGESTION_GENETIC_RESULTS_QUESTION)) {
      const message1: DisplayChatMessage = {
        role: 'assistant',
        content: 'Lo siento, pero no tengo acceso a tus resultados genéticos. Es importante comprar un test que analiza tu genética, así podré brindarte recomendaciones personalizadas sobre qué alimentos evitar o limitar en tu dieta. '
      }
      const message2: DisplayChatMessage = {
        role: 'assistant',
        content: 'Recuerda que cada persona es única y requiere un enfoque individualizado en cuanto a la alimentación.'
      }
      const resultsButton: DisplayChatMessage = {
        role: 'assistant',
        content: '',
        type: 'buyTest'
      }
      const updatedMessages = [
        ...messages,
        userMessage,
        message1,
        message2,
        resultsButton
      ];
      setMessages(updatedMessages);
      setCurrentMessage('');

      setIsDisabled(false);
    }
    else if (userMessage.content.includes(SUGGESTION_CHANGE_DIET)) {
      const message1: DisplayChatMessage = {
        role: 'assistant',
        content: '¡Claro! ¿En qué día te gustaría hacer cambios en tu dieta?'
      }

      const daysButton: DisplayChatMessage = {
        role: 'assistant',
        content: '',
        type: 'days'
      }

      const updatedMessages = [
        ...messages,
        userMessage,
        message1,
        daysButton
      ];
      if (userToken) {
        await saveMessage(userToken, userMessage.role, userMessage.content);
        await saveMessage(userToken, message1.role, message1.content);
        setMessages(updatedMessages);
        setCurrentMessage('');

        setIsDisabled(false);
      }
    }
    else if (includesDayOfWeek(userMessage.content)) {
      await changeMenuByDay(newMessage, userMessage);
    }
    else {
      const writingMessage: DisplayChatMessage = {
        role: 'assistant',
        content: 'Escribiendo...'
      }
      const updatedMessages = [...messages, userMessage, writingMessage];
      setMessages(updatedMessages);
      setCurrentMessage('');
      try {
        setIsDisabled(true);

        if (userToken) {
          await sendMessageToChat(userToken, newMessage);
          attemptGetNewMessage(userToken, newMessage).then((response) => {
            const assitanteMessage: DisplayChatMessage = response;
            let messagesCopy = [...updatedMessages];
            messagesCopy.splice(-1, 1, assitanteMessage);
            setIsDisabled(false);
            setMessages(messagesCopy);
          }).finally(() => {
            divRef.current?.scrollIntoView({ behavior: 'smooth' });
          });
        }
      } catch (error: any) {
        setError(error.message);
        setIsDisabled(false);
      }
    }
  }

  const attemptGetNewMessage = async (userToken: string, newMessage: string, attempt: number = 1): Promise<DisplayChatMessage> => {
    let waitTime = calculateBackoffTime(attempt);
    await new Promise(r => setTimeout(r, waitTime));

    const { foundMessages } = await getMessageHistory(userToken, 0, 2);

    if (foundMessages.length > 1) {
      let lastUserMessage = foundMessages[foundMessages.length - 2];
      if (lastUserMessage.content !== newMessage) {
        return lastUserMessage;
      }
    }

    return attemptGetNewMessage(userToken, newMessage, attempt + 1);
  }


  const includesDayOfWeek = (message: string) => {
    const lowerCaseMessage = message.toLowerCase();
    return days.some((day) => lowerCaseMessage === day.toLowerCase());
  }

  const handleSendMessageDay = async (day: string) => {
    if (day) {
      sendMessage(day);
    }
  }

  const changeMenuByDay = async (newDay: string, userMessage: DisplayChatMessage) => {
    setIsLoading(true);
    try {
      const userToken = localStorage.getItem('userToken');
      if (userToken) {
        const writingMessage: DisplayChatMessage = {
          role: 'assistant',
          content: 'Escribiendo...'
        }
        const updatedMessages = [...messages, userMessage, writingMessage];
        setMessages(updatedMessages);
        setCurrentMessage('');
        try {
          setIsDisabled(true);
          if (userToken) {
            let content: string
            try {
              await changeDailyMenu(newDay, userToken)
              try {
                const dayDiet = await getDiet([newDay], userToken)
                content = `¡Perfecto! Hice cambios en tu dieta del día ${newDay}:\n\n`
                dayDiet.meals[0].forEach(d => {
                  content += `${d.name}:\n`
                  content += d.foods.map(foodText => `- ${foodText}`).join('\n') + '\n\n'
                });
                content = content + `Recuerda que estos son solo ejemplos y puedes adaptar las comidas a tus preferencias y necesidades nutricionales.`
              } catch (error) {
                console.error(error)
                content = `¡Perfecto! Hice cambios en tu dieta del día ${newDay}.`;
              }
            } catch (error) {
              content = `¡Lo siento! No fue posible hacer cambios en tu dieta del día ${newDay}.`
            }

            const assitanteMessage: DisplayChatMessage = {
              role: 'assistant',
              content
            };
            await saveMessage(userToken, assitanteMessage.role, assitanteMessage.content);
            let messagesCopy = [...updatedMessages];
            messagesCopy.splice(-1, 1, assitanteMessage);
            setIsDisabled(false);
            setMessages(messagesCopy);
          }
        } catch (error: any) {
          setError(error.message);
          setIsDisabled(false);
        }

      }
    } catch (error) {
      setError('Error al actualizar tu dieta. Vuelve a intentarlo');
    }
    setIsLoading(false);
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setCurrentMessage(value);
  }

  const handlePressEnter = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter') {
      sendMessage(currentMessage);
    }
  }

  const sendSuggestion = (question: string) => {
    setCurrentMessage(question);
    sendMessage(question);
  }

  const toggleSubscriptionPlan = () => {
    setShowSubscriptionPlanModal(!showSubscriptionPlanModal)
  }

  const messageRate = (rate: MessageRate, message: DisplayChatMessage) => {
    const userToken = localStorage.getItem('userToken');
    if (userToken && message && message.messageId) {
      try {
        rateMessage(rate, message.messageId, userToken);
        message.rate = rate;
        let updatedMessages = messages.map((m: DisplayChatMessage) => {
          if (m.messageId === message.messageId) {
            return message;
          }
          return m;
        });
        setMessages(updatedMessages)
      } catch (e) {
        alert(e)
      }
    }
  }

  if (isLoadingDiet) {
    return (
      <MainLayout>
        <div className="chat-loader-container">
          <Spinner size="small" rotate={true} />
          <span className="loader-text mt-3 text-center">Cargando tu chat</span>
          <br />
          <span>Por favor espera</span>
        </div>
      </MainLayout>
    )
  }

  return (
    <>
      <MainLayout>
        <Notification
          show={error.length > 0}
          variant="danger"
          text={[error]}
          onClose={() => setError('')}
        />
        <div className="chat-page">
          <div className="d-flex flex-column justify-content-between chat-container">
            {messages.length === 0 && !isLoading && (
              <SuggestedMessages hasMessages={messages.length > 0} sendSuggestion={sendSuggestion} />
            )}
            <div className="conversation-container pt-4 pb-3">
              {(messages.length > 0 && !allMessagesFetched) &&
                <Row className="align-items-center justify-content-center mt-3 mb-3">
                  <Col xs={9} md={10} lg={11} className="text-center align-items-center justify-content-center">
                    {isLoadingNewMessages ?
                      <Row className="justify-content-center mt-2">
                        <Spinner size="small" rotate={true} />
                      </Row>
                      :
                      <Button
                        variant="outline-primary"
                        onClick={loadMoreMessages}
                      >
                        Cargar mensajes previos
                      </Button>
                    }
                  </Col>
                </Row>}
              {messages.length > 0 && (
                <MessagesList
                  messages={messages}
                  currentUser={currentUser}
                  dots={dots}
                  handleSendMessageDay={handleSendMessageDay}
                  toggleSubscriptionPlan={toggleSubscriptionPlan}
                  messageRate={messageRate}
                />
              )}
            </div>
            {messages.length > 0 && (
              <SuggestedMessages hasMessages={messages.length > 0} sendSuggestion={sendSuggestion} />
            )}
            <div className="input-chat-container px-3 mb-1 mb-lg-3">
              <hr className="my-2" />
              <Form>
                <InputGroup className="mb-2">
                  <Form.Control
                    as="textarea"
                    rows={3}
                    maxLength={500}
                    placeholder="Haz tus consultas acá ..."
                    value={currentMessage}
                    onChange={handleChange}
                    onKeyUp={handlePressEnter}
                    disabled={isDisabled || isLoading}
                    className="chat-input py-1 px-3 p-lg-3"
                  />
                  <Button
                    className="send-button input-group-text px-3"
                    type="submit"
                    onClick={(e) => sendMessage(currentMessage, e)}
                    disabled={!currentMessage}
                  >
                    <img
                      src={(isDisabled || isLoading) ? DisabledSendIcon : EnabledSendIcon}
                      alt="enviar"
                    />
                  </Button>
                </InputGroup>
                <Form.Text className="footnote">
                  <div className='d-flex flex-row'>
                    <InfoCircle width={40} className='m-2 mynu-purple' />
                    <i><b>
                      <p>Este servicio se encuentra en versión beta y utiliza inteligencia artificial,
                        por lo que puede cometer errores y no sustituye el asesoramiento de profesionales en ningun caso.</p>
                    </b></i>
                  </div>
                </Form.Text>
              </Form>
            </div>
          </div>
        </div>

      </MainLayout>

      <SubscriptionPlanModal
        showModal={showSubscriptionPlanModal}
        subscription={currentUser?.subscription ?? null}
        onHide={toggleSubscriptionPlan}
      />
    </>
  )
}


export default ChatPage;
