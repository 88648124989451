import { weeklyViewType } from "@/models/fes-model";

export enum DAY {
  MONDAY = 'Lunes',
  TUESDAY = 'Martes',
  WEDNESDAY = 'Miércoles',
  THURSDAY = 'Jueves',
  FRIDAY = 'Viernes',
  SATURDAY = 'Sábado',
  SUNDAY = 'Domingo'
}

export const days = [DAY.MONDAY, DAY.TUESDAY, DAY.WEDNESDAY, DAY.THURSDAY, DAY.FRIDAY, DAY.SATURDAY, DAY.SUNDAY];

export enum LOWERCASE_DAY {
  MONDAY = 'lunes',
  TUESDAY = 'martes',
  WEDNESDAY = 'miércoles',
  THURSDAY = 'jueves',
  FRIDAY = 'viernes',
  SATURDAY = 'sábado',
  SUNDAY = 'domingo'
}

export const lowerDays = [
  LOWERCASE_DAY.MONDAY,
  LOWERCASE_DAY.TUESDAY,
  LOWERCASE_DAY.WEDNESDAY,
  LOWERCASE_DAY.THURSDAY,
  LOWERCASE_DAY.FRIDAY,
  LOWERCASE_DAY.SATURDAY,
  LOWERCASE_DAY.SUNDAY
];

export enum MEAL {
  BREAKFAST = 'breakfast',
  LUNCH = 'lunch',
  DINNER = 'dinner',
  MORNING_SNACK = 'morning-snack',
  AFTERNOON_SNACK = 'afternoon-snack',
}

export const weekViewIndex: weeklyViewType[] = [
  { ix: 1, s: "Lu", ld: LOWERCASE_DAY.MONDAY, cd: DAY.MONDAY },
  { ix: 2, s: "Ma", ld: LOWERCASE_DAY.TUESDAY, cd: DAY.TUESDAY },
  { ix: 3, s: "Mi", ld: LOWERCASE_DAY.WEDNESDAY, cd: DAY.WEDNESDAY },
  { ix: 4, s: "Ju", ld: LOWERCASE_DAY.THURSDAY, cd: DAY.THURSDAY },
  { ix: 5, s: "Vi", ld: LOWERCASE_DAY.FRIDAY, cd: DAY.FRIDAY },
  { ix: 6, s: "Sa", ld: LOWERCASE_DAY.SATURDAY, cd: DAY.SATURDAY },
  { ix: 7, s: "Do", ld: LOWERCASE_DAY.SUNDAY, cd: DAY.SUNDAY },
];

export const MYNU_PRIMARY_COLOR = '#6628FF';

export const KIT_PRICE_CLP = 119990;
export const DISCOUNT_PRICE_CLP = 99990;

export const UTC_OFFSET = 'T00:00:00';

export const PHONE_NUMBER_REGEX = /^(\+\d{2,3}\s?)?(\d\s?){1,3}(\d{3}\s?)\d{4}$/;
export const PASSWORD_REGEX = /(?=[A-Za-z0-9]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,}).*$/;
export const NAME_REGEX = /(w\s*)+$/;