import { useState } from "react";

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';

import { ArrowRepeat } from "react-bootstrap-icons";

type IngredientProps = {
  food: string,
  selectedIngredient: string,
  handleChangeIngredient: (food: string, history: string[], reason: string) => void,
  name?: string;
}

function Ingredient({ food, selectedIngredient, handleChangeIngredient, name }: IngredientProps) {
  const [ingredientHistory, setIngredientHistory] = useState<string[]>([]);
  const [reason, setReason] = useState('');

  const capitalizeFirstLetter = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  const renderIngredientPortions = (ing: string) => {
    const portions = ing.split(':');
    const food = capitalizeFirstLetter(portions[0]);
    let portion = portions[1];
    return (
      <Row className="ingredient-text">
        <div className="food-title">{food}</div>
        <div className="food-portion pt-1">{portion}</div>
      </Row>
    )
  }

  const changeIngredient = () => {
    setIngredientHistory(ingredientHistory);
    handleChangeIngredient(food, ingredientHistory, reason);
  }

  // modal
  const [show, setShow] = useState(false);

  const handleShowModal = (type: string) => {
    if (type === 'change') {
      changeIngredient()
    } else {
      setReason('')
    }
    setShow(false)
  };


  return (
    <>
      <Row id={name} className="align-items-center">
        <Col xs>
          {renderIngredientPortions(food)}
        </Col>
        <Col xs={2} className="p-0 m-0">
          <Button
            variant="link"
            className="reload-button"
            disabled={!!selectedIngredient}
            onClick={() => setShow(true)}
          >
            <ArrowRepeat size={22} />
          </Button>
        </Col>
      </Row>

      <Modal show={show} onHide={() => { handleShowModal('') }}>
        <Modal.Header closeButton>
          <Modal.Title>¿Por qué quieres cambiar ese ingrediente?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control value={reason} onChange={(event) => { setReason(event.target.value) }} />
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: 'space-between' }}>
          <Button variant="outline-danger" onClick={() => { handleShowModal('') }}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={() => { handleShowModal('change') }}>
            Cambiar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Ingredient;
