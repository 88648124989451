import Form from 'react-bootstrap/esm/Form';

import SuccessPayment from '@/assets/images/success-payment.svg';
import FailurePayment from '@/assets/images/failure_payment.svg';

import './SuccessPayment.scss';

const { Text } = Form;

type SuccessMessageProps = {
  text: string;
  to?: string;
  description?: string;
  textLink?: string;
  success: boolean;
}

function SuccessMessage({ text, to = '/login', description = '', textLink = 'Continuar', success }: SuccessMessageProps) {
  return (
    <div className="success-container">
      <div className="text-center">
        {success ? <img src={SuccessPayment} alt="" width={280} /> : <img src={FailurePayment} alt="" width={280} />}
      </div>
      <h1 className="text-center text-title">
        {text}
      </h1>
      <Text className='text-description'>
        {description}
      </Text>
      <a href={to} className="btn btn-primary mt-4 continue-button">
        {textLink}
      </a>
    </div>
  )
}

export default SuccessMessage;
