import { useEffect, useState, useReducer } from "react";

import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';

import { FormPersonalData, QuestionnaireOptions } from "@/models";

import QuestionnaireExitModal from './QuestionnaireExitModal';
import CloseQuestionnaire from './CloseQuestionnaire';
import EndOfSection from "./components/EndOfSection";
import NumberComponent from "./components/NumberComponent";
import CheckBoxComponent from "./components/CheckBoxComponent";
import DateComponent from "./components/DateComponent";
import SelectComponent from "./components/SelectComponent";
import TextComponent from "./components/TextComponent";
import QuestionnaireHeader from "./components/QuestionnaireSectionHeader";

import './Questionnaire.scss';

export interface QuestionnaireFormPersonalInformationProps {
  options: QuestionnaireOptions;
  updateForm: (form: FormPersonalData) => void;
  goToNextSection: () => void;
  currentForm: FormPersonalData;
  goBack: (form: FormPersonalData, save: boolean) => void;
  close: (form: FormPersonalData, save: boolean) => void;
}

enum FormUpdateType {
  FULL_SET = "FULL_SET",
  FIELD_SET = "FIELD_SET"
};

type FormUpdateAction = {
  actionType: FormUpdateType;
  newState?: FormPersonalData;
  fieldName?: string;
  fieldValue?: any;
}

const OTHER_MEDICAL_CONDITION_OPTION_ID = 'other';

const formDispatcherSwitch = (
  state: FormPersonalData,
  { actionType, fieldName, fieldValue, newState }: FormUpdateAction): FormPersonalData => {
  switch (actionType) {
    case FormUpdateType.FULL_SET:
      if (!newState) throw new Error("No state for full assignemnt!");

      return {
        ...newState
      };
    case FormUpdateType.FIELD_SET:
      if (!fieldName) throw new Error("No fieldName for field assignemnt!");

      let newFormState = {
        ...state,
        [fieldName]: fieldValue
      };

      if (!state.medicalConditionIds.includes(OTHER_MEDICAL_CONDITION_OPTION_ID)) {
        newFormState.otherMedicalConditionsText = undefined;
      }

      return newFormState;
    default:
      throw new Error(`Reducer unknown action type: ${actionType}`);
  }
}

const QuestionnaireFormPersonalInformation:
  React.FC<QuestionnaireFormPersonalInformationProps> =
  ({
    options,
    updateForm,
    currentForm,
    goBack,
    goToNextSection
  }) => {
    const [validated, setValidated] = useState(false);
    const [showExitModal, setShowExitModal] = useState(false);

    const [form, formDispatcher] = useReducer(
      formDispatcherSwitch,
      {
        genderId: undefined,
        birthDate: undefined,
        weightKg: undefined,
        heightCm: undefined,
        medicalConditionIds: [],
        otherMedicalConditionsText: undefined,
        allergyOrIntoleranceIds: [],
        otherAllergiesOrIntolerancesText: '',
        medicationIds: [],
        smokes: undefined,
        alcoholConsumptionLevelId: undefined,
        goalId: undefined,
        wellnessImprovementGoalId: undefined,
        hasFollowedDiet: undefined,
        experienceFollowingDietsText: undefined,
        experienceTryingToLooseWeightText: undefined,
        weightGoalKg: undefined,
        hasTriedToGainWeight: undefined,
        followingDiet: undefined,
        gainMuscularWeightFatTimePriorityId: undefined,
        gainMuscularWeightTrainingTimeInMonths: undefined,
        gainMuscularWeightApetiteLevelId: undefined
      } as FormPersonalData
    );

    useEffect(() => {
      formDispatcher({
        actionType: FormUpdateType.FULL_SET,
        newState: currentForm
      });
    }, [currentForm]);

    const updateField = (name: string, newValue: any) => {
      formDispatcher({
        actionType: FormUpdateType.FIELD_SET,
        fieldName: name,
        fieldValue: newValue
      });
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      event.stopPropagation();

      const formTarget = event.currentTarget;

      if (formTarget.checkValidity()) {
        updateForm(form);
        goToNextSection();
        setShowModal(false);
      } else {
        setValidated(true);
        setShowModal(true);
      }
    };

    const handleExit = () => {
      setShowExitModal(true);
    };

    const [showModal, setShowModal] = useState(false);

    return (
      <Container className="w-100">
        <Form className="w-100" noValidate validated={validated} onSubmit={handleSubmit} >
          <div className="py-3 w-100">
            <CloseQuestionnaire onClick={handleExit} text="Volver al inicio" />
          </div>

          <QuestionnaireHeader
            title="Necesitamos conocer más sobre ti!"
            subtitle="Estos datos son esenciales para que disfrutes de una experiencia
             personalizada a tus metas y necesidades."
            numerator={1}
            denominator={3}
          />

          <Row xs={1} md={2}>
            <Col>
              <SelectComponent
                name="gender-sex"
                question="¿Cuál es tu sexo?"
                options={options.genderOptions}
                required={true}
                updateValue={(value: string) => {
                  updateField('genderId', value);
                }}
                isValid={(option: string) => {
                  return option.length > 3;
                }}
                caveat="Indica sexo de nacimiento"
                initialValue={form.genderId}
                showInvalid={validated}
              />
            </Col>

            <Col>
              <DateComponent
                name={"birthDate"}
                question="¿Cuál es tu fecha de nacimiento?"
                required={true}
                isValid={() => validated}
                initialDate={form.birthDate}
                updateDate={(newDate: string) => {
                  updateField('birthDate', newDate);
                }}
              />
            </Col>
          </Row>

          <Row xs={1} md={2}>
            <Col>
              <NumberComponent
                name="weightKg"
                question="¿Cuál es tu peso actual (en kilogramos)?"
                className='weight'
                required={true}
                initialValue={form.weightKg}
                placeholder={"Valores sobre 30kg"}
                updateValue={(newVal: number) => {
                  updateField('weightKg', newVal);
                }}
                isValid={(someNumber: number) => {
                  return someNumber > 30 || !validated;
                }}
                numberType="integer"
              />
            </Col>

            <Col>
              <NumberComponent
                name="height"
                question="¿Cuál es tu altura (en centímetros)?"
                className='heightCm'
                required={true}
                initialValue={form.heightCm}
                placeholder={"Valores sobre 100 centímetros"}
                updateValue={(newVal: number) => {
                  updateField('heightCm', newVal);
                }}
                isValid={(someNumber: number) => {
                  return someNumber < 250;
                }}
                numberType="integer"
                showInvalid={validated}
              />
            </Col>

          </Row>


          <CheckBoxComponent
            name="medicalConditionIds"
            question='¿Tienes alguna de estas condiciones médicas?'
            description="Selecciona las opciones que apliquen"
            required
            options={options.medicalConditionOptions.map(({ id, label, description }) => {
              return { id, label, tooltip: description };
            })}
            isValid={(selectedOptions: string[]) => selectedOptions.length > 0}
            initiallySelectedIds={form.medicalConditionIds}
            updateSelectedIds={(condiciones) => {
              updateField('medicalConditionIds', condiciones);
            }}
            hasNone
          />

          {form.medicalConditionIds.includes(OTHER_MEDICAL_CONDITION_OPTION_ID) && <TextComponent
            name="otherMedicalConditionsText"
            question={"¿Qué otras condiciones médicas padeces? Sé específico"}
            placeholder={"..."}
            required={false}
            initialText={form.otherMedicalConditionsText}
            updateText={(value: string) => {
              updateField('otherMedicalConditionsText', value);
            }}
            isValid={(value: string) => true}
          />}

          <CheckBoxComponent
            name="allergiesOrIntolerances"
            question="¿Padeces alguna de las siguientes intolerancias o alergias?"
            description="Selecciona todas las que padezcas"
            required
            options={options.allergiesOrIntoleranceOptions}
            isValid={(selectedOptions: string[]) => selectedOptions.length > 0}
            initiallySelectedIds={form.allergyOrIntoleranceIds}
            updateSelectedIds={(condiciones) => {
              updateField('allergyOrIntoleranceIds', condiciones);
            }}
            hasNone
          />

          <CheckBoxComponent
            name="medicationIds"
            question='¿Consumes algún(unos) de el(los) siguiente(s) medicamento para las siguientes condiciones?'
            description="Selecciona las opciones que apliquen"
            required
            options={options.medicationOptions.map(({ id, label }) => {
              return { id, label };
            })}
            isValid={(selectedOptions: string[]) => selectedOptions.length > 0}
            initiallySelectedIds={form.medicationIds}
            updateSelectedIds={(medicamentos) => {
              updateField('medicationIds', medicamentos);
            }}
            hasNone
          />

          <Row xs={1} md={2}>
            <Col>
              <SelectComponent
                name="smoke"
                question="¿Fumas tabaco?"
                options={[
                  { id: 'yes', label: 'Sí' },
                  { id: 'no', label: 'No' },
                ]}
                required={true}
                updateValue={(value: string) => {
                  updateField('smokes', value === 'yes');
                }}
                initialValue={form.smokes !== undefined ? (form.smokes ? 'yes' : 'no') : undefined}
                isValid={(value: string) => {
                  return value.length > 1 || !validated;
                }}
                showInvalid={validated}
              />
            </Col>
            <Col>
              <SelectComponent
                name="alcoholConsumptionLevelId"
                question='¿Con qué frecuencia consumes alcohol?'
                required={true}
                options={options.alcoholConsumptionFrequencyOptions}
                initialValue={form.alcoholConsumptionLevelId}
                updateValue={(optionId) => {
                  updateField('alcoholConsumptionLevelId', optionId);
                }}
              />
            </Col>
          </Row>

          <SelectComponent
            name="goalId"
            question='¿Cuál es tu objetivo principal?'
            required={true}
            options={options.goalOptions}
            initialValue={form.goalId}
            updateValue={(optionId) => {
              updateField('goalId', optionId);
            }}
          />

          {form.goalId === "gain-muscular-weight" && <>

            <SelectComponent
              name="gainMuscularWeightFatTimePriority"
              question="El aumento de masa muscular puede venir acompañado de un aumento de porcentaje de grasa corporal, dicho esto ¿De qué forma prefieres aumentar tu masa múscular?"
              required={true}
              options={options.gainMuscularWeightFatTimePriorityOptions}
              initialValue={form.gainMuscularWeightFatTimePriorityId}
              updateValue={(optionId) => {
                updateField('gainMuscularWeightFatTimePriorityId', optionId);
              }}
            />

            <SelectComponent
              name="gainMuscularWeightApetiteLevelId"
              question="¿Cómo consideras tu apetito en general?"
              required={true}
              options={options.gainMuscularWeightApetiteOptions}
              initialValue={form.gainMuscularWeightApetiteLevelId}
              updateValue={(optionId) => {
                updateField('gainMuscularWeightApetiteLevelId', optionId);
              }}
            />

            <NumberComponent
              name='gainMuscularWeightTrainingTimeInMonths'
              question='¿Cuánto tiempo llevas entrenando con el objetivo de aumentar la masa muscular?'
              placeholder="Ingresa el número en meses"
              required={true}
              initialValue={form.gainMuscularWeightTrainingTimeInMonths}
              updateValue={(selectedNumber: number) => {
                updateField('gainMuscularWeightTrainingTimeInMonths', selectedNumber);
              }}
              isValid={(selectedNumber: number) => {
                return true;
              }}
              numberType={'integer'}
            />

          </>}

          {form?.goalId === "weight-loss" && <>

            <NumberComponent
              name="weightGoalKg"
              question="¿Cuál es tu peso ideal? (en kilogramos)"
              className='weight'
              required={true}
              initialValue={form.weightGoalKg}
              placeholder={"En kilogramos, entre 30 y 150"}
              updateValue={(newVal: number) => {
                updateField('weightGoalKg', newVal);
              }}
              isValid={(someNumber: number) => {
                return someNumber > 88 || !validated;
              }}
              numberType="integer"
            />

            <SelectComponent
              question='¿Has practicado alguna dieta para bajar peso anteriormente?'
              name="hasFollowedDiet"
              required={true}
              options={[
                { id: 'hasFollowedDiet-yes', label: 'Sí' },
                { id: 'hasFollowedDiet-no', label: 'No' }
              ]}
              initialValue={form.hasFollowedDiet !== undefined ? (form.hasFollowedDiet ? 'hasFollowedDiet-yes' : 'hasFollowedDiet-no') : undefined}
              updateValue={(optionId) => {
                updateField('hasFollowedDiet', optionId === 'hasFollowedDiet-yes');
              }}
            />

            {
              form.hasFollowedDiet && <TextComponent
                name="experienceFollowingDietsText"
                question={"¿Cuál ha sido tu experiencia practicando estas dietas para bajar de peso?"}
                required={true}
                initialText={form.experienceFollowingDietsText}
                placeholder="escribe acá..."
                updateText={(value: string) => {
                  updateField('experienceFollowingDietsText', value);
                }}
                isValid={(value: string) => {
                  return true;
                }}
              />
            }

            {
              form.hasFollowedDiet && <TextComponent
                name="experienceTryingToLooseWeightText"
                question={"¿Cuál ha sido tu experiencia al tratar de bajar de peso?"}
                required={true}
                initialText={form.experienceTryingToLooseWeightText}
                placeholder="escribe acá..."
                updateText={(value: string) => {
                  updateField('experienceTryingToLooseWeightText', value);
                }}
                isValid={(value: string) => {
                  return true;
                }}
              />
            }

            <SelectComponent
              name="followingDiet"
              question="¿Actualmente estás siguiendo algún tipo de dieta?"
              options={[
                { id: 'followingDiet-yes', label: 'Sí' },
                { id: 'followingDiet-no', label: 'No' },
              ]}
              required={true}
              updateValue={(value: string) => {
                updateField('followingDiet', value === "followingDiet-yes");
              }}
              initialValue={form.followingDiet !== undefined ? (form.followingDiet ? 'followingDiet-yes' : 'followingDiet-no') : undefined}
              isValid={(value: string) => {
                return true;
              }}
            />

          </>}

          {form?.goalId === "weight-gain" && <>

            <NumberComponent
              name="weightGoalKg"
              question="¿Cuál es tu peso ideal? (en kilogramos)"
              className='weight'
              required={true}
              initialValue={form.weightGoalKg}
              placeholder={"En kilogramos, entre 30 y 150"}
              updateValue={(newVal: number) => {
                updateField('weightGoalKg', newVal);
              }}
              isValid={(someNumber: number) => {
                return someNumber > 88 || !validated;
              }}
              numberType="integer"
            />

            <SelectComponent
              question='¿Has tratado de subir de peso anteriormente?'
              name="hasTriedToGainWeight"
              required={true}
              options={[
                { id: 'has-tried-to-gain-weight-yes', label: 'Sí' },
                { id: 'has-tried-to-gain-weight-no', label: 'No' }
              ]}
              initialValue={form.hasTriedToGainWeight !== undefined ? (form.hasTriedToGainWeight ? 'has-tried-to-gain-weight-yes' : 'has-tried-to-gain-weight-no') : undefined}
              updateValue={(optionId) => {
                updateField('hasTriedToGainWeight', optionId === 'has-tried-to-gain-weight-yes');
              }}
            />

            <SelectComponent
              name="followingDiet"
              question="¿Actualmente estás siguiendo algún tipo de dieta?"
              options={[
                { id: 'followingDiet-yes', label: 'Sí' },
                { id: 'followingDiet-no', label: 'No' },
              ]}
              required={true}
              updateValue={(value: string) => {
                updateField('followingDiet', value === 'followingDiet-yes');
              }}
              initialValue={form.followingDiet !== undefined ? (form.followingDiet ? 'followingDiet-yes' : 'followingDiet-no') : undefined}
              isValid={(value: string) => {
                return true;
              }}
            />

          </>}

          {form?.goalId === "wellness" && <>
            <SelectComponent
              question='¿Qué parámetro de salud y bienestar te gustaría mejorar?'
              name="wellnessImprovementGoalId"
              required={true}
              options={options.wellnessGoalOptions}
              initialValue={form.wellnessImprovementGoalId}
              updateValue={(optionId) => {
                updateField('wellnessImprovementGoalId', optionId);
              }}
            />
          </>}

          <QuestionnaireExitModal
            show={showExitModal}
            onClose={() => setShowExitModal(false)}
            onExit={(save) => goBack(form, save)}
          />
          <EndOfSection
            previousSectionButtonText="Continuar luego"
            previousSectionButtonHandler={() => { handleExit() }}
            nextSectionButtonText="Siguiente"
          />
          <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Porfavor completa todos los campos</Modal.Title>
            </Modal.Header>
          </Modal>
        </Form>
        <br></br>
      </Container>
    );
  }

export default QuestionnaireFormPersonalInformation;
