import { FC } from "react";

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import '@/styles/App.scss';
import NutritionistSessionImage from '@/assets/images/nutritionist-session-section.png';
import NutritionistSessionMobileImage from '@/assets/images/nutritionist-session-section-mobile.png';
import SubscriptionIcon from '@/assets/icons/subscription-icon.svg';

const NutricionistSessionSection: FC<{}> = () => {

  return (
    <Card className="mb-4 home-card">
      <Container>
        <Row>
          <Col lg={"auto"} md={12} className="px-0">
            <img
              src={NutritionistSessionImage}
              alt=""
              className="banner-nutrition d-none d-lg-block"
            />
            <img
              src={NutritionistSessionMobileImage}
              alt=""
              className="banner-nutrition d-flex d-lg-none"
            />
          </Col>
          <Col lg={5} md={12} className="pt-3 pb-lg-0">
            <h1 className="home-title subtitle">
              <img src={SubscriptionIcon} alt="" className="me-3" />
              ¡Agenda tu teleconsulta!
            </h1>
            <p className="home-text mt-3 text-center text-lg-start">
              Ahora sí puedes tener atención personalizada! Agenda tu teleconsulta a través del siguiente <a
                href="https://calendly.com/contacto-wty/teleconsulta"
                target="_blank"
                rel="noopener noreferrer"
              >link</a>
            </p>
          </Col>
          <Col className="home-button-container pb-4 px-0"></Col>
        </Row>
      </Container>
    </Card>
  )
}

export default NutricionistSessionSection;
