import { useState, useEffect } from "react";

export const useIsIOSWebView = () => {
    const [isWebView, setIsWebView] = useState(false);

    useEffect(() => {
        const userAgent = window.navigator.userAgent;
        const isIOS = /iPhone|iPod|iPad/i.test(userAgent);
        const isWebKit = /AppleWebKit(?!.*Safari)/i.test(userAgent);

        // If it's an iOS device and not Safari, it's likely a WebView
        if (isIOS && isWebKit) {
            setIsWebView(true);
        }
    }, []);

    return isWebView;
};