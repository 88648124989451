import { useMemo } from "react";

import Card from "react-bootstrap/esm/Card";
import Badge from "react-bootstrap/esm/Badge";
import Carousel from "react-bootstrap/esm/Carousel";
import Popover from "react-bootstrap/esm/Popover";
import OverlayTrigger from "react-bootstrap/esm/OverlayTrigger";

import { fesNutrientReportType } from "@/models/fes-model";
import { microNutrientsSources } from "../../constants";

import { Plus, QuestionCircleFill } from "react-bootstrap-icons";

interface MicroNutrientsProps {
  nutrients: fesNutrientReportType[];
}
export default function MicroNutrientsComponent(props: MicroNutrientsProps) {
  const { nutrients } = props;

  let vitaminMinteralsNutrients = microNutrientsSources.map((microNutrient) => microNutrient.name);

  let data = nutrients.filter((nutrient) => vitaminMinteralsNutrients.includes(nutrient.name));

  let consumeMore = data.filter((n) => {
    if (n.name !== 'sodium' && ((n.value / n.dailyLb) < 1)) {
      return true;
    }
    return false;
  }).map(n => n.name);

  return (
    data.length > 0 ?
      <Card className="card-bottom micro-card mynu-shadow">
        <Card.Header> Vitaminas y Minerales </Card.Header>

        <Card.Body>
          <div className="micro-chart-header">
            <div><Badge pill>Mi consumo</Badge></div>
            <div>
              <Badge pill className="mx-1 py-0 px-2">
                <Plus size={25} />
              </Badge>
              <div>Mayor cantidad de vitaminas</div>
            </div>
          </div>
          <Carousel data-bs-theme="dark">
            {Array.from({ length: Math.ceil(data.length / 3) }, (_, i) => {
              return (
                <Carousel.Item key={`carousel-micro-nutrients-${i}`} className="p-0">
                  <div className='w-100 d-flex justify-content-center' >
                    {data.slice(i * 3, i * 3 + 3).map((vitNtr, j) => {
                      let micro = microNutrientsSources.find(m => m.name === vitNtr.name);
                      return (
                        micro && micro?.label.length > 0 ?
                          <div style={{ width: '25%' }} key={vitNtr.name + '-' + j}>
                            <div className={'font-avenir-bold'} style={{ textAlign: 'center', height: '40px' }}>
                              {micro.label}
                            </div>
                            <div key={`chart-macro-${micro.name}`} style={{ height: '200px' }} >
                              <CustomSlider
                                name={vitNtr.name}
                                value={vitNtr.value}
                                lb={vitNtr.dailyLb}
                                ub={vitNtr.dailyUb}
                                unit={vitNtr.unit}
                                details={micro.sources.join(', ')}
                              />
                            </div>
                          </div>
                          : null)
                    })}
                  </div>
                </Carousel.Item>
              )
            })
            }
          </Carousel>
          <div className="micro-text-recommendation">
            <b className="font-avenir-bold">
              Aumenta el consumo de
            </b>: {consumeMore.map(n => microNutrientsSources.find(m => n === m.name)?.label ?? null).join(', ')}
          </div>
        </Card.Body>
      </Card>
      : null
  )
}


export const CustomSlider = (
  props: {
    value: number,
    lb: number,
    ub: number,
    unit?: string,
    name?: string,
    details?: string
  }
) => {
  const { value, lb, ub, name, details } = props;

  const barColor = useMemo(() => {
    switch (name) {
      case 'sodium':
        return value < ub ? '#33B591' : '#FE5133';
      default:
        return lb < value ? '#33B591' : '#FE5133';
    }
  }, [value, ub, lb, name]);

  const computedPercentage = useMemo(() => {
    const middleLine = name === 'sodium' ? ub : lb;
    const ratio = value / middleLine;
    return Math.max(0, Math.min(100, ratio >= 2.5 ? 100 : Math.trunc(ratio * 50)));

  }, [value, name, lb, ub])

  return (
    <div id={`custom-slider-micros-${name}`} className="custom-range m-auto mt-3">
      <div className="holder mynu-inner-shadow ">
        <hr />
        <div className="font-avenir-bold"> </div>
        <div className="font-avenir-bold">+</div>
        <div className="slider"
          style={{ backgroundColor: barColor, height: `${computedPercentage}%` }}
        />
      </div>
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Popover id={`custom-slider-micros-tooltip-${name}`} >
            <Popover.Body>
              {details}
            </Popover.Body>
          </Popover>
        }
      >
        <QuestionCircleFill size={20} className="mynu-stars" />
      </OverlayTrigger>
    </div>
  )
}


