import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

type TestModalProps = {
  show: boolean;
  onHide: () => void;
  addTestId: () => void;
  modalContent: {
    image: string,
    title: string,
    description: string,
    buttonText: string,
    imageWidth: number,
    type: string,
  }
}

function TestModal({ show, onHide, addTestId, modalContent }: TestModalProps) {

  const handleClick = () => {
    onHide();
    if (modalContent.type === 'error') {
      addTestId();
    }
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      className="text-center test-modal-container"
      centered
    >
      <Modal.Header closeButton className="border-bottom-0 mx-3 mt-3"></Modal.Header>
      <Modal.Body className="pt-0 pb-4">
        <img src={modalContent.image} alt="" width={modalContent.imageWidth} />
        <Modal.Title id="contained-modal-title-vcenter" className="test-modal-title mt-3">
          {modalContent.title}
        </Modal.Title>
        <p className="mt-2">
          {modalContent.description}
        </p>
        <Button className="fw-bold px-3" onClick={handleClick}>
          {modalContent.buttonText}
        </Button>
      </Modal.Body>
    </Modal>
  )
}

export default TestModal;
