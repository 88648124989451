import { useEffect, useMemo, useState } from "react";

import { useCurrentUser } from "@/components/AuthContext";
import { useStateStore } from "@/store/store";
import { loadDiet, loadUserInfo } from "@/store/loader";

import Card from "react-bootstrap/esm/Card";
import Container from "react-bootstrap/esm/Container";

import MainLayout from "@/components/MainLayout/MainLayout";
import { LoadingPage } from "@/router/LoadingPage";
import Notification from "@/components/Notification/Notification";

import ProfileSummary from "../menu/components/ProfileSummary";
import DietSummaryComponent from "./components/SummaryItem/DietSummaryComponent";

import { isEmptyOrUndefined } from "@/utils";

export default function DietSummaryPage() {
  const store = useStateStore();
  const currentUser = useCurrentUser();

  const {
    dietInformation,
    isLoadingDiet,
    dietCreationFailed,
    hasDiet
  } = store;

  const [notificationText, setNotificationText] = useState('');
  const notificationError = useMemo(() => notificationText.toLowerCase().includes('error'), [notificationText]);
  const notificationOpen = useMemo(() => notificationText.length > 0, [notificationText]);

  const loadDietInfo = async () => {
    try {
      await loadDiet(store);
    } catch (error: any) {
      setNotificationText('Ha ocurrido un error cargando tu dieta');
    }
  }

  const checkDiet = async () => {
    if (isLoadingDiet) {
      await loadDietInfo();
    } else {
      return
    }
  };

  useEffect(() => {
    if (currentUser && isEmptyOrUndefined(dietInformation, 'arr')) {
      loadUserInfo(store);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const userToken = localStorage.getItem('userToken');
    if (currentUser?.resultsDate && userToken && isLoadingDiet) {
      checkDiet()
      const intervalId = setInterval(checkDiet, 10000);
      return () => clearInterval(intervalId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, isLoadingDiet]);

  return (
    <MainLayout>
      {hasDiet() ?
        <>
          <Notification
            show={notificationOpen}
            text={[notificationText]}
            variant={notificationError ? 'danger' : 'success'}
            onClose={() => setNotificationText('')}
          />
          <div className="fes-page pt-2 pt-lg-4 px-0">
            <Container className="page-container">

              <Card className="p-4 mb-4 diet-header">
                <h1 className="diet-title">Guía Nutricional</h1>
                <p className="text mt-3">¡Bienvenido a tu Guía Nutricional!</p>
                <p className="text">
                  Aquí encontrarás recomendaciones hechas específicamente para ti basadas en tu genética y condiciones médicas.
                  Explora qué alimentos debes preferir, reducir y/o evitar para mejorar tu salud.
                </p>
                <hr />
                <ProfileSummary />
              </Card>
              {
                !isLoadingDiet && !dietCreationFailed &&
                <Card className="py-4 px-2 mb-4 diet-header">
                  <DietSummaryComponent
                    setNotificationText={setNotificationText}
                  />
                </Card>
              }
            </Container>
          </div>
        </>
        : <LoadingPage />
      }
    </MainLayout>
  )
}