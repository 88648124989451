import { LOWERCASE_DAY, MEAL } from "./constants";
import { breakfastSnacks, lunchDinner, FoodGroups, dailyPortionsType } from "@/models/fes-model";
import { adaptationLevels } from "@/pages/diets/fes/constants";

export const foodGroupFilter = (mealType: MEAL, recommendedGroup: string): boolean => {
  if ([MEAL.LUNCH, MEAL.DINNER].includes(mealType)) {
    return lunchDinner.includes(recommendedGroup as FoodGroups);
  }

  return breakfastSnacks.includes(recommendedGroup as FoodGroups);
}

export const validatePortions = (portions: dailyPortionsType, day: LOWERCASE_DAY): boolean => {

  let incompleteCount = 0;
  let totalMealTypes = Object.keys(portions[day]).length;

  Object.keys(portions[day]).forEach(m => {
    let mealType = m as MEAL;
    let portion = portions[day][mealType];

    if (portion.skipped === undefined || portion.skipped === true) {
      incompleteCount++;
    }
  });

  return incompleteCount !== totalMealTypes;
}

export const adaptationLevelFromBoundsAndValue = (value: number, lb: number, ub: number): adaptationLevels => {
  if (value !== undefined && lb !== undefined && ub !== undefined) {
    if (value <= lb * 0.7) {
      return adaptationLevels.VL;
    }
    else if (lb * 0.7 <= value && value < lb) {
      return adaptationLevels.L;
    }
    else if (lb <= value && value <= ub) {
      return adaptationLevels.M;
    }
    else if (ub < value && value <= ub * 1.3) {
      return adaptationLevels.H;
    }
    else if (value > 1.3 * ub) {
      return adaptationLevels.VH;
    }
  }
  throw new Error('No valid adaptation for given values');
}


export const translateMacroToEs = (unit: string | undefined) => {
  switch (unit) {
    case 'calories':
      return 'Calorías';
    case 'protein':
      return 'Proteínas';
    case 'carbs':
      return 'Carbohidratos';
    case 'fat':
      return 'Grasas';
    case 'grams':
      return 'g';
    case 'calorías':
      return 'cal'
    default:
      return unit;
  }
}
