import { ReactNode } from 'react';

import AppNavbar from './AppNavbar';
import HealthDisclaimerComponent from '@/components/HealthDisclaimer/HealthDiscalimer';

type MainLayoutProps = {
  children: ReactNode,
}

function MainLayout({ children }: MainLayoutProps) {
  return (
    <>
      <AppNavbar />
      <div style={{ margin: "3rem 0 5rem" }}>
        {children}
      <HealthDisclaimerComponent />
      </div>
    </>
  );
}

export default MainLayout;
