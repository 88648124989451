import './ErrorMessage.scss';
import Failure from '@/assets/images/failure_payment.svg';

type ErrorMessageProps = {
  text: string;
  to?: string;
  description?: string;
  textLink?: string;
}

function ErrorMessage({ text, to = '/login', description = '', textLink = 'Continuar' }: ErrorMessageProps) {
  return (
    <div className="questionnaire-error-container">
      <div className="text-center">
        <img src={Failure} alt="" width={280} />
      </div>
      <h1 className="text-center text-title">
        {text}
      </h1>
      <div className='text-center m-4 pt-2'>
        {description}
      </div>
      <div className="error-button-container">
        <a href={to} className="btn btn-primary mt-4 continue-button">
          {textLink}
        </a>
      </div>
    </div>
  )
}

export default ErrorMessage;
