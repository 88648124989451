import { useNavigate } from "react-router";

import CallToAction from "./components/CallToAction";
import FeaturesComponent from "./components/FeaturesComponent";
import FloatingContactUs from "./components/FloatingContactUs";
import Footer from "./components/Footer";
import InfoCategories from "./components/InfoCategories";
import InfoDelivery from "./components/InfoDelivery";
import InfoSoftware from "./components/InfoSoftware";
import InfoLab from "./components/InfoLab";
import MynuButtonComponent from "./components/ButtonComponent";
import MynuLandingNavBar from "./components/LandingNavBar";
import VideoComponent from "./components/VideoComponent";

import { ArrowDown } from "react-bootstrap-icons";
import './landing.scss';

const LANDING_VIDEO_SRC = "https://www.youtube.com/embed/tJyiZLMRmR8";

const LandingPage = () => {
  const navigate = useNavigate();

  const scrollDown = () => {
    var scrollDiv = document.getElementById("video-component")?.offsetTop ?? 0;
    window.scrollTo(0, scrollDiv);
  }

  return (
    <>
      <MynuLandingNavBar />
      <div className="landing-page">
        <div className="landing-container">
          <div className="flex-c container-center">
            <div className="landing-text-wrapper">
              <div className="main-text">Aquí comienza tu mejor versión</div>
              <div className="sub-text">{"Start Today ;)"}</div>
            </div>

            <div className="main-button-container">
              <MynuButtonComponent
                text="COMPRA AQUÍ"
                onClick={() => navigate('/buy-kit')}
              />
            </div>
            <div className="content-center">
              <ArrowDown color="white" className="icon m-2 point" onClick={scrollDown} />
            </div>
          </div>
        </div>

        <div className="flex-c align-items-start video-landing" id="video-component">
          <VideoComponent
            src={LANDING_VIDEO_SRC}
            videoTitle="lab"
            headerText="La R-evolución nutricional,"
            subHeaderText="está a punto de comenzar"
            bottomText1="Mucho más que un kit"
            bottomText2="una inversión en salud para toda la vida"
          />
        </div>
        <div className="">
          <FeaturesComponent />
        </div>
        <div className="flex-c content-center">
          <CallToAction />
        </div>

        <InfoSoftware />

        <InfoCategories />

        <div>
          <InfoDelivery />
        </div>
        <div>
          <InfoLab />
        </div>
      </div>

      <FloatingContactUs />

      <Footer />
    </>

  );
}

export default LandingPage;