import WhatsAppLogo from '@/assets/icons/landing-icons/wsp-logo.svg'

export default function FloatingContactUs () {
  return (
    <div className='d-flex flex-row justify-content-end stick'>
      <a
        className="floating-button d-flex flex-column content-center point"
        href="https://wa.me/56923846918?text=Tengo%20una%20duda%20sobre%20mynu"
        target="_blank"
        rel="noreferrer"
      >
        <img src={WhatsAppLogo} alt="whatsapp" className="floating-icon" />
      </a>
    </div>
  )    
}