import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Button, Container } from 'react-bootstrap';

// state & context
import { useCurrentUser } from './components/AuthContext';
import { loadUserInfo } from './store/loader';
import { useStateStore } from './store/store';

// models
import { Subscription } from './models';

// utils & services
import { registerTestId } from './services/user/user';
import { parseBool, validSubscription } from './utils';

// my components
import MainLayout from './components/MainLayout/MainLayout';
import KitBanner from './components/KitBanner/KitBanner';
import SubscriptionPlanModal from './components/SubscriptionPlanModal/SubscriptionPlanModal';
import Notification from './components/Notification/Notification';
import SubscriptionBanner from './components/SubscriptionBanner/SubscriptionBanner'
import ResultSection from './pages/home/ResultSection';
import QuestionnaireSection from './pages/home/QuestionnaireSection';
import SubscriptionSection from './pages/home/SubscriptionSection'
import KitAdnSection from './pages/home/KitADNSection'
import WelcomeSection from './pages/home/WelcomeSection';
import TestModal from './pages/home/TestModal';
import TestRegisterModal from './pages/home/TestRegisterModal';
import NutricionistSessionSection from './pages/home/NutricionistSessionSection';

// assets
import './styles/App.scss';
import ErrorData from './assets/images/error-data.png';
import Success from './assets/images/success-image.png';

function App() {
  const store = useStateStore();
  const location = useLocation();
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const [showModal, setShowModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    image: '',
    title: '',
    description: '',
    buttonText: '',
    imageWidth: 0,
    type: ''
  })
  const [error, setError] = useState('');
  const [isSubscriptionBanner, setSubscriptionBanner] = useState('');
  const [showSubscriptionCard, setshowSubscriptionCard] = useState(false);
  const [showKitCard, setshowKitCard] = useState(false);
  const [canEditQuestionnaire, setCanEditQuestionnaire] = useState(true);
  const [showRegisterKit, setShowRegisterKit] = useState(false);
  const [showKitBanner, setShowKitBanner] = useState(false);
  const [showNutritionistSession, setShowNutritionistSession] = useState(false);
  const [showSubscriptionPlanModal, setShowSubscriptionPlanModal] = useState(false);

  const [showNotification, setShowNotification] = useState(false);

  const goBuyKit = () => navigate('/buy-kit');

  useEffect(() => {
    const notification = localStorage.getItem('notification');
    if (notification && parseBool(notification)) {
      setShowNotification(false);
    } else {
      setShowNotification(true);
    }

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        for (const registration of registrations) {
          registration.unregister();
        }
      }).catch((error) => {
        console.error('Error unregistering service workers:', error);
      });
    }

  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const testId = urlParams.get('testId');
    if (testId) {
      if (currentUser?.testId) {
        setModalContent({
          image: ErrorData,
          title: 'Ya tienes un kit asociado a tu nombre',
          description: 'Por favor, verifica tus registros o escanea otro QR.',
          buttonText: 'Aceptar',
          imageWidth: 250,
          type: 'user-with-test'
        })
        setShowModal(true);
      } else {
        //Enviar código al backend y esperar respuesta, página en placeholder
        const userToken = localStorage.getItem('userToken');
        if (userToken && currentUser) {
          registerTestId(testId, currentUser, userToken)
            .then(() => {
              setShowRegisterModal(false);
              setModalContent({
                image: Success,
                title: '¡Felicitaciones!',
                description: '¡Listo! Hemos registrado tu Kit de ADN.',
                buttonText: 'Salir',
                imageWidth: 150,
                type: 'success'
              })
              setShowModal(true);
            })
            .catch(() => {
              setModalContent({
                image: ErrorData,
                title: 'Vaya, algo no salió como esperábamos.',
                description: 'No pudimos registrar automáticamente tu Kit de ADN. Puedes registrarte manualmente.',
                buttonText: 'Registrar manualmente',
                imageWidth: 250,
                type: 'error'
              })
              setShowModal(true);
            })
        }
      }
    }
    if (!store.getUserData() || !store.hasResults() || !store.hasDiet()) {
      loadUserInfo(store);
    }

    getSubscriptionInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, location.search]);

  const addTestId = () => {
    setShowRegisterModal(true);
  }

  const getSubscriptionInformation = () => {
    const subscription = currentUser?.subscription as Subscription;
    if (subscription) {
      // Check for premium subscription and missing testId
      if (subscription.type === 'premium') {
        if (!validSubscription(subscription)) {
          setCanEditQuestionnaire(false);
        }
        if (currentUser?.resultsDate) {
          setShowNutritionistSession(true);
        }
      }

      if (!currentUser?.testId) {
        setShowRegisterKit(true);
        setShowKitBanner(true);
        setshowKitCard(true);
      }

      // Check for non-premium subscription
      if (subscription.type !== 'premium') {
        // Check for freemium subscription
        if (subscription.type === 'freemium') {
          setshowSubscriptionCard(true);
        }

        // Check for non-valid subscription
        if (!validSubscription(subscription)) {
          setshowSubscriptionCard(true);
          setCanEditQuestionnaire(false);
        }
      }

      // Check for expired subscription
      if (subscription.end_date && subscription.end_date < new Date().toISOString()) {
        setSubscriptionBanner('expiredWarning');
      }
    }
  }

  const registerTest = async (testId: string) => {
    const userToken = localStorage.getItem('userToken');
    if (!userToken || !currentUser) {
      return
    }

    registerTestId(testId, currentUser, userToken)
      .then(() => {
        setShowRegisterModal(false);
        setModalContent({
          image: Success,
          title: '¡Felicitaciones!',
          description: '¡Listo! Hemos registrado tu Kit de ADN.',
          buttonText: 'Salir',
          imageWidth: 150,
          type: 'success'
        })
        setShowModal(true);
      })
      .catch((error) => {
        console.log(error);
        setError(error.message);
      })
  }

  const onHideModal = () => {
    setShowModal(false);
    setShowRegisterModal(false);

    if (modalContent.type === 'success') {
      window.location.reload();
    }
  }

  const toggleSubscriptionPlan = () => {
    setShowSubscriptionPlanModal(!showSubscriptionPlanModal)
  }

  return (
    <MainLayout>
      <KitBanner showBanner={showKitBanner} onClick={goBuyKit} />
      {showRegisterKit && (
        <div className="register-test-banner" id="register-kit">
          <p className="mb-0">Registra tu test de ADN en 1 minuto</p>
          <Button
            id={'register-kit-button'}
            variant="outline-secondary"
            className="banner-button"
            onClick={addTestId}
          >
            Registrar
          </Button>
        </div>
      )}
      <Container fluid className="page pt-2 pt-lg-4 px-0">
        <Container className="page-container">
          <SubscriptionBanner type={isSubscriptionBanner} onClick={() => { }} />
          <Notification
            variant={'info'}
            show={showNotification}
            text={['Recuerda mantener tu cuestionario actualizado!']}
            onClose={() => { localStorage.setItem('notification', 'true'); setShowNotification(false) }}
          />
          <WelcomeSection
            userName={currentUser?.givenName}
            userGender={currentUser?.gender}
            subscription={currentUser?.subscription}
          />
          {showNutritionistSession && <NutricionistSessionSection />}
          <SubscriptionSection showSubscriptionCard={showSubscriptionCard} />
          <QuestionnaireSection progress={currentUser?.profileStatus} subscriptionValid={canEditQuestionnaire} />
          <KitAdnSection showKitAdnCard={showKitCard} onClick={goBuyKit} />
          <ResultSection status={store.resultStatus} />
        </Container>
        <TestModal
          show={showModal}
          onHide={onHideModal}
          modalContent={modalContent}
          addTestId={addTestId}
        />
        <TestRegisterModal
          show={showRegisterModal}
          onHide={onHideModal}
          registerTest={registerTest}
          error={error}
        />
        <SubscriptionPlanModal
          showModal={showSubscriptionPlanModal}
          subscription={currentUser?.subscription ?? null}
          onHide={toggleSubscriptionPlan}
        />
      </Container>
    </MainLayout>
  );
}

export default App;
