import React, { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import Accordion from "react-bootstrap/Accordion";
import Badge from "react-bootstrap/Badge";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useAccordionButton } from "react-bootstrap/AccordionButton";

// categories
import { Category, Subcategory, percentileType, predispositionIndexType } from "../../../../models";
import { Categories, categoryToSpanish, subcategoryToSpanish } from "../../Categories";

// mynu components
import MynuTooltip from "../../../../components/Tooltip/Tooltip";

// icons
import { CaretDownFill } from "react-bootstrap-icons";
import GreenCheck from "../../../../assets/images/green-check.png";
import YellowMatk from "../../../../assets/images/yellow-x.png";
import RedX from "../../../../assets/images/red-x.png";

import Markdown from "react-markdown";

// styles
import './ResultsCategories.scss';
import * as c from "./RestultsConstants";
import { isEmptyOrUndefined } from "../../../../utils";

function PrsTermometer(props: c.TermometerProps): JSX.Element {
  const {
    prs,
    prsTo1,
    minBound,
    maxBound,
    subcategoryName,
    predisposition,
    subcatScore,
    percentile
  } = props;


  function colorFromPercentage(percentage: number, color1: string, color2: string, color3: string): string {
    const rgbGreen = hexToRgb(color1);
    const rgbPurple = hexToRgb(color2);
    const rgbRed = hexToRgb(color3);

    // Interpola entre los colores basándose en el porcentaje
    let colorInterpolado = [];
    if (percentage <= 50) {
      const factor = percentage / 50;
      for (let i = 0; i < 3; i++) {
        colorInterpolado[i] = Math.round(rgbGreen[i] + factor * (rgbPurple[i] - rgbGreen[i]));
      }
    } else {
      const factor = (percentage - 50) / 50;
      for (let i = 0; i < 3; i++) {
        colorInterpolado[i] = Math.round(rgbPurple[i] + factor * (rgbRed[i] - rgbPurple[i]));
      }
    }

    const colorHex = colorInterpolado.reduce((acc, val) => {
      return acc + ('0' + val.toString(16)).slice(-2);
    }, '#');

    return colorHex;
  }

  function hexToRgb(hex: string) {
    hex = hex.replace(/^#/, '');

    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return [r, g, b];
  }
  const resultIsNumber = !(c.nonNumericSubcategories.includes(subcategoryName));

  const shrimpAllergic = (subcategoryName === 'Alergia al camarón')

  const percentileOrPRS = percentile?.percentile && resultIsNumber && !shrimpAllergic ? percentile.percentile : prs;

  let prsPercentage = percentile?.percentile && resultIsNumber && !shrimpAllergic ? percentile.percentile : ((prs - minBound) / (maxBound - minBound)) * 100;

  const prsDistanceFromLeft = prsPercentage + '%';

  let gradientClass = (subcategoryName === "Masa Corporal Magra" || subcategoryName === 'Vitamina C') ? "gradient-body-lean" : "gradient-regular";
  let predispositionColor = colorFromPercentage(
    percentile?.percentile && resultIsNumber && !shrimpAllergic ? percentileOrPRS : prsPercentage,
    c.Green, c.Purple,
    (subcategoryName === "Masa Corporal Magra") ? c.Purple : c.Red);

  const styleTooltip = {
    "backgroundColor": `${predispositionColor}`,
    "--background-color": `${predispositionColor}`
  }

  const numberOrResultNumber: string = useMemo(() => {
    if (!resultIsNumber) {
      let toShow:
        c.PredispositionShow
        | c.MusclePredispositionShow
        | c.LowHighHighPredispositionShow
        | undefined = c.rawPredispositiontoShow.get(predisposition);
      if (c.RegularGradientSubcategories.includes(subcategoryName)) {
        toShow = c.rawMusclePredispositionToShow.get(predisposition);
      }
      if (subcategoryName === 'Vitamina C') {
        toShow = c.alterPredispositiontoShow.get(predisposition);
      }
      if (!toShow) {
        console.log('Invalid muscle predisposition', predisposition)
      };
      return toShow ? toShow : 'NaN';
    }
    if (shrimpAllergic) {
      return prs.toFixed(1)
    }

    return (percentile?.percentile && resultIsNumber ? 'Percentil ' + percentileOrPRS.toFixed(0) : prsTo1.toFixed(2));
    // eslint-disable-next-line
  }, [percentileOrPRS, subcatScore, resultIsNumber, subcategoryName, predisposition]);

  const sliderBounds: string[] = useMemo(() => {
    if (!shrimpAllergic && c.nonNumericSubcategories.includes(subcategoryName)) {
      if (c.RegularGradientSubcategories.includes(subcategoryName)) {
        return [
          c.SubCategoriesBounds.NON_NUMERIC_REGULAR_GRADIENT_LOW,
          c.SubCategoriesBounds.NON_NUMERIC_REGULAR_GRADIENT_HIGH
        ]
      }
      return [
        c.SubCategoriesBounds.NON_NUMERIC_GRADIENT_LOW,
        c.SubCategoriesBounds.NON_NUMERIC_GRADIENT_HIGH
      ];
    }

    const scale = 1 - minBound;
    const [left, right] = [minBound + scale, maxBound + scale];

    if (c.ExtraDecimalSubcategories.includes(subcategoryName)) {
      return [left.toFixed(2), right.toFixed(2)];
    }
    return [left.toFixed(1), right.toFixed(1)];
  }, [minBound, maxBound, subcategoryName, shrimpAllergic]);

  return (
    <Col className="continuous-container">
      <div className={gradientClass}>
        <div
          className="mark-prs rounded-circle" 
          style={{ left: prsDistanceFromLeft, border: `5px solid ${predispositionColor}`}}
        >
          <div
            className={`prs-number ${percentile?.percentile ? 'margin-percentile' : 'margin-prs'}`}
            style={{
              ...styleTooltip,
              left: parseInt(prsDistanceFromLeft) > 0 ? '-200%' : 0
            }}
          >
            {numberOrResultNumber}
          </div>
        </div>
        <Row>
          <Col style={{ textAlign: 'left' }}>
            {!shrimpAllergic && percentile?.percentile && resultIsNumber ? '0' : sliderBounds[0] }
          </Col>
          <Col style={{ textAlign: 'right' }}>
            {!shrimpAllergic && percentile?.percentile && resultIsNumber ? '100' : sliderBounds[1] }
          </Col>
        </Row>
      </div>
    </Col>
  )
}


function ResultsCategories({ data = [], resultMealsRestrictions }: { data?: Category[], resultMealsRestrictions: any }) {

  let allowed: string[] = resultMealsRestrictions.allowed;
  let reduced: string[] = resultMealsRestrictions.reduced;
  let avoided: string[] = resultMealsRestrictions.avoided;


  function checkFoodConsistency(food: string, advice: string): boolean {
    return (
      (allowed.length > 0 && advice.includes('prefer') && allowed.includes(food)) ||
      (reduced.length > 0 && advice === 'reduce' && reduced.includes(food)) ||
      (avoided.length > 0 && advice === 'avoid' && avoided.includes(food))
    );
  }

  function RenderMainAccordion({ data }: { data: Category[] }): JSX.Element {
    return (
      <Card.Body>
        <Accordion className="main-accordion">
          {
            data.map((category: Category, index) => {
              return (
                <Accordion.Item eventKey={index.toString()} key={index} className="category-item">
                  <Accordion.Header
                    className="category-item-header"
                    id={`category-${category.category_name}`}
                  >
                    <span className="tag-category">
                      {categoryToSpanish(category.category_name)}
                    </span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <RenderSectionAccordionItem category={category} />
                  </Accordion.Body>
                </Accordion.Item>
              )
            })
          }
          {
            c.CATEGORIES_SOON.map((c: string, index: number) =>
              <Accordion.Item eventKey={index + c} className="category-item" key={index + c}>
                <Accordion.Header className="category-item-header">
                  <span className="soon">{c} (Próximamente)</span>
                </Accordion.Header>
              </Accordion.Item>
            )
          }
        </Accordion>
      </Card.Body>
    )
  }

  function RenderSectionAccordionItem(
    { category }: { category: Category }
  ): JSX.Element {
    const subcategories = category.subcategories
    const categoryInfo = Categories.find(cat => cat.id === category.category_name)
    const description: string = categoryInfo?.description || "";
    const subcategoriesInfo = categoryInfo?.subcategories || [];

    const [openSubCategory, setOpenSubCategory] = useState<string>('');

    return (
      <div className="d-flex flex-column justify-content-center">
        <Markdown className="category-description">{description}</Markdown>
        <Accordion>
          {
            subcategories
              .filter(category => !c.HIDDEN_SUBCATEGORIES.includes(category.subcategory_name))
              .map(subcategory => {
                if (subcategoriesInfo.some((subcat) =>
                  subcat.subcategory_name === subcategory.subcategory_name
                  && c.validPredispositions.includes(subcategory.predisposition)
                )) {
                  return (
                    <SubsectionAccordionItem
                      subcategoriesInfo={subcategoriesInfo}
                      subcategory={subcategory}
                      openSubCategory={openSubCategory}
                      setOpenSubCategory={setOpenSubCategory}
                      categoryName={category.category_name}
                    />
                  )
                }
                return null;
              })
          }
          {
            c.SUBCATEGORIES_SOON.map((categ: c.categorySubcategories, index: number) => {
              if (category.category_name === categ.categoryName) {
                return categ.subcategories.map((s: string, ix: number) => (
                  <Accordion.Item key={index + s + ix} eventKey={index + s + ix} className="subcategory-item">
                    <Accordion.Header className="subcategory-header" >
                      <Container>
                        <Col>
                          <div className="font-avenir-bold subcategory-name" style={{ color: 'gray' }}>{s}</div>
                        </Col>
                      </Container>
                    </Accordion.Header>
                  </Accordion.Item>
                ))
              }
              return null;
            })
          }
        </Accordion>
      </div>
    )
  }

  type SubSectionAccordionItemType = {
    subcategoriesInfo: any[],
    subcategory: Subcategory, // subcategory from results
    openSubCategory: string,
    setOpenSubCategory: (arg0: string) => void,
    categoryName: string
  }

  function SubsectionAccordionItem(props: SubSectionAccordionItemType): JSX.Element {

    const {
      subcategoriesInfo, subcategory,
      openSubCategory, setOpenSubCategory,
      categoryName,
    } = props

    const subcategoryName = subcategoryToSpanish(subcategory.subcategory_name) || "";
    const predisposition = subcategory.predisposition;
    let prs = subcategory.polygenic_risk_factor_score;
    const prsTo1 = subcategory.polygenic_risk_factor_score_1
    let minBound = subcategory.prs_min;
    let maxBound = subcategory.prs_max;
    let percentile: percentileType | null = null;

    if (subcategory.subcategory_name === 'allergies_shrimp') {
      prs = Math.exp(prs);
      minBound = Math.exp(minBound);
      maxBound = Math.exp(maxBound);
    }

    // subcategory from categories file
    const subcategoryData = subcategoriesInfo.find((subcat: { subcategory_name: string }) => subcat.subcategory_name === subcategory.subcategory_name);
    let studyLabel = subcategoryData.subcategory_study_label;

    if (!isEmptyOrUndefined(subcategory.percentiles, 'obj')) {
      // select percentile via category
      const sbPercent = subcategory.percentiles;
      if (studyLabel) {
        percentile = sbPercent.filter(p => p.label === studyLabel)[0];
      }
    }


    const subcatDescription = subcategoryData.description
    const subcatPrevRisk = subcategoryData.prevalence_risk
    const subcatNutriAssociation = subcategoryData[predisposition].asociation
    const rememberText = subcategoryData[predisposition].remember;
    const genes = subcategoryData.genes.join(", ");
    const subcatScore = subcategoryData.subcategory_score;
    const prsTo1Parsed = Number(prsTo1.toFixed(1)).toLocaleString("es-Cl");
    const prsTimesScore = Number((subcatScore * prsTo1).toFixed(1)).toLocaleString("es-CL");

    function CustomToggle({ children, eventKey }: { children: JSX.Element, eventKey: string }): JSX.Element {
      const handleCustomToggle = useAccordionButton(eventKey, () => {
        setOpenSubCategory(openSubCategory !== eventKey ? eventKey : '')
      });
      return (
        <div onClick={handleCustomToggle} >
          {children}
        </div>
      );
    }

    return (
      <Accordion.Item key={subcategoryName} eventKey={subcategoryName} className="subcategory-item">
        <CustomToggle eventKey={subcategoryName}>
          <Container className="subcategory-header justify-content-center align-items-center">
            <Row className="my-2 pt-4 text-center">
              <div className="font-avenir-bold subcategory-title">{subcategoryName}</div>
            </Row>
            <Row className="my-2">
              <p className="subcategory-description">{subcatDescription}</p>
            </Row>
            <Row className="justify-content-center align-items-center">
              <Col lg={8}>
                <div className="subcategory-title">Interpretación de tu Resultado</div>
                {subcatNutriAssociation
                  .replace(c.REPLACE_NUMBER_PRS, prsTo1Parsed)
                  .split('. ')
                  .map((par: string) => {
                    return <Markdown className="subcategory-p">{par}</Markdown>
                  })
                }
                {
                  subcatPrevRisk && subcatScore &&
                  <b><p className="subcategory-p">{subcatPrevRisk.replace(c.REPLACE_NUMBER_RISK, prsTimesScore + '%')}</p></b>
                }
              </Col>
              <Col lg className="genetic-predisposition-section">
                <Row>
                  <div className="subcategory-title">Te encuentras aquí respecto a la población</div>
                </Row>
                <Row className="my-1">
                  <PrsTermometer
                    subcategoryName={subcategoryName}
                    prs={prs}
                    prsTo1={prsTo1}
                    predisposition={predisposition}
                    minBound={minBound}
                    maxBound={maxBound}
                    subcatScore={subcatScore}
                    percentile={percentile}
                  />
                </Row>
              </Col>
            </Row>
          </Container>
        </CustomToggle>
        <Accordion.Body className="accordion-body">
          <Container>
            <Col>
              <Row>
                <Col lg={7} xs={12}>
                  <div className="subcategory-title">Recomendación Nutricional</div>
                  <RenderNutritionalRecomendation
                    predisposition={predisposition}
                    subcategoryData={subcategoryData}
                    categoryName={categoryName}
                  />
                </Col>
                <Col lg={5} xs={12}>
                  <div className="subcategory-title">Recuerda</div>
                  <p className="subcategory-paraph">{rememberText}</p>
                </Col>
              </Row>
              <div className="subcategory-title gene-title">Genes Asociados</div>
              <p className="gene-list">{genes}</p>
            </Col>
          </Container>
        </Accordion.Body>

        <CustomToggle eventKey={subcategoryName} >
          <Col className="subcategory-footer"
            key={subcategoryName}
            style={{
              backgroundColor: subcategoryName !== openSubCategory ? '#e7e7e777' : '#38b6ff23',
              flexDirection: subcategoryName !== openSubCategory ? 'column' : 'column-reverse',
            }}
          >
            <div className="font-avenir-bold">
              {subcategoryName !== openSubCategory ? ' Ver más' : 'Ver menos'}
            </div>
            <CaretDownFill
              size={"1.25em"}
              style={{
                transform: subcategoryName !== openSubCategory ? 'rotate(0deg)' : 'rotate(180deg)'
              }}
            />
          </Col>
        </CustomToggle>
      </Accordion.Item>
    );
  }

  function adviceTotitle(advice: string) {
    if (advice.includes('prefer')) {
      return [GreenCheck, "Prefiere"]
    } else if (advice === 'reduce') {
      return [YellowMatk, "Reduce"]
    } else {
      return [RedX, "Evita"]
    }
  }

  const RenderNutritionalRecomendation = (
    { predisposition, subcategoryData, categoryName }:
      { predisposition: predispositionIndexType, subcategoryData: any, categoryName: string }
  ): JSX.Element => {
    const navigate = useNavigate();

    // recomendaciones sin involucrar alimentos
    if (
      (predisposition === "low" && typeof subcategoryData.low.advice === 'string') ||
      (!('prefer' in subcategoryData) && !('reduce' in subcategoryData) && !('avoid' in subcategoryData) && !('prefer1' in subcategoryData))
    ) {
      return (
        <p className="subcategory-paraph nutritional-rec-text">{subcategoryData[predisposition].advice}</p>
      )

    }

    const advices = subcategoryData[predisposition].advice;
    let reverse = "";
    if (advices.length === 1 && (advices[0] === 'reduce' || advices[0] === 'avoid')) {
      reverse = "flex-row-reverse";
    }

    let webSize = 6;
    if (advices.length === 1) {
      webSize = 12;
    }

    return (
      <Row className={reverse}>
        {
          advices.map((advice: string, index: number) => {
            const advisetitle = adviceTotitle(advice)
            return (
              <Col lg={webSize} md={webSize} sm={webSize} xs={12} className="recomendation-column" key={index}>
                <Row>
                  <Col lg={1} xs={1} className="recomendation-icon-freq-col d-flex align-items-center justify-content-end">
                    <img className="recomendation-icon" src={advisetitle[0]} alt="Icono de recomendación"></img>
                  </Col>
                  <Col lg={11} xs={11} className="food-freq-col">
                    <div className="food-freq">{advisetitle[1]}</div>
                  </Col>
                </Row>
                <Col className="food-recomendation-list">
                  {
                    subcategoryData[advice].map((food: string, ix: number) => {
                      return (
                        checkFoodConsistency(food, advice) ?
                          <div className="food" key={`${index}-${ix}`}>
                            <MynuTooltip description={'Para saber más, click aquí!'} position='top'>
                              <Badge
                                pill
                                className={`badge-${advice.includes('prefer') ? 'prefer' : advice} point`}
                                onClick={() => {
                                  navigate('/results-explanation/'
                                    + `${categoryName}/${subcategoryData.subcategory_name}/`
                                    + `${predisposition}/`
                                    + `${advice.includes('prefer') ? 'prefer' : advice}/${food}`);
                                }}
                              >
                                {food}
                              </Badge>
                            </MynuTooltip>
                          </div>
                          : null
                      )
                    })
                  }
                </Col>
              </Col>
            )
          })
        }
      </Row>
    )
  }

  return (
    <div>
      <Card className="result-card mt-3 mb-2">
        <Card.Header className="fw-bolder my-4 text-center ">
          <span className="categories-title">Categorías</span>
        </Card.Header>
        <RenderMainAccordion data={data} />
      </Card>
    </div>
  );
}

export default ResultsCategories;
