import { useState } from "react";

import Button from "react-bootstrap/Button";
import Card from 'react-bootstrap/Card';
import Carousel from 'react-bootstrap/Carousel';
import CarouselItem from 'react-bootstrap/CarouselItem';
import Modal from 'react-bootstrap/Modal';

import { ArrowLeftCircle, XCircleFill } from "react-bootstrap-icons";

import fesTutorialImg1 from '@/assets/fes/images/tutorial/1.webp'
import fesTutorialImg2 from '@/assets/fes/images/tutorial/2.webp'
import fesTutorialImg3 from '@/assets/fes/images/tutorial/3.webp'
import fesTutorialImg3_1 from '@/assets/fes/images/tutorial/3-1.webp'
import fesTutorialImg4 from '@/assets/fes/images/tutorial/4.webp'
import fesTutorialImg5 from '@/assets/fes/images/tutorial/5.webp'
import fesTutorialImg6 from '@/assets/fes/images/tutorial/6.webp'

import './FesTutorial.scss';

type tutorialSlidesProps = {
  title: JSX.Element;
  description: JSX.Element;
  buttonText: string;
  imageSrc?: string;
  extraImg?: string
}

const tutorialSlidesInfo = [{
  title: <div className="title"><div className="title1">¡Bienvenida/o a</div><div className="title2">My Pauta!</div></div>,
  description: <div><span>Esta sección te ayudará a crear tu propio menú, según lo que </span><span className="highlighted-description"> quieras comer o tengas disponible.</span></div>,
  buttonText: 'Empezar'
},
{
  title: <div className="title">¡Organiza tus tiempos!</div>,
  description: <div>Por cada día, habrá una propuesta de grupos de alimentos necesarios para cumplir con tus requerimientos.</div>,
  imageSrc: fesTutorialImg1,
  buttonText: '¡Vamos!'
},
{
  title: <div className="title">Añade tus alimentos</div>,
  description: <div>Busca tus opciones preferidas para consumir en cada tiempo de comida.</div>,
  imageSrc: fesTutorialImg2,
  buttonText: 'Siguiente'
},
{
  title: <div className="title">Alimentación 100% personalizada</div>,
  description: <div>Cada alimento tiene un <span className="highlighted-description">puntaje</span>, con el que puedes conocer los alimentos más beneficiosos según tu genética.</div>,
  imageSrc: fesTutorialImg3,
  extraImg: fesTutorialImg3_1,
  buttonText: 'Continúa ;)'
},
{
  title: <div className="title">Agrega + alimentos</div>,
  description: <div>Puedes agregar nuevos grupos en el botón + de abajo.</div>,
  imageSrc: fesTutorialImg4,
  buttonText: '¡Ya casi!'
},
{
  title: <div className="title">Calcula tus porciones</div>,
  description: <div>Haz click en <span className="highlighted-description">calcular porciones</span> para ver la cantidad que debes consumir, y así cumplir tus objetivos.</div>,
  imageSrc: fesTutorialImg5,
  buttonText: '¡Un paso más!'
},
{
  title: <div className="title">Tu reporte Diario</div>,
  description: <div>Al final del día, ve tu <span className="highlighted-description">reporte diario</span>!<br />Este cuenta con una calificación en base a tus elecciones alimentarias y sus nutrientes asociados, además de <span className="highlighted-description">recomendaciones</span> para mejorar tu alimentación.</div>,
  imageSrc: fesTutorialImg6,
  buttonText: '¡Tu turno!'
}];

interface fesTutorialProps {
  show: boolean,
  handleClose: (x: boolean) => void
}

export default function FesTutorial({ show, handleClose }: fesTutorialProps) {
  const [index, setIndex] = useState(0);

  const handleSelect = (id: number) => {
    setIndex(id);
  }
  const handleCloseModal = () => {
    handleClose(false);
    setIndex(0);
  }

  return (
    <Modal
      className="fes-tutorial-modal"
      show={show}
      handleClose={handleClose}
      onHide={() => handleCloseModal()}
      centered
    >

      <Modal.Header>
        <div className="back-button">
          {index !== 0 ?
            <ArrowLeftCircle size={28} style={{ cursor: 'pointer' }} onClick={() => setIndex(index - 1)} />
            : null}
        </div>
        <div className="close-button">
          <XCircleFill size={28} onClick={() => handleCloseModal()} />
        </div>

      </Modal.Header>
      <Modal.Body className="p-0">

        <Card className='fes-tutorial-card'>
          <Card.Body>

            <Carousel
              className="animated-carousel"
              activeIndex={index}
              onSelect={handleSelect}
              data-bs-theme="dark"
              interval={null}
              controls={false}
              indicators={false}
              wrap={false}
            >

              {tutorialSlidesInfo.map(({ title, description, buttonText, imageSrc, extraImg }: tutorialSlidesProps, i) => (
                <CarouselItem key={`fes-tutorial-slide-${i}`} id={`fes-tutorial-slide-id-${i}`} className="p-0">
                  {i > 0 ? <div className="card-img mb-3">
                    <img src={imageSrc} alt={`Imagen tutorial ${i}`} />
                  </div> : null}
                  <Card.Text>
                    <div>
                      {title}
                    </div>
                    <div className="description1 mb-3 mt-3">
                      {description}
                    </div>
                  </Card.Text>
                  {!!extraImg ? (
                    <div className="card-img-extra mb-3"><img src={extraImg} alt={`Imagen auxiliar ${i}`} /></div>) : null}
                  <div className="card-button mb-2">
                    <Button variant="primary" onClick={() => {
                      i < tutorialSlidesInfo.length - 1 ?
                        handleSelect(i + 1) :
                        handleCloseModal()
                    }}>{buttonText}</Button>
                  </div>
                </CarouselItem>
              ))}
            </Carousel>
            <Card.Text>{index !== 0 ? index + '/' + (tutorialSlidesInfo.length - 1) : ''}</Card.Text>
          </Card.Body>
        </Card>
      </Modal.Body>
    </Modal>
  )
}