import { useMemo } from "react";

import Card from "react-bootstrap/esm/Card";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";

import RenderRecipeScore from "../RenderRecipeScore";
import AdaptationHeaderComponent from "./AdaptationHeader";
import { adaptationLevels, starsEvaluation } from "../../constants";

interface IngestedFoodQualityProps {
  score: number
}

export default function IngestedFoodQuality(props: IngestedFoodQualityProps) {
  const { score } = props;
  const adaptation = useMemo(() => {
    if (score <= -0.6)
      return adaptationLevels.VL;
    else if (score < 0)
      return adaptationLevels.L;
    else if (score < 0.8)
      return adaptationLevels.M;
    else
      return adaptationLevels.H;
  }, [score]);

  return (adaptation && score ?
    <Card className="mynu-shadow">
      <Card.Header>
        Calidad de alimentación
      </Card.Header>
      <Card.Body>
        <Container className="mb-4">
          <Row className="justify-content-center">
            <RenderRecipeScore score={score} size={'40px'} />
          </Row>
        </Container>
        <div className="d-flex flex-column align-items-center">
          <AdaptationHeaderComponent
            level={adaptation}
            title={starsEvaluation[adaptation].title}
            showIcon={adaptation !== adaptationLevels.H}
          />
          <div>
            {starsEvaluation[adaptation].recommendation}
          </div>
        </div>
      </Card.Body>
    </Card>
    : null)
}