import { useMemo } from 'react';

import Col  from 'react-bootstrap/Col';
import Row  from 'react-bootstrap/Row';

import mynuLogo from '@/assets/images/mynu.svg';

import './Spinner.scss';

type SpinnerProps = {
  size?: 'small' | 'medium' | 'large';
  rotate?: boolean;
}

function Spinner(props: SpinnerProps) {
  const { size, rotate } = props;
  
  const sanitizedSize = useMemo(() => {
    return size ?? 'small';
  }, [size]);

  const sanitizedRotate = useMemo(() => {
    return rotate ?? false;
  }, [rotate]);
  
  return (
    <Col className={
      `spinner-container-${sanitizedSize} d-flex justify-content-center align-items-center`
    }>
      <Row className="d-flex justify-content-center align-items-center">
        <img
          className={`spinner-image ${sanitizedRotate ? 'animated' : 'scale'}`}
          src={mynuLogo} 
          alt="Logo"
        />
      </Row>
    </Col>
  );
}


export default Spinner;