import { useMemo } from "react";
import { useStateStore } from "@/store/store";

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import GoalIcon from '@/assets/icons/goal-icon.svg';
import WeightIcon from '@/assets/icons/weight-icon.svg';
import BMIIcon from '@/assets/icons/bmi-icon.svg';
import ActivityIcon from '@/assets/icons/activity-icon.svg';

function ProfileSummary() {
  const store = useStateStore();
  const {
    hasQuestionnaireData,
    hasQuestionnaireOptions,
    questionnaireData,
    questionnaireOptions
  } = store;

  const weight = useMemo(() => {
    return questionnaireData ? questionnaireData.questionnaire.weightKg : -1;
  }, [questionnaireData]);

  const height = useMemo(() => {
    return questionnaireData ? questionnaireData.questionnaire.heightCm / 100 : -1;
  }, [questionnaireData]);

  const goalLabel = useMemo(() => {
    const goalId = questionnaireData ? questionnaireData.questionnaire.goalId : null;

    const foundGoal = questionnaireOptions ? questionnaireOptions.goalOptions.find(goalOption => goalOption.id === goalId) : null;

    if (!foundGoal) {
      throw new Error('Goal not found');
    }

    return foundGoal.label;
  }, [questionnaireData, questionnaireOptions]);

  return (
    hasQuestionnaireData() && hasQuestionnaireOptions() ?
      <>
        <p className="fw-bold">Mi perfil:</p>
        <Container fluid>
          <Row>
            <Col xs={6} lg={3} className="information-container ps-0">
              <img src={GoalIcon} alt="" />
              <div className="mx-2">
                <span className="information-title">{goalLabel}</span>
                <br />
                <span className="label text">Objetivo de nutrición</span>
              </div>
            </Col>
            <Col xs={6} lg={3} className="information-container pt-3 pt-lg-0 ps-0 ps-lg-3">
              <img src={WeightIcon} alt="" />
              <div className="mx-2">
                <span className="information-title">{weight} Kg</span>
                <br />
                <span className="label text">Peso actual</span>
              </div>
            </Col>
            <Col xs={6} lg={3} className="information-container pt-3 pt-lg-0 ps-0 ps-lg-3" >
              <OverlayTrigger overlay={
                <Tooltip className="imc-tooltip">
                  Índice de masa corporal
                </Tooltip>
              }>
                <img src={BMIIcon} alt="" />
              </OverlayTrigger>
              <div className="mx-2">
                <span className="information-title">{(weight / (height ** 2)).toFixed(2)}</span>
                <br />
                <span className="label text">IMC</span>
              </div>
            </Col>
            <Col xs={6} lg={3} className="information-container pt-3 pt-lg-0 ps-0 ps-lg-3">
              <img src={ActivityIcon} alt="" />
              <div className="mx-2">
                <span className="information-title">{questionnaireData?.questionnaire.engagesInSport ? 'Sí' : 'No'}</span>
                <br />
                <span className="label text">Actividad física</span>
              </div>
            </Col>
          </Row>
        </Container>
      </>
      : null
  )
}

export default ProfileSummary;
