import React, { FC, useState, useCallback, useEffect, useMemo } from 'react';

import Form from 'react-bootstrap/Form';

import { QuestionnaireCard } from './QuestionnaireCard';
import MynuTooltip from '@/components/Tooltip/Tooltip';

import './CheckBoxComponent.scss';

const { Control, Group, Label, Text } = Form;

export type CheckBoxOption = {
  id: string;
  label: string;
  tooltip?: string;
  icon?: string;
}

type CheckBoxComponentProps = {
  name: string;
  question: string;
  required: boolean;
  description?: string | React.ReactNode;
  options: CheckBoxOption[];
  initiallySelectedIds: string[];
  updateSelectedIds: (selectedIds: string[]) => void;
  isValid: (selectedIds: string[]) => boolean
  showInvalid?: boolean;
  hasNone?: boolean;
  single?: boolean;
}

const DescriptionComponent: FC<{ text: string | React.ReactNode }> = ({ text }) => {
  return (
    <p><Text>{text}</Text></p>
  )
}

const NONE_OPTION: CheckBoxOption = {
  id: 'none',
  label: 'Ninguno/a',
  tooltip: 'Selecciona esta opcion si no aplica en tu caso'
}

const CheckBoxComponent: FC<CheckBoxComponentProps> = (props: CheckBoxComponentProps) => {
  const {
    name,
    question,
    description,
    required,
    options,
    initiallySelectedIds,
    updateSelectedIds,
    isValid,
    showInvalid,
    hasNone,
    single
  } = props;
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [checkBoxOptions, setCheckBoxOptions] = useState<CheckBoxOption[]>([]);

  useEffect(() => {
    setCheckBoxOptions(
      hasNone ? [
        ...options.sort((a, b) => a.label.localeCompare(b.label)),
        NONE_OPTION
      ] : options
    )
  }, [options, hasNone])

  useEffect(() => {
    if (initiallySelectedIds?.length === 0 && hasNone) {
      setSelectedIds([NONE_OPTION.id]);
    } else {
      setSelectedIds(initiallySelectedIds);
    }
  }, [initiallySelectedIds, hasNone]);

  const triggerUpdate = useCallback((newIds: string[]) => {
    setSelectedIds(newIds);
    updateSelectedIds(newIds);
  }, [updateSelectedIds]);

  const showingInvalid = useMemo((): boolean => {
    return !isValid(selectedIds);
  }, [isValid, selectedIds]);

  const handleSwitch = useCallback((event: React.ChangeEvent<HTMLInputElement>, switchedId: string) => {
    const checked = event.target.checked;

    const element = checkBoxOptions.find(({ id }) => id === switchedId);

    if (!element) throw new Error('No such option with id: ' + switchedId);

    let newIds = !checked ? selectedIds.filter(optId => optId !== element.id)
      : single ? [element.id] : [...selectedIds, element.id];

    if (hasNone) {
      if (switchedId === NONE_OPTION.id) {
        newIds = [NONE_OPTION.id];
      } else {
        newIds = newIds.filter(opt => opt !== NONE_OPTION.id);
      }
    }

    triggerUpdate(newIds);
  }, [checkBoxOptions, triggerUpdate, selectedIds, hasNone, single]);

  return (
    <QuestionnaireCard>
      <Label className="signup-form-label mb-1">{question}</Label>
      {description ? <DescriptionComponent text={description} /> : null}
      <div
        style={{ maxHeight: options.length > 8 ? '24em' : 'none', overflow: 'auto' }}
      >
        <Group>
          {checkBoxOptions
            .map(({ id, label, tooltip, icon }: CheckBoxOption) =>
              <div className='d-flex mb-2' key={name + "-" + id}>
                <div className='d-flex px-2' >
                  <input
                    className={`form-check-input ${isValid(selectedIds) ? 'invalid' : ''}`}
                    required={required && showingInvalid}
                    style={showInvalid ? (!isValid(selectedIds) && selectedIds.includes(id) ? { backgroundColor: '#d63384' } : {}) : {}}
                    name={id}
                    type="checkbox"
                    checked={selectedIds.includes(id)}
                    onChange={(event) => handleSwitch(event, id)}
                    id={id}
                  />
                </div>
                {tooltip ?
                  <MynuTooltip description={tooltip} >
                    <label className="form-check-label fw-normal">{label}</label>
                  </MynuTooltip>
                  :
                  <div>
                    {icon ? <img src={icon} alt="" width={20} className='mx-2' /> : ''}
                    {label}
                  </div>
                }
              </div>
            )}
          <Control.Feedback>Selección inválida</Control.Feedback>
        </Group>
      </div>
    </QuestionnaireCard>)
}

export default CheckBoxComponent;