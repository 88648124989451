import { Subscription } from '@/models';
import { getSubscriptionType, limitChatGPTUses } from '@/constants'

export const validSubscription = (subscription?: Subscription) => {
    // check user has subscription
    if (!subscription) return false;

    // if user is not premium, check chatgpt uses and return false if limit is reached
    if (getSubscriptionType(subscription?.type || '') === ''){
      if (subscription.chatgpt_uses >= limitChatGPTUses) return false;
    }

    // if user is not active or subscription is expired, return false
    if (subscription?.active === false) return false;
    if (subscription?.end_date){
        const now = new Date();
        const expirationDate = new Date(subscription.end_date);
        if (now > expirationDate) return false;
    }

    // otherwise, return true
    return true;
  }