import React, { FC } from 'react';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import './Tooltip.scss';

type MynuTooltipProps = {
  description: string;
  children: React.ReactElement;
  position?: 'bottom' | 'top' | 'left' | 'right';
}

const MynuTooltip: FC<MynuTooltipProps> = (props: MynuTooltipProps) => {
  const {
    description,
    children,
    position
  } = props;

  return (
    <OverlayTrigger
      placement={position ?? 'top'}
      overlay={
        <Tooltip
          className="mynu-tooltip"
        >
          {description}
        </Tooltip>
      }
    >
      {children}
    </OverlayTrigger>
  )
}

export default MynuTooltip;