import React, { useState, useEffect } from "react";
import { Col, Row, Button, Container} from "react-bootstrap";
import './EndOfSection.scss';

interface EndOfSectionProps {
  previousSectionButtonText: string;
  previousSectionButtonHandler: () => void;
  nextSectionButtonText: string;
}

const SECTION_APPEREANCE_THRESHOLD = 0;

const EndOfSection: React.FC<EndOfSectionProps> = ({
    previousSectionButtonText,
    previousSectionButtonHandler,
    nextSectionButtonText
  }) => {
  const [offset, setOffset] = useState(0);
  
  useEffect(() => {
    const onScroll = () => setOffset(window.scrollY);
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);
  
  return (
    offset>=SECTION_APPEREANCE_THRESHOLD ? <Container
      className="end-of-section-card home-card"
    >
      <Row className="w-100">
        <Col className="questionnaire-step-button-col-container">
          <Button
            className="questionnaire-step-button"
            variant="outline-primary"
            onClick={ () => {previousSectionButtonHandler();}}
          >
            {previousSectionButtonText}
          </Button>
        </Col>
        <Col className="questionnaire-step-button-col-container d-flex justify-content-end">
          <Button
            className="questionnaire-step-button fw-bold"
            variant="primary"
            type="submit"
          >
            {nextSectionButtonText}
          </Button>
        </Col>
      </Row>
    </Container> : null
  );
}

export default EndOfSection;