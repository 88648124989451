// Custom hook for GTM events
export const ga4TrackEvent = (event: string, data: Record<string, any>) => {
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: event,
      ...data,
    });
  }
};
