import { useEffect, useState } from 'react';
import { Accordion, Card, Button } from 'react-bootstrap';
import { RiseLoader } from 'react-spinners';

// state & context
import { useStateStore } from '@/store/store';

// models & serices
import { Meal, mealOrder } from '@/models/diet-model';
import { requestNewDiet } from '@/services/diet';
import { Subscription, User } from '@/models';
import { getSubscription } from '@/services/subscription';
import { changeDailyMenu } from '@/services/diet';

// my components
import MealCard from './MealCard';
import SubscriptionModal from '@/components/SubscriptionModal/SubscriptionModal';
import SubscriptionPlanModal from '@/components/SubscriptionPlanModal/SubscriptionPlanModal';

// utils & constants
import { MYNU_PRIMARY_COLOR } from '@/utils/constants';
import { validSubscription } from '@/utils';

import { ArrowRepeat } from 'react-bootstrap-icons';
import './DietAccordion.scss';

type DietAccordionProps = {
  days: string[],
  loadDiet: () => Promise<void>,
  currentUser: User | null,
  openAccordionDay: string,
  setOpenDietAccordionDay: (day: string) => void,
  setNotificationText: (message: string) => void,
}

function DietAccordion(props: DietAccordionProps) {
  const store = useStateStore();
  const { dietInformation } = store;

  const {
    days,
    loadDiet,
    currentUser,
    openAccordionDay,
    setOpenDietAccordionDay,
    setNotificationText,
  } = props;

  const [isLoadingDay, setIsLoadingDay] = useState(false);
  const [showSubscriptionModal, setSubscriptionModal] = useState(false);
  const [showSubscriptionPlanModal, setShowSubscriptionPlanModal] = useState(false);

  const [sortedDiet, setSortedDiet] = useState<Meal[][]>([]);

  const changeMenuByDay = async (day: string) => {
    setIsLoadingDay(true);
    const userToken = localStorage.getItem('userToken');
    let subscription: Subscription | undefined = undefined;
    if (userToken) {
      subscription = await getSubscription(userToken) as Subscription;
    }

    if (!validSubscription(subscription)) {
      setSubscriptionModal(true)
    }
    else {
      try {
        const userToken = localStorage.getItem('userToken');
        if (userToken) {
          setOpenDietAccordionDay('dailyMeal-' + day)
          await changeDailyMenu(day, userToken);
          await loadDiet();
          setNotificationText(`Tu dieta del día ${day} se ha actualizado exitosamente`);
        }
      } catch (error) {
        setNotificationText('Error al actualizar tu dieta. Vuelve a intentarlo');
      }
    }
    setIsLoadingDay(false);
  }

  const toggleSubscriptionPlan = () => {
    setShowSubscriptionPlanModal(!showSubscriptionPlanModal)
  }

  useEffect(() => {
    if (dietInformation?.length) {
      const sorted = dietInformation.map((day) => {
        return day.sort((a, b) => mealOrder.indexOf(a.name) - mealOrder.indexOf(b.name))
      })
      setSortedDiet(sorted)
    }
  }, [dietInformation])


  const reloadWeeklyDiet = async () => {
    const userToken = localStorage.getItem('userToken');
    if (userToken) {
      requestNewDiet(userToken).then(async (status) => {
        if (status === 200) {
          await loadDiet();
          setNotificationText('Hemos comenzado a crear tu nueva dieta');
        } else {
          setNotificationText('No fue posible actualizar tu dieta. Por favor intenta mas tarde.')
        }
      }).catch(error => {
        setNotificationText('No fue posible actualizar tu dieta. Por favor intenta mas tarde.')
      });
    }
  }

  const handleAccordionClick = (index: number) => {
    const el = `dailyMeal-${days[index]}`;
    setOpenDietAccordionDay(openAccordionDay !== el ? el : '');
  }

  return (
    <div className='diet-accordion-container'>
      <Button
        id="request-new-diet-button"
        className='my-2 align-self-end'
        variant='outline-primary'
        onClick={reloadWeeklyDiet}
      >
        Cambiar Menú Semanal
      </Button>
      <Accordion className='w-100 pb-2' activeKey={openAccordionDay} alwaysOpen>
        {sortedDiet.length > 0 &&
          sortedDiet.map((dailyMeal: Meal[], index) => {
            return (
              <Accordion.Item
                eventKey={"dailyMeal-" + days[index]}
                key={"dailyMeal-" + days[index]}
                id={"dailyMeal-" + days[index]}
              >
                <div className='diet-day'>
                  <Accordion.Header
                    style={{ borderRadius: '25px' }}
                    onClick={() => handleAccordionClick(index)}
                  >
                    <span className="fw-bold">
                      {days[index]}
                    </span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className='w-100 py-2 d-flex flex-column'>
                      <Button
                        variant="outline-secondary"
                        className="day-button d-flex align-items-center align-self-end"
                        onClick={() => changeMenuByDay(days[index])}
                        disabled={isLoadingDay}
                        id={'change-meals-for-day-btn'}
                      >
                        Cambiar dieta del día
                        <ArrowRepeat size={24} className='mx-2' />
                      </Button>
                    </div>
                    <div className='meal-cards-container'>
                      {dailyMeal.map((meal: Meal, index) =>
                        <div key={meal.name + "-" + index} className='meal-card-container'>
                          {isLoadingDay ?
                            <Card className="loader-card meal-card mb-3">
                              <RiseLoader
                                color={MYNU_PRIMARY_COLOR}
                                loading={true}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                                size={16}
                                speedMultiplier={0.8}
                              />
                            </Card>
                            :
                            <MealCard
                              meal={meal}
                              index={index}
                              day={meal.day}
                              loadDiet={loadDiet}
                              showSubscriptionModal={() => setSubscriptionModal(true)}
                              currentUser={currentUser}
                              setNotificationText={setNotificationText}
                            />
                          }
                        </div>
                      )}
                    </div>
                  </Accordion.Body>
                </div>
              </Accordion.Item>
            )
          })
        }
      </Accordion>

      <SubscriptionModal
        show={showSubscriptionModal}
        onClose={() => setSubscriptionModal(false)}
        onAccept={() => { setSubscriptionModal(false); toggleSubscriptionPlan(); }}
      />
      <SubscriptionPlanModal
        showModal={showSubscriptionPlanModal}
        subscription={currentUser?.subscription ?? null}
        onHide={toggleSubscriptionPlan}
      />
    </div>
  )
}


export default DietAccordion
