import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';

import redcompra from '@/assets/images/redcompra.png';
import { Cart, LockFill, XLg } from 'react-bootstrap-icons';

import { KIT_PRICE_CLP } from '@/utils/constants';
import { formatCLP } from '@/utils';
import { ReveniuPlan } from '@/models';

export type ShoppingCartProps = {
  selectedPlan: ReveniuPlan;
  kitQuantity: number;
  kitData: {
    kitImage: string;
    kitDescription: string;
  }
  step: number;
  handleSetToggleBackground: () => void;
}

export default function ShoppingCart(props: ShoppingCartProps) {
  const {
    selectedPlan,
    kitQuantity, kitData,
    handleSetToggleBackground, step,
  } = props;

  const { kitImage } = kitData;

  return (
    <>
      {
        step !== 3 &&
        <Accordion className='shopping-cart desktop-hidden' onSelect={handleSetToggleBackground} >
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <div className='shopping-cart-header-container'>
                <div className='shopping-cart-header'>Carrito de compra</div>
                <Cart className='shopping-cart-icon' />
              </div>
            </Accordion.Header>
            <Accordion.Body className='shopping-cart-item-container'>
              {
                kitQuantity > 0 ?
                  CartItem({
                    name: 'Kit Genético',
                    image: kitImage,
                    quantity: kitQuantity,
                    price: selectedPlan.price
                  })
                  :
                  <div className='shopping-cart-empty'>
                    Tu carrito está vacío, porfavor selecciona la cantidad de kits que quieras comprar
                  </div>
              }
              <div className='shopping-cart-secure-transaction-banner'>
                <img src={redcompra} alt='Paypal' className='secure-transaction-thumb-img' />
                <div className='shopping-cart-secure-transaction-text'>
                  <LockFill className='shopping-cart-lock-icon mx-2' />
                  <div>Esta Compra Está Protegida</div>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      }
      <Card className='shopping-cart mobile-hidden'>
        <Card.Header className='shopping-cart-header-container'>
          <div className='shopping-cart-header'>Carrito de compra</div>
          <Cart className='shopping-cart-icon' />
        </Card.Header>
        <div className='shopping-cart-item-container'>
          {
            kitQuantity > 0 ?
              CartItem({
                name: 'Kit Genético',
                image: kitImage,
                quantity: kitQuantity,
                price: selectedPlan.price
              })
              :
              <div className='shopping-cart-empty'>
                Tu carrito está vacío, porfavor selecciona la cantidad de kits que quieras comprar
              </div>
          }
          <div className='shopping-cart-secure-transaction-banner'>
            <img src={redcompra} alt='Paypal' className='secure-transaction-thumb-img' />
            <div className='shopping-cart-secure-transaction-text'>
              <LockFill className='shopping-cart-lock-icon mx-2' />
              <div>Esta compra está protegida</div>
            </div>
          </div>
        </div>
      </Card>
    </>
  )
}

type cartItemProps = {
  name: string;
  image: string;
  quantity: number;
  price: number;
};

function CartItem(props: cartItemProps) {
  const {
    name,
    image,
    quantity,
    price
  } = props;

  return (
    <div className='shopping-cart-item'>
      <div className='shopping-cart-item-holder'>
        <div className='d-flex flex-row'>
          <img src={image} alt={`cart-item-${price}`} className='thumb-img shopping-cart-item-img' />
          <div className='shopping-cart-item-info align-items-start px-2'>
            <div className='shopping-cart-item-name'>{name}</div>
            <div >{quantity + ' Unidad' + (quantity > 1 ? 'es' : '')}</div>
          </div>
        </div>
        <div className='mobile-hidden mynu-bold'>
          <div className='d-flex flex-row align-items-center'>
            <div className='mx-2'><XLg /></div>
            <br />
            <div className='mt-1'>{formatCLP(KIT_PRICE_CLP)}</div>
          </div>
        </div>
      </div>

      <div className='mobile-hidden my-2'>
        <div className='hline' />
        <div className='d-flex flex-row align-items-center justify-content-between p-2'>
          <div className='shopping-cart-item-name'>
            <div className='mynu-bold'>
              Subtotal
            </div>
          </div>
          <div className=''>
            {formatCLP(KIT_PRICE_CLP * quantity)}
          </div>
        </div>
      </div>

      <div className='mobile-hidden my-2'>
        <div className='d-flex flex-row align-items-center justify-content-between p-2'>
          <div className='shopping-cart-item-name'>
            <div className='mynu-bold'>
              Oferta de lanzamiento
              <br />
              ({formatCLP(price)} por unidad)
            </div>
          </div>
          <div className='mynu-purple'>
            - {formatCLP(quantity * KIT_PRICE_CLP * (1 - price / KIT_PRICE_CLP))}
          </div>

        </div>
        <div className='hline' />
      </div>

      <div className='shopping-cart-item-price'>
        <div className='header-text'>
          Total a Pagar
        </div>
        <div className='price-text'>
          {formatCLP(quantity * price)}
        </div>
      </div>

    </div>
  )
}