import { Categories } from "@/pages/results/Categories";
import { validPredispositions } from "@/pages/results/components/resultsCategories/RestultsConstants";

export const resultsHelper = (resultsData: any) => {
  let allowed: string[] = []
  let reduced: string[] = []
  let avoided: string[] = []
  // food consistency categorization
  // checks all foods for each category->advice->food recomendation
  // and categorizes them in allowed, avoid and reduce
  // the use checkFoodConsistency function to check if the food
  // is consistent with the advice and categories
  let lactose_intollerant = false;
  if(resultsData?.find((cat: any) => cat.category_name === 'allergies') ){
    let predisposition = resultsData
      .find((cat: any) => cat.category_name === 'allergies')?.subcategories
      .find((subcat: any) => subcat.subcategory_name === 'allergies_lactose')?.predisposition;
      lactose_intollerant = predisposition === 'high' || predisposition === 'medium';
  }
  resultsData.forEach((userCategory: any) => {
    userCategory.subcategories.forEach((usrSbcat: any) => {
    let predisposition = usrSbcat.predisposition;
    Categories.forEach(category => {
      category.subcategories.forEach((subcategory: any) => {
        if(usrSbcat.subcategory_name === subcategory.subcategory_name && validPredispositions.includes(predisposition)){
          let advs = subcategory[predisposition]['advice'];
          if (!(typeof advs === 'string')){
            let advices: string[] = typeof advs === 'string' ? [advs] : advs;
            if(advices.includes('reduce') || advices.includes('avoid') || advices.some(a=>a.includes('prefer'))){
              advices.forEach((advice: string) => {
                let foodList = subcategory[advice];
                foodList.forEach((food: string) => {
                  let prfs = ['prefer', 'prefer1', 'prefer2', 'prefer3']
                  if(prfs.some(p=>advice.includes(p)) && !(reduced.includes(food) || avoided.includes(food))){
                    let food_l = food.toLocaleLowerCase();
                    if (lactose_intollerant && food_l.includes('lácteos con lactosa')){
                      return;
                    }
                    const newAllowed = Array.from(new Set([...allowed, food]));
                    allowed = newAllowed;
                  }
                  if(advice.includes('reduce') && !allowed.includes(food)){
                    const newReduced = Array.from(new Set([...reduced, food]));
                    reduced = newReduced;
                  }
                  if(advice.includes('avoid') && !allowed.includes(food)){
                    const newAvoided = Array.from(new Set([...avoided, food]));
                    avoided = newAvoided;
                  }
                })
              })
            }     
          }
        }
      })
      })
    })
  });

  return [allowed, reduced, avoided];
}
