import { useEffect, useState, useCallback } from "react";

import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';

import EndOfSection from "./components/EndOfSection";
import CheckBoxComponent from "./components/CheckBoxComponent";
import CustomDropDownComponent from "./components/CustomDropDownComponent";
import SelectComponent from "./components/SelectComponent";
import QuestionnaireHeader from "./components/QuestionnaireSectionHeader";
import PickerList from "./components/PickerList";
import QuestionnaireExitModal from './QuestionnaireExitModal';
import CloseQuestionnaire from './CloseQuestionnaire';

import { FormDailyRoutine, QuestionnaireOptions, SportData } from '@/models';

interface QuestionnaireFormDailyRoutinesProps {
  options: QuestionnaireOptions;
  updateForm: (form: FormDailyRoutine) => void;
  gotoNextSection: () => void,
  currentForm: FormDailyRoutine;
  goBack: (data: FormDailyRoutine) => void;
  close: (data: FormDailyRoutine, save: boolean) => void;
}

const QuestionnaireFormDailyRoutines:
  React.FC<QuestionnaireFormDailyRoutinesProps> =
  ({
    options,
    updateForm,
    gotoNextSection,
    currentForm,
    goBack,
    close
  }) => {
    const [validated, setValidated] = useState(false);
    const [showExitModal, setShowExitModal] = useState(false);
    const [form, setForm] = useState<FormDailyRoutine>({
      sleepHoursId: undefined,
      sleepDescriptionIds: [],
      engagesInSport: undefined,
      sportsData: [],
      dailyActivityLevelId: undefined,
      meanOfTransportId: undefined
    });
    const [sportIds, setSportIds] = useState<string[]>([]);
    const [sportPerWeek, setSportPerWeek] = useState<{ id: string, timesPerWeek: number }[]>([]);

    const updateField = useCallback((name: string, newValue: any) => {
      const newValues = {
        ...form,
        [name]: newValue,
      };
      setForm(newValues);
    }, [form]);

    useEffect(() => {
      setForm(currentForm);
      setSportIds(currentForm.sportsData.filter((sportData: SportData) => {
        return options.sportOptions.map(sportOption => sportOption.id).includes(sportData.sportId);
      }).map(sportData => {

        const sport = options.sportOptions.find(sportOption => sportOption.id === sportData.sportId);

        if (!sport) throw new Error('No sport with id: ' + sportData.sportId);

        return sport.id;
      }));

      setSportPerWeek(currentForm.sportsData.filter((sportData: SportData) => {
        return options.sportOptions.map(sportOption => sportOption.id).includes(sportData.sportId);
      }).map(sportData => {

        const sport = options.sportOptions.find(sportOption => sportOption.id === sportData.sportId);

        if (!sport) throw new Error('No sport with id: ' + sportData.sportId);

        return { id: sport.id, timesPerWeek: Number(sportData.timesPerWeek) };
      }));

    }, [currentForm, options]);

    const updateSportperWeek = (id: string, timesPerWeek: number) => {

      const newSportPerWeek = sportPerWeek.filter(sp => sp.id !== id);
      newSportPerWeek.push({ id, timesPerWeek });
      setSportPerWeek(newSportPerWeek);
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      event.stopPropagation();

      const formTarget = event.currentTarget;

      if (formTarget.checkValidity() === true) {
        const newSportsData = sportIds.map(spId => {
          const sportInOptions = options.sportOptions.find(sportOption => sportOption.id === spId);
          if (!sportInOptions) throw new Error('No sport in options with id: ' + spId);
          const sportInPerWeek = sportPerWeek.find(el => el.id === spId);
          if (!sportInPerWeek) throw new Error('No sport in perWeekState with id: ' + spId);
          return {
            sportId: sportInOptions.id,
            timesPerWeek: sportInPerWeek.timesPerWeek
          }
        });

        const newForm = {
          ...form,
          sportsData: newSportsData
        };

        updateForm(newForm);
        setShowModal(false);
        gotoNextSection();
      } else {
        setValidated(true);
        setShowModal(true);
      }
    };

    const handleExit = () => {
      setShowExitModal(true);
    };

    const [showModal, setShowModal] = useState(false);

    return (
      <Container>
        <div className="py-3">
          <CloseQuestionnaire onClick={handleExit} text="Volver al inicio" />
        </div>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>

          <QuestionnaireHeader
            title="Tus hábitos diarios"
            subtitle="Entender tu rutina diaria nos proporciona un contexto más amplio para personalizar tu plan de alimentación"
            numerator={2}
            denominator={3}
          />

          <SelectComponent
            name="sleepHoursId"
            required={true}
            question={"En promedio ¿Cuánto duermes por noche?"}
            options={options.sleepHourOptions}
            updateValue={(optionId: string) => {
              updateField('sleepHoursId', optionId);
            }}
            initialValue={form.sleepHoursId}
          />

          <CheckBoxComponent
            name="sleepDescriptionIds"
            question="¿Cómo describirías tu sueño?"
            description="Puedes seleccionar más de una opción"
            options={options.sleepDescriptionOptions}
            initiallySelectedIds={form.sleepDescriptionIds}
            updateSelectedIds={(selectedIds) => {
              updateField('sleepDescriptionIds', selectedIds);
            }}
            isValid={(selectedIds) => selectedIds.length > 0}
            required
            hasNone
          />

          <SelectComponent
            name={"engagesInSport"}
            question={"¿Haces deporte?"}
            options={[
              { id: "engagesInSport-yes", label: "Sí" },
              { id: "engagesInSport-no", label: "No" },
            ]}
            required={true}
            updateValue={(value: string) => {
              updateField('engagesInSport', value === "engagesInSport-yes");
              setSportIds([]);
            }}
            initialValue={form.engagesInSport !== undefined ? (
              form.engagesInSport ? "engagesInSport-yes" : "engagesInSport-no") : undefined}
            isValid={(value: string) => true}
            showInvalid={validated}
          />

          {form.engagesInSport &&
            <PickerList
              name="sports-checkbox-"
              question="Indica el(los) deporte(s) que realizas y cuántas veces lo haces a la semana"
              description="Recuerda ser lo más sincero posible, ya que define el cálculo de calorías diarias."
              required={false}
              options={options.sportOptions}
              initiallySelectedIds={sportIds}
              updateSelectedIds={(selectedIds) => {
                setSportIds(selectedIds);
              }}
              isValid={(selectedIds) => selectedIds.length >= 0}
              selectorOptions={sportPerWeek.map((sport) => ({ id: sport.id, value: sport.timesPerWeek }))}
              OptionAdornmentComponent={CustomDropDownComponent}
              updateAdornmentOptions={(id, value) => updateSportperWeek(id, Number(value))}
              renderAdornmentName={"-sport-intensity"}
              renderAdornmentValues={[1, 2, 3, 4, 5, 6, 7, 8].map(num => ({ label: num.toString(), id: num.toString() }))}
              showInvalid={validated}
              adornmentText={"¿Cuántas veces por semana?"}
              adornmentTextOffset={10}
            />
          }

          <SelectComponent
            name="dailyActivity"
            required={true}
            question={"De acuerdo a tu rutina diaria (laboral, estudiantil, etc…) ¿Cómo describirías tu nivel de actividad?"}
            options={options.dailyActivityOptions}
            initialValue={form.dailyActivityLevelId}
            updateValue={(optionId: string) => {
              updateField('dailyActivityLevelId', optionId);
            }}
          />
          <SelectComponent
            name="meanOfTransport"
            required={true}
            question={"En general ¿Cómo te desplazas a tu trabajo?"}
            options={options.meansOfTransportOptions}
            initialValue={form.meanOfTransportId}
            updateValue={(optionId: string) => {
              updateField('meanOfTransportId', optionId);
            }}
          />

          <EndOfSection
            previousSectionButtonText="Atrás"
            previousSectionButtonHandler={() => {
              goBack(form);
            }}
            nextSectionButtonText="Siguiente"
          />

          <br></br>

        </Form>

        <QuestionnaireExitModal
          show={showExitModal}
          onClose={() => setShowExitModal(false)}
          onExit={(save) => {
            close(form, save);
          }}
        />
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Porfavor completa todos los campos</Modal.Title>
          </Modal.Header>
        </Modal>
      </Container>
    )
  };

export default QuestionnaireFormDailyRoutines;