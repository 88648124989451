import { useNavigate } from 'react-router';

import Button from 'react-bootstrap/Button';

import { ArrowLeftShort } from 'react-bootstrap-icons';
import './CloseQuestionnaire.css';

type BackButtonProps = {
  text: string;
  onClick?: () => void;
}

function CloseQuestionnaire({ text, onClick }: BackButtonProps) {
  const navigate = useNavigate();
  const goHome = () => {
    navigate('/');
  }
  return (

    <Button onClick={onClick || goHome} variant="link" className="home-button">
      <ArrowLeftShort size={26} />{text}
    </Button>
  )
}

export default CloseQuestionnaire;