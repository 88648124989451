import { createContext, useEffect, useState, useContext } from 'react';

import { User } from '@/models';
import { getCurrentUser } from '@/services/user/user';
import { useStateStore } from '@/store/store';

type AuthContextValue = {
  user: User | null
  loadUser: () => Promise<void>
}

const AuthContext = createContext<AuthContextValue>({
  user: null,
  loadUser: () => Promise.resolve()
});

export default function AuthProvider({ children }: { children: JSX.Element }) {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<User | null>(null);
  const store = useStateStore();
  const {
    setUserData,
    setUserToken
  } = store;

  const loadUser = async () => {
    const userToken = localStorage.getItem('userToken');

    if (userToken) {
      try {
        const newUser = await getCurrentUser(userToken);
        setUser(newUser);
        setUserData(newUser);
        setUserToken(userToken);
      } catch (e) {
        setUser(null);
        setUserData(null);
      }
    } else {
      setUser(null);
    }
  }

  useEffect(() => {
    const userToken = localStorage.getItem('userToken');

    if (userToken) {
      getCurrentUser(userToken)
        .then((user) => {
          setUser(user);
          setUserData(user);
          setUserToken(userToken);
        })
        .catch(() => {
          setUser(null);
          setUserData(null);
        })
        .finally(() => setIsLoading(false))
    } else {
      setUser(null)
      setIsLoading(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) {
    return null;
  }

  return (
    <AuthContext.Provider value={{ user, loadUser }}>
      {children}
    </AuthContext.Provider>
  )
}

export function useCurrentUser(): User | null {
  const context = useContext(AuthContext)
  return context.user
}

export function useLoadUser(): () => Promise<void> {
  const context = useContext(AuthContext)
  return context.loadUser
}
