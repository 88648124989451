import { StateCreator } from 'zustand';
import { Meal } from '@/models/diet-model';
import { AppState, DietStore } from '../interfaces';
import { NutritionalRecommendation } from '@/pages/diets/summary/components/SummaryItem/DietSummaryComponent';
import { isEmptyOrUndefined } from '@/utils';

export const createDietSlice: StateCreator<
  AppState,
  [],
  [],
  DietStore
> = (set, get) => ({
  // diet information
  dietInformation: null,
  setDietInformation: (d: Meal[][]) => set(() => ({ dietInformation: d })),
  isLoadingDiet: false,
  setIsLoadingDiet: (l: boolean) => set(() => ({ isLoadingDiet: l })),
  dietStatus: '',
  setDietStatus: (s: string) => set(() => ({ dietStatus: s })),
  dietCreationFailed: false,
  setDietCreationFailed: (d: boolean) => set(() => ({ dietCreationFailed: d })),
  hasDiet: () => !!get().dietInformation,
  
  // diet summary
  dietSummary: null,
  setDietSummary: (d: NutritionalRecommendation | null) => set(() => ({ dietSummary: d })),
  hasDietSummary: () => !isEmptyOrUndefined(get().dietSummary, 'obj'),
  isLoadingDietSummary: false,
  setIsLoadingDietSummary: (v: boolean) => set(() => ({ isLoadingDietSummary: v })),
});