import './ToggleButton.scss'

export interface ToggleButtonComponentProps {
  value?: boolean;
  changeValue?: (value: boolean) => void;
  on: JSX.Element,
  off: JSX.Element
}

export default function ToggleButtonComponent(props: ToggleButtonComponentProps) {
  const { value, changeValue, on, off } = props;

  const handleClick = () => {
    changeValue && changeValue(!value);
  }

  return (
    <div className="toggle-button-container">
      <div className={`toggle-button-component ${value ? 'right' : 'left'}`} onClick={handleClick}>
        <div className={`p-1 icon-component  ${value ? 'animate-right' : 'animate-left'}`}>
          {value ? on : off}
        </div>
      </div>
    </div>
  )
}