import { useState } from 'react';
import { Link } from 'react-router-dom';

import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';

import FormButton from '@/components/FormButton/FormButton';
import Spinner from '@/components/Spinner/Spinner';

import { LoginData } from '@/models';

import { EyeFill, EyeSlashFill } from 'react-bootstrap-icons';

const { Group, Label, Control } = Form;

interface LoginFormProps {
  loginUser(data: LoginData): Promise<void>
}


function LoginForm({ loginUser }: LoginFormProps) {
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [inputPasswordType, setInputPasswordType] = useState('password');
  const [formData, setFormData] = useState({
    username: '',
    password: ''
  })

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {

    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;

    if (form.checkValidity()) {
      setIsLoading(true);
      loginUser(formData).then(() => {
        setIsLoading(false);
      });
    } else {
      setValidated(true);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const newValues = {
      ...formData,
      [name]: value,
    }
    setFormData(newValues);
  };

  const changeToShowPassword = () => {
    if (inputPasswordType === 'password') {
      setInputPasswordType('text');
    } else {
      setInputPasswordType('password');
    }
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Group className="mb-2" controlId="login-email">
        <Label className="signup-form-label mb-1">Correo electrónico:</Label>
        <Control
          required
          name="username"
          type="email"
          placeholder="ejemplo@mail.cl"
          value={formData.username}
          onChange={handleChange}
        />
        <Control.Feedback type="invalid">
          Formato de correo inválido
        </Control.Feedback>
      </Group>

      <Label htmlFor="login-form-password" className='signup-form-label'>
        Contraseña:
      </Label>
      <InputGroup className="mb-2">
        <Control
          id="login-form-password"
          name="password"
          required
          type={inputPasswordType}
          value={formData.password}
          onChange={handleChange}
        />
        <InputGroup.Text
          onClick={changeToShowPassword}
          className="password-icon"
          role="button"
        >
          {
            inputPasswordType === 'password' ?
              <EyeFill size={20} />
              :
              <EyeSlashFill size={20} />
          }
        </InputGroup.Text>
        <Control.Feedback type="invalid">
          Debes ingresar tu contraseña
        </Control.Feedback>
      </InputGroup>
      <div className="text-center py-3 redirect-note">
        <Link className="link-forgot-password" to="../password-recovery">¿Olvidaste tu clave?</Link>
      </div>

      {isLoading ? (
        <div
          className='d-flex justify-content-center align-items-center'
        >
          <Spinner />
        </div>
      ) : (
        <div className="text-center">
          <FormButton text="Iniciar sesión" />
        </div>
      )}
    </Form>
  );
}

export default LoginForm;