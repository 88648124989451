import { useState } from 'react';

import { useForm, SubmitHandler, SubmitErrorHandler } from "react-hook-form";
import { zodResolver } from '@hookform/resolvers/zod';
import { z, ZodType } from 'zod';

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import { NewUser } from '@/models';

import FormButton from '@/components/FormButton/FormButton';

import './SignupForm.scss';
import { EyeFill, EyeSlashFill } from 'react-bootstrap-icons';
import { PASSWORD_REGEX, PHONE_NUMBER_REGEX } from '@/utils/constants';

interface Props {
  submitUserData(userData: NewUser): Promise<void>;
  showAlert(text: string): void;
}

enum FormIds {
  GIVEN_NAME = 'givenName',
  FAMILY_NAME = 'familyName',
  EMAIL = 'email',
  PHONE_NUMBER = 'phoneNumber',
  PASSWORD = 'password',
  CONFIRM_PASSWORD = 'confirmPassword',
  CHECKBOX = 'acceptedConditions',
}

enum FormTypes {
  TEXT = 'text',
  PASSWORD = 'password',
  EMAIL = 'email',
  CHECKBOX = 'checkbox'
}

interface FormFields {
  [FormIds.GIVEN_NAME]: string,
  [FormIds.FAMILY_NAME]: string,
  [FormIds.EMAIL]: string,
  [FormIds.PHONE_NUMBER]: string,
  [FormIds.PASSWORD]: string,
  [FormIds.CONFIRM_PASSWORD]: string,
  [FormIds.CHECKBOX]: boolean,
}
interface FormFieldParams {
  id: FormIds;
  label: string;
  placeholder: string;
  type: FormTypes;
  text?: string;
}

const FormSchema: ZodType<FormFields> = z
  .object({
    [FormIds.GIVEN_NAME]: z
      .string({ required_error: 'Debes ingresar tu nombre' })
      .min(1, { message: 'Debes ingresar tu nombre' })
      .max(255, { message: 'Por favor sólo usa hasta 255 caracteres' }),

    [FormIds.FAMILY_NAME]: z
      .string({ required_error: 'Debes ingresar tu apellido' })
      .min(1, { message: 'Debes ingresar tu nombre' })
      .max(255, { message: 'Por favor sólo usa hasta 255 caracteres' }),

    [FormIds.EMAIL]: z
      .string({ required_error: 'Debes ingresar tu correo' })
      .email({ message: 'Formato Incorrecto' }),

    [FormIds.PHONE_NUMBER]: z
      .string({ required_error: 'Debes ingresar tu teléfono' })
      .min(9, 'Debes introducir al menos 9 dígitos')
      .refine((value) => PHONE_NUMBER_REGEX.test(value ?? ""), 'Debes usar el formato correcto'),

    [FormIds.PASSWORD]: z
      .string({ required_error: 'Debes escribir una contraseña' })
      .min(8, { message: 'La contraseña es muy corta' })
      .max(30, { message: 'Por favor sólo usa hasta 30 caracteres' })
      .refine((value) => PASSWORD_REGEX.test(value), 'La contraseña debe seguir las condiciones'),

    [FormIds.CONFIRM_PASSWORD]: z
      .string({ required_error: 'Debes escribir la contraseña otra vez' })
      .min(8, { message: 'La contraseña es muy corta' })
      .max(30, { message: 'Por favor sólo usa hasta 30 caracteres' }),

    [FormIds.CHECKBOX]: z
      .boolean({ required_error: '' }),
  })
  .superRefine((data, ctx) => {
    if (data[FormIds.PASSWORD] !== data[FormIds.CONFIRM_PASSWORD]) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Las contraseñas no coinciden',
        path: [FormIds.CONFIRM_PASSWORD]
      });
    }
  });

const inputs: FormFieldParams[] = [
  {
    id: FormIds.GIVEN_NAME,
    label: 'Nombre(s)',
    type: FormTypes.TEXT,
    placeholder: 'Javiera Andrea',
  },
  {
    id: FormIds.FAMILY_NAME,
    label: 'Apellido(s)',
    type: FormTypes.TEXT,
    placeholder: 'Reyes Silva'
  },
  {
    id: FormIds.PHONE_NUMBER,
    label: 'Celular (Incluye código de país)',
    type: FormTypes.TEXT,
    placeholder: '+56922334455'
  },
  {
    id: FormIds.EMAIL,
    label: 'Correo electrónico',
    type: FormTypes.TEXT,
    placeholder: 'luisa@gmail.com'
  },
  {
    id: FormIds.PASSWORD,
    label: 'Contraseña',
    text: 'Mínimo 8 caracteres, números, mayúsculas y minúsculas.',
    placeholder: '••••••••••••••••',
    type: FormTypes.PASSWORD,
  },
  {
    id: FormIds.CONFIRM_PASSWORD,
    label: 'Vuelve a escribir tu contraseña',
    text: '',
    placeholder: '••••••••••••••••',
    type: FormTypes.PASSWORD,
  },
  {
    id: FormIds.CHECKBOX,
    label: "Al registrarme acepto los <a href='https://mynu.ai/terminos/' target='_blank' rel='noreferrer'>términos y condiciones</a>",
    type: FormTypes.CHECKBOX,
    placeholder: ''
  }
]

function SignupForm({ submitUserData, showAlert }: Props) {
  const [acceptedConditions, setAcceptedConditions] = useState(false);

  const { register, handleSubmit, formState: { errors } } = useForm<FormFields>({
    resolver: zodResolver(FormSchema)
  });

  const [inputPasswordType, setInputPasswordType] = useState<FormTypes.PASSWORD | FormTypes.TEXT>(FormTypes.PASSWORD);

  const changeToShowPassword = () => {
    if (inputPasswordType === FormTypes.PASSWORD) {
      setInputPasswordType(FormTypes.TEXT);
    } else {
      setInputPasswordType(FormTypes.PASSWORD);
    }
  };

  const onSubmit: SubmitHandler<FormFields> = async (data) => {

    const formDataDTO = {
      givenName: data.givenName,
      familyName: data.familyName,
      phoneNumber: data.phoneNumber,
      email: data.email,
      password: data.password,
    }
    await submitUserData(formDataDTO);
  };

  const onError: SubmitErrorHandler<FormFields> = (_) => {
    showAlert('Debes completar todos los campos.');
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit, onError)}>
      {inputs.map((inputField, i) => {

        const { id, label, placeholder, text, type } = inputField;

        return (
          <Form.Group
            key={`signup-form-field-${id}-${i}`}
            id={`signup-form-field-${id}-${i}`}
            controlId={`signup-form-field-${id}`}
            className={inputField.type !== FormTypes.CHECKBOX ? "mb-2" : "mt-3 mb-2 form-assistive-text"}
          >
            {inputField.type !== FormTypes.CHECKBOX ?
              <>
                <Form.Label>{label}</Form.Label>
                <InputGroup>
                  <Form.Control
                    type={type === FormTypes.PASSWORD ? inputPasswordType : type}
                    placeholder={placeholder}
                    {...register(id)}
                  />
                  {type === FormTypes.PASSWORD ?
                    <InputGroup.Text
                      onClick={changeToShowPassword}
                      className="password-icon"
                      role="button"
                    >
                      {inputPasswordType === FormTypes.PASSWORD ? <EyeFill size={20} /> : <EyeSlashFill size={20} />}
                    </InputGroup.Text>
                    : null
                  }
                </InputGroup>
              </>
              :
              <Form.Check
                {...register(id)}
                required
                type={FormTypes.CHECKBOX}
                name="acceptedConditions"
                label={<div dangerouslySetInnerHTML={{ __html: label }} />}
                isInvalid={!acceptedConditions}
                onChange={() => setAcceptedConditions(!acceptedConditions)}
              />
            }
            {text ?
              <Form.Text className="text-muted form-assistive-text">
                {text}
                <br />
              </Form.Text>
              : null
            }
            {errors?.[id]?.message ?
              <Form.Text className='text-danger form-assistive-text'>
                {errors[id]?.message}
              </Form.Text>
              : null
            }
          </Form.Group>
        )
      })
      }

      <div className='text-center'>
        <FormButton text={'Regístrame!'} />
      </div>

    </Form >
  );
}

export default SignupForm;