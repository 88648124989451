import Form from 'react-bootstrap/Form';

import { BuyKitFormProps, FormIds } from "../../BuyKitPage";

export default function InfoStep(
  props: BuyKitFormProps
    & {
      email: string,
      emailConfirmation: string
    }) {
  const {
    email,
    emailConfirmation,
    register,
    errors
  } = props;

  return (
    <>
      <Form.Group className="my-2" controlId={'buy-kit-form-firstName'}>
        <Form.Label className="buy-kit-form-label mb-1">Nombres</Form.Label>
        <Form.Control
          {...register(FormIds.FIRST_NAME)}
          type={'text'}
          placeholder={'José Manuel'}
          className={errors?.[FormIds.FIRST_NAME]?.message ? 'is-invalid' : ''}
        />
        {errors?.[FormIds.FIRST_NAME]?.message ?
          <Form.Text className="text-danger form-assistive-text">
            {errors?.[FormIds.FIRST_NAME]?.message}
          </Form.Text>
          : null
        }
      </Form.Group>

      <Form.Group className="my-2" controlId={'buy-kit-form-lastName'}>
        <Form.Label className="buy-kit-form-label mb-1">Apellidos</Form.Label>
        <Form.Control
          {...register(FormIds.LAST_NAME)}
          type={'text'}
          placeholder={'Carrera Pérez'}
          className={errors?.[FormIds.LAST_NAME]?.message ? 'is-invalid' : ''}
        />
        {errors?.[FormIds.LAST_NAME]?.message ?
          <Form.Text className="text-danger form-assistive-text">
            {errors?.[FormIds.LAST_NAME]?.message}
          </Form.Text>
          : null
        }
      </Form.Group>

      <Form.Group className="my-2" controlId={'buy-kit-form-email'}>
        <Form.Label className="buy-kit-form-label mb-1">Correo Electrónico</Form.Label>
        <Form.Control
          {...register(FormIds.EMAIL)}
          type={'email'}
          placeholder={"jose@gmail.com"}
          className={errors?.[FormIds.EMAIL]?.message ? 'is-invalid' : ''}
        />
        {errors?.[FormIds.EMAIL]?.message ?
          <Form.Text className="text-danger form-assistive-text">
            {errors?.[FormIds.EMAIL]?.message}
          </Form.Text>
          : null
        }
      </Form.Group>

      <Form.Group className="my-2" controlId={'buy-kit-form-email-validation'}>
        <Form.Label className="buy-kit-form-label mb-1">Confirma tu correo electrónico</Form.Label>
        <Form.Control
          {...register(FormIds.EMAIL_CONFIRMATION)}
          disabled={!email}
          placeholder={"jose@gmail.com"}
          className={errors?.[FormIds.EMAIL_CONFIRMATION]?.message ? 'is-invalid' : ''}
        />
        {errors?.[FormIds.EMAIL_CONFIRMATION]?.message ?
          <Form.Text className="text-danger form-assistive-text">
            {errors?.[FormIds.EMAIL_CONFIRMATION]?.message}
            <br />
          </Form.Text>
          : null
        }
        {email && emailConfirmation && email !== emailConfirmation ?
          <Form.Text className="text-danger form-assistive-text">
            Los correos deben coincidir
          </Form.Text>
          : null
        }
      </Form.Group>

      <Form.Group className="my-2" controlId={'buy-kit-form-phoneNumber'}>
        <Form.Label className="buy-kit-form-label mb-1">Teléfono</Form.Label>
        <Form.Control
          {...register(FormIds.PHONE_NUMBER)}
          type={'text'}
          placeholder={'+56982345678'}
          className={errors?.[FormIds.PHONE_NUMBER]?.message ? 'is-invalid' : ''}
        />
        {errors?.[FormIds.PHONE_NUMBER]?.message ?
          <Form.Text className="text-danger form-assistive-text">
            {errors?.[FormIds.PHONE_NUMBER]?.message}
          </Form.Text>
          : null
        }
      </Form.Group>
    </>
  )
}