import { useMemo } from "react";

interface RenderRecipeScoreProps {
  score: number;
  size?: string
}

export default function RenderRecipeScore(props: RenderRecipeScoreProps) {
  const { score, size } = props;

  const color = useMemo(() => {
    if (score <= -0.6) {
      return '#ff474A';
    } else if (score < 0) {
      return '#ff8d1c';
    }
    return '#33b591';
  }, [score])

  const amountCircles = useMemo(() => {
    if (score <= -0.6 || score === 1 || score === 0) {
      return 3;
    } else if (score < 0 || (0.5 < score && score < 1)) {
      return 2;
    } else if (score <= 0.5) {
      return 1
    }

  }, [score])

  return (
    score !== undefined && score != null && color && amountCircles ?
      <div className="mynu-score-circles">
        {Array.from(Array(amountCircles).keys()).map((c, i: number) =>
          <div key={'score-circles-' + c + i}
            className="circles"
            style={{
              "--circle-fill": color,
              width: size,
              height: size,
              background: score !== 0 ? color : ''
            } as React.CSSProperties}
          />
        )}
        {Array.from(Array(3 - amountCircles).keys()).map((c, ii) =>
          <div key={'score-circles-remain-' + c + ii}
            className="circles"
            style={{
              "--circle-fill": color,
              width: size,
              height: size,
            } as React.CSSProperties}
          />
        )}
      </div>
      : null
  )
}
