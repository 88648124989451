import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';


export type TableHeaderComponentProps = {
  text: string
}

export const TableHeaderComponent: React.ComponentType<TableHeaderComponentProps> = (props) => {
  const { text, } = props;
  return (
    <>
      <Row>
        <Col sm="10">
          <h1 className="home-title">{text}</h1>
        </Col>
      </Row>
      <hr />
    </>
  )
}