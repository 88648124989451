import React, { useEffect, useState } from "react";

import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

import { BuyKitFormProps, DISCOUNT_CODE, FormIds } from "../../BuyKitPage";

import { DISCOUNT_PRICE_CLP, KIT_PRICE_CLP } from "@/utils/constants";

import { QuestionCircleFill } from "react-bootstrap-icons";
import { Button } from "react-bootstrap";


export default function KitStep(props: BuyKitFormProps) {
  const {
    kitQuantity,
    register,
    errors,
    selectedPlan,
    kitData,
    promoApplied,
    setPromoApplied,
  } = props;

  const {
    kitName,
    kitDescription,
    kitImage,
  } = kitData;

  const [promoCode, setPromoCode] = useState<string>('');
  const [promoFieldActive, setPromoFieldActive] = useState(false);

  useEffect(() => {
    if (kitQuantity === 1 && !promoFieldActive && !promoApplied) {
      setPromoFieldActive(true);
    } else if (promoApplied) {
      setPromoFieldActive(false);
    }

    if (kitQuantity !== 1) {
      setPromoApplied && setPromoApplied(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promoFieldActive, promoApplied, promoCode, kitQuantity])

  const handleApplyPromo = () => {
    if (kitQuantity === 1 && promoCode === DISCOUNT_CODE) {
      setPromoApplied && setPromoApplied(true);
      alert('Código de descuento aplicado');
    }
  }
  const [show, setShow] = useState(true);

  return (
    <div className="stepper-items-container">
      <div className="item">
        <div className="item-content">
          <div className="item-element">
            <img src={kitImage} alt={kitData.kitName} className="thumb-img" />
          </div>
          <div className="item-element">
            <div className="item-header-text" style={{ cursor: "pointer" }}>
              {kitName}
              &nbsp;
              <QuestionCircleFill className="info-icon" onClick={() => setShow(true)} />
            </div>
            <Markdown remarkPlugins={[remarkGfm]} className='item-description'>{kitDescription}</Markdown>
          </div>
          <div className="item-element justify-content-start d-flex flex-column align-items-end">
            <div className="item-header-text">Precio</div>
            <div className="align-self-end">
              <div className="item-price right"><s>{KIT_PRICE_CLP.toLocaleString("es-CL", { style: "currency", currency: "CLP" })}</s></div>
              <div className="item-price right color-purple">{DISCOUNT_PRICE_CLP.toLocaleString("es-CL", { style: "currency", currency: "CLP" })}</div>
            </div>
          </div>
        </div>
        <div className="item-content mt-2 align-items-center">
          <div className="w-50 px-4 item-element" >
            <Form.Group className="my-2" controlId={`buy-kit-form-${FormIds.KIT_QUANTITY}`}>
              <Form.Select
                {...register(FormIds.KIT_QUANTITY, { setValueAs: (v: string): number => parseInt(v, 10) })}
                name="kitQuantity"
                defaultValue={''}
                className={Object.keys(errors).length > 0 && !errors?.[FormIds.KIT_QUANTITY]?.message ? 'is-valid' : ''}
              >
                <option key={''} value={''}>Selecciona la cantidad de kits</option>
                {Array.from(Array(10).keys()).map((index) => (
                  <option key={index+1} value={index+1}>{index+1}</option>
                ))}
              </Form.Select>
              {errors?.[FormIds.KIT_QUANTITY]?.message ?
                <Form.Text className="text-danger form-assistive-text">
                  {errors?.[FormIds.KIT_QUANTITY]?.message}
                </Form.Text>
                : null
              }
            </Form.Group>
          </div>
          <div className="w-50 item-element text-end">
            <div className="item-price" style={{ textWrap: 'nowrap' }}>Total a pagar</div>
            <div className="item-price right color-purple">
              {kitQuantity ?
                (selectedPlan.price * kitQuantity).toLocaleString("es-CL", { style: "currency", currency: "CLP" })
                : '$0'
              }
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4 item-content align-items-center">
        <div className="mb-2">
          Tienes un código de descuento?<br />
          Úsalo acá! (Válido sólo por 1 kit)
        </div>
        <div className="d-flex flex-column justify-content-between">
          <div className="mb-1 d-flex gap-2 flex-row justify-content-start align-items-center">
            <div className="">
              <Form.Control
                value={promoCode}
                onChange={(e) => setPromoCode(e.target.value)}
                name={'discount_code'}
                type={"text"}
                placeholder={'Código de descuento'}
                disabled={!promoFieldActive}
              />
            </div>
            <div>
              <Button
                size="sm"
                variant='primary'
                type="button"
                onClick={handleApplyPromo}
                disabled={!promoFieldActive}
              >
                Aplicar
              </Button>
            </div>
          </div>
          {promoCode?.length > 0 && promoFieldActive && promoCode !== DISCOUNT_CODE ?
            <div style={{ color: 'red', fontSize: 'small' }}>
              Debes ingresar un código de descuento válido
            </div>
            : null
          }
        </div>
      </div>

      <div className="mt-4 item">
        <Form.Group className="mb-2 d-flex justify-content-center" controlId="buy-kit-formTerms">
          <div className="item justify-content-center text-center ">
            <Form.Group className="my-2" controlId={`buy-kit-form-${FormIds.ACCEPTED_CONDITIONS}`}>
              <Form.Check
                {...register(FormIds.ACCEPTED_CONDITIONS)}
                type="checkbox"
                label={<>Acepto los <a href={"https://mynu.ai/terminos/"} target={'_blank'} rel='noreferrer'>términos y condiciones</a></>}
              />
              {errors?.[FormIds.ACCEPTED_CONDITIONS]?.message ?
                <Form.Text className="text-danger form-assistive-text">
                  {errors?.[FormIds.ACCEPTED_CONDITIONS]?.message}
                </Form.Text>
                : null
              }
            </Form.Group>
          </div>
        </Form.Group>
      </div>

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Info sobre el {kitData.kitName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Por la compra de un {kitData.kitName} te llevas</p>
          <span><Markdown remarkPlugins={[remarkGfm]}>{kitData.kitDescription}</Markdown></span>
          <p>Los 3 meses premium incluyen además el uso de nuestra aplicación:</p>
          <ul>
            <li>Dieta personalizada</li>
            <li>Chat con Numy, nuestra IA especialista en nutrición </li>
            <li>Tus resultados genéticos</li>
          </ul>
        </Modal.Body>
      </Modal>
    </div>
  )
}
