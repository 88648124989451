export enum objectives {
  WEIGHT_LOSS = 'weight-loss',
  WEIGHT_GAIN = 'weight-gain',
  GAIN_MUSCLE = 'gain-muscular-weight',
  WELLNESS = 'wellness'
}

export enum nutrientTypes {
  MACROS = 'macros',
  CRITICAL_NUTRIENTS = 'critical-nutrients',
  DIET_QUALITY = 'diet-quality',
  CALORIES = 'calories'
}

export enum nutrients {
  VITAMIN_A = 'vitaminA',
  VITAMIN_C = 'vitaminC',
  VITAMIN_FOLIC_ACID = 'vitaminFolicAcid',
  VITAMIN_D = 'vitaminD',
  VITAMIN_B12 = 'vitaminB12',
  VITAMIN_B6 = 'vitaminB6',
  COPPER = 'copper',
  SELENIUM = 'selenium',
  ZINC = 'zinc',
  CALCIUM = 'calcium',
  SODIUM = 'sodium',
  POTASSIUM = 'potassium',
  IRON = 'iron'
}

export enum macroNutrients {
  PROTEINS = 'protein',
  CARBS = 'carbs',
  FAT = 'fat'
}

export enum adaptationLevels {
  VL = 'vl',
  L = 'l',
  M = 'm',
  H = 'h',
  VH = 'vh'
}

export const objectivesMap = {

  [objectives.WEIGHT_LOSS]: {
    [nutrientTypes.CALORIES]: {
      [adaptationLevels.VL]: {
        title: 'Muy bajo',
        description: 'Tu cuerpo necesita suficiente energía para mantener tu metabolismo activo.Intenta cumplir con los alimentos y cantidades en el día.'
      },
      [adaptationLevels.L]: {
        title: 'Un poco bajo',
        description: 'Añadir un poco más de alimentos te ayudará a mantener tu energía y apoyar tu metabolismo.'
      },
      [adaptationLevels.M]: {
        title: 'Lo lograste!',
        description: null,
      },
      [adaptationLevels.H]: {
        title: 'Te pasaste',
        description: '¡No te preocupes! Es parte del proceso de ajuste.Mañana busca encontrar ese equilibrio.',
      },
      [adaptationLevels.VH]: {
        title: 'Muy alto',
        description: 'Intenta ser consciente de tus elecciones y prioriza alimentos que te nutran y te hagan sentir bien'
      }
    },
    [nutrientTypes.MACROS]: {
      [macroNutrients.PROTEINS]: {
        [adaptationLevels.VL]: {
          title: 'Muy bajo',
          description: 'Las proteínas te ayudarán a controlar el hambre durante el día y mantener tu musculatura en baja de peso.Intenta incorporar más mañana.'
        },
        [adaptationLevels.L]: {
          title: 'Un poco bajo',
          description: 'Añadir un poco más de proteínas en tus comidas puede ayudarte a alcanzar tu objetivo.'
        },
        [adaptationLevels.M]: {
          title: 'Lo lograste!',
          description: null,
        },
        [adaptationLevels.H]: {
          title: 'Te pasaste',
          description: 'Un exceso ligero está bien, pero intenta mantener un balance adecuado para maximizar la pérdida de peso.'
        },
        [adaptationLevels.VH]: {
          title: 'Te pasaste',
          description: 'Para mañana, intenta enfocarte en porciones moderadas y priorizar las fuentes de carbohidratos sugeridas'
        }
      },
      [macroNutrients.CARBS]: {
        [adaptationLevels.VL]: {
          title: 'Muy bajo',
          description: 'Los carbohidratos son una fuente principal de energía para tu cuerpo y cerebro. Intenta incorporarlos mañana para tener suficiente energía.'
        },
        [adaptationLevels.L]: {
          title: 'Un poco bajo',
          description: 'Busca cumplir con las porciones de alimentos para darte el impulso de energía que necesitas.'
        },
        [adaptationLevels.M]: {
          title: 'Lo lograste!',
          description: null,
        },
        [adaptationLevels.H]: {
          title: 'Te pasaste!',
          description: 'Mañana, intenta enfocarte en opciones con carbohidratos para un mejor balance'
        },
        [adaptationLevels.VH]: {
          title: 'Muy alto',
          description: 'Para mañana, intenta enfocarte en porciones moderadas y priorizar las fuentes de carbohidratos sugeridas.'
        }
      },
      [macroNutrients.FAT]: {
        [adaptationLevels.VL]: {
          title: 'Muy bajo',
          description: 'Busca añadir grasas saludables mañana para llegar a un nivel ideal para la salud de la piel y cerebro.'
        },
        [adaptationLevels.L]: {
          title: 'Un poco bajo',
          description: 'Para mañana, ajusta mejor tus porciones para un mejor balance.'
        },
        [adaptationLevels.M]: {
          title: 'Lo lograste!',
          description: null,
        },
        [adaptationLevels.H]: {
          title: 'Te pasaste!',
          description: 'Ajusta las porciones y prioriza grasas saludables para mañana.'
        },
        [adaptationLevels.VH]: {
          title: 'Muy alto',
          description: 'Mañana intenta priorizar las grasas saludables y en las porciones adecuadas para un mejor balance.'
        }
      }
    }
  },
  [objectives.WEIGHT_GAIN]: {
    [nutrientTypes.CALORIES]: {
      [adaptationLevels.VL]: {
        title: 'Muy bajo',
        description: 'Tu cuerpo necesita más energía para alcanzar tu objetivo. Asegúrate de cumplir con las cantidades recomendadas.'
      },
      [adaptationLevels.L]: {
        title: 'Un poco bajo',
        description: 'Añadir más alimentos te acercará aún más a tus metas de ganancia de peso. ¡Estás muy cerca!'
      },
      [adaptationLevels.M]: {
        title: 'Lo lograste!',
        description: null,
      },
      [adaptationLevels.H]: {
        title: 'Te pasaste',
        description: 'Está bien si sucede algunos días, pero procura mantenerte en tu meta durante la semana para un progreso equilibrado.'
      },
      [adaptationLevels.VH]: {
        title: 'Muy alto',
        description: 'Es bueno para tu meta, pero recuerda que el balance es clave para un aumento saludable.'
      }
    },
    [nutrientTypes.MACROS]: {
      [macroNutrients.PROTEINS]: {
        [adaptationLevels.VL]: {
          title: 'Muy bajo',
          description: 'Intenta aumentar tu ingesta mañana para cuidar tu musculatura.'
        },
        [adaptationLevels.L]: {
          title: 'Un poco bajo',
          description: 'Añadir más proteínas a tus comidas puede ayudarte a alcanzar tus objetivos de ganancia de peso.'
        },
        [adaptationLevels.M]: {
          title: 'Lo lograste!',
          description: null,
        },
        [adaptationLevels.H]: {
          title: 'Te pasaste',
          description: 'Aunque más proteínas pueden apoyar tu meta, recuerda que balancear tu ingesta es clave para resultados saludables.'
        },
        [adaptationLevels.VH]: {
          title: 'Te pasaste',
          description: 'Aunque más proteínas pueden apoyar tu meta, recuerda que balancear tu ingesta es clave para resultados saludables.'
        }
      },
      [macroNutrients.CARBS]: {
        [adaptationLevels.VL]: {
          title: 'Muy bajo',
          description: 'Los carbohidratos son esenciales para mantener tu energía y apoyar tus actividades diarias. Asegúrate de incluir más mañana.'
        },
        [adaptationLevels.L]: {
          title: 'Un poco bajo',
          description: 'Añadir un poco más de carbohidratos puede darte el impulso de energía que necesitas para tus metas.'
        },
        [adaptationLevels.M]: {
          title: 'Lo lograste!',
          description: null,
        },
        [adaptationLevels.H]: {
          title: 'Te pasaste!',
          description: 'Si bien te ayuda en tu meta, mañana ajusta un poco para cumplir con tu objetivo.'
        },
        [adaptationLevels.VH]: {
          title: 'Muy alto',
          description: 'Si bien puede ayudarte, para mañana intenta enfocarte en porciones moderadas y priorizar las opciones sugeridas.'
        }
      },
      [macroNutrients.FAT]: {
        [adaptationLevels.VL]: {
          title: 'Muy bajo',
          description: 'Añade más grasas saludables para mejorar tu ingesta y apoyar la salud de tu piel y cerebro.'
        },
        [adaptationLevels.L]: {
          title: 'Un poco bajo',
          description: 'Ajustar las porciones de grasas saludables para mañana te ayudará a mantener un buen balance.'
        },
        [adaptationLevels.M]: {
          title: 'Lo lograste!',
          description: null,
        },
        [adaptationLevels.H]: {
          title: 'Te pasaste!',
          description: 'Ajusta las porciones y prioriza grasas saludables para mañana.'
        },
        [adaptationLevels.VH]: {
          title: 'Muy alto',
          description: 'Para mañana, enfócate en porciones adecuadas y grasas saludables para mantener un balance ideal.'
        }
      }
    }
  },
  [objectives.GAIN_MUSCLE]: {
    [nutrientTypes.CALORIES]: {
      [adaptationLevels.VL]: {
        title: 'Muy bajo',
        description: 'Para ganar músculo, tu cuerpo necesita suficiente energía. Intenta aumentar tu ingesta.'
      },
      [adaptationLevels.L]: {
        title: 'Un poco bajo',
        description: 'Necesitas un poco más para maximizar tu ganancia muscular.'
      },
      [adaptationLevels.M]: {
        title: 'Lo lograste!',
        description: null,
      },
      [adaptationLevels.H]: {
        title: 'Te pasaste',
        description: 'Un exceso ligero está bien, pero mantén el control para maximizar la ganancia muscular sin aumentar grasa.',
      },
      [adaptationLevels.VH]: {
        title: 'Muy alto',
        description: 'Un exceso de calorías puede llevar a una mayor ganancia de grasa. Intenta mantenerte dentro de tu rango objetivo.'
      }
    },
    [nutrientTypes.MACROS]: {
      [macroNutrients.PROTEINS]: {
        [adaptationLevels.VL]: {
          title: 'Muy bajo',
          description: 'Intenta aumentar tu ingesta mañana para cuidar tu musculatura.'
        },
        [adaptationLevels.L]: {
          title: 'Un poco bajo',
          description: 'Estás cerca, pero necesitas un poco más de proteínas para optimizar la ganancia muscular.'
        },
        [adaptationLevels.M]: {
          title: 'Lo lograste!',
          description: null,
        },
        [adaptationLevels.H]: {
          title: 'Te pasaste',
          description: 'Más proteínas no necesariamente significan más músculo, mantener el equilibrio es para optimizar tus resultados.'
        },
        [adaptationLevels.VH]: {
          title: 'Te pasaste',
          description: 'Más proteínas no necesariamente significan más músculo, mantener el equilibrio es para optimizar tus resultados.'
        }
      },
      [macroNutrients.CARBS]: {
        [adaptationLevels.VL]: {
          title: 'Muy bajo',
          description: 'Los carbohidratos son clave para la energía y recuperación muscular. Auméntalos mañana para apoyar tu rendimiento y regeneración.'
        },
        [adaptationLevels.L]: {
          title: 'Un poco bajo',
          description: 'Añade un poco más de carbohidratos para rendir mejor y optimizar la recuperación.'
        },
        [adaptationLevels.M]: {
          title: 'Lo lograste!',
          description: null,
        },
        [adaptationLevels.H]: {
          title: 'Te pasaste',
          description: 'Modera las porciones para no sobrepasar lo necesario.'
        },
        [adaptationLevels.VH]: {
          title: 'Muy alto',
          description: 'Ajusta las porciones para priorizar la ganancia de músculo y no de grasa.'
        }
      },
      [macroNutrients.FAT]: {
        [adaptationLevels.VL]: {
          title: 'Muy bajo',
          description: 'Añade más grasas saludables para apoyar tu función hormonal, esencial para ganar músculo.'
        },
        [adaptationLevels.L]: {
          title: 'Un poco bajo',
          description: 'Busca incluir más alimentos que contengan grasas saludables.'
        },
        [adaptationLevels.M]: {
          title: 'Lo lograste!',
          description: null,
        },
        [adaptationLevels.H]: {
          title: 'Te pasaste',
          description: 'Necesitas un pequeño ajuste en las porciones para mejorar.'
        },
        [adaptationLevels.VH]: {
          title: 'Muy alto',
          description: 'Para mañana, enfócate en porciones adecuadas y grasas saludables para mantener un balance ideal.'
        }
      }
    }
  },
  [objectives.WELLNESS]: {
    [nutrientTypes.CALORIES]: {
      [adaptationLevels.VL]: {
        title: 'Muy bajo',
        description: 'Asegúrate de consumir lo suficiente para obtener todos los nutrientes necesarios para tu salud.'
      },
      [adaptationLevels.L]: {
        title: 'Un poco bajo',
        description: 'Incrementa ligeramente tu ingesta para mantener tu energía y cubrir tus necesidades nutricionales.'
      },
      [adaptationLevels.M]: {
        title: 'Lo lograste!',
        description: null,
      },
      [adaptationLevels.H]: {
        title: 'Te pasaste',
        description: 'Ajusta un poco tu ingesta y elige alimentos nutritivos para maximizar los beneficios para tu bienestar.'
      },
      [adaptationLevels.VH]: {
        title: 'Muy alto',
        description: 'Reduce las calorías y prioriza alimentos nutritivos para mantener un equilibrio saludable y sostenible.'
      }
    },
    [nutrientTypes.MACROS]: {
      [macroNutrients.PROTEINS]: {
        [adaptationLevels.VL]: {
          title: 'Muy bajo',
          description: 'Aumenta tu ingesta de proteínas para apoyar la función muscular ayudando a mantenerte saludable.'
        },
        [adaptationLevels.L]: {
          title: 'Un poco bajo',
          description: 'Añade un poco más de proteínas para cubrir tus necesidades diarias y mantener el balance adecuado.'
        },
        [adaptationLevels.M]: {
          title: 'Lo lograste!',
          description: null,
        },
        [adaptationLevels.H]: {
          title: 'Te pasaste',
          description: 'Más proteínas no siempre es mejor. Mantén un equilibrio para asegurar que todas tus necesidades nutricionales estén cubiertas.'
        },
        [adaptationLevels.VH]: {
          title: 'Te pasaste',
          description: 'Más proteínas no siempre es mejor. Mantén un equilibrio para asegurar que todas tus necesidades nutricionales estén cubiertas.'
        }
      },
      [macroNutrients.CARBS]: {
        [adaptationLevels.VL]: {
          title: 'Muy bajo',
          description: 'Aumenta tu ingesta de carbohidratos para asegurar la energía necesaria que tu cuerpo y mente necesitan para funcionar.'
        },
        [adaptationLevels.L]: {
          title: 'Un poco bajo',
          description: 'Añade más carbohidratos de calidad para mantener tus niveles de energía estables.'
        },
        [adaptationLevels.M]: {
          title: 'Lo lograste!',
          description: null,
        },
        [adaptationLevels.H]: {
          title: 'Te pasaste',
          description: 'Reduce ligeramente los carbohidratos y enfócate en opciones de alta calidad para mantener un buen balance.'
        },
        [adaptationLevels.VH]: {
          title: 'Muy alto',
          description: 'Modera un poco el consumo de carbohidratos para mantener una nutrición equilibrada.'
        }
      },
      [macroNutrients.FAT]: {

        [adaptationLevels.VL]: {
          title: 'Muy bajo',
          description: 'Aumenta las grasas saludables para obtener sus vitaminas y mantener tu energía.'
        },
        [adaptationLevels.L]: {
          title: 'Un poco bajo',
          description: 'Añade un poco más de grasas de calidad para asegurar un buen balance en tu alimentación.'
        },
        [adaptationLevels.M]: {
          title: 'Lo lograste!',
          description: null,
        },
        [adaptationLevels.H]: {
          title: 'Te pasaste',
          description: 'Disminuye ligeramente los alimentos altos en lípidos para mantener un equilibrio adecuado.'
        },
        [adaptationLevels.VH]: {
          title: 'Muy alto',
          description: 'Para mañana, enfócate en porciones adecuadas y grasas saludables para mantener un bienestar óptimo.'
        }
      }
    }

  },
}

export const microNutrientsSources = [
  {
    name: 'vitaminA',
    label: 'Vitamina A',
    sources: ['Pescados grasos y mariscos', 'lácteos', 'hortalizas de color rojas, naranjas y moradas']
  },
  {
    name: 'vitaminC',
    label: 'Vitamina C',
    sources: ['Frutas cítricas, ácidas y semiácidas', 'verduras']
  },
  {
    name: 'folicAcid',
    label: 'Ácido fólico',
    sources: ['Hortalizas de hoja verde', 'frutas altas en grasas', 'frutas cítricas', 'pescados grasos y mariscos', 'harina de trigo y pan']
  },
  {
    name: 'vitaminB12',
    label: 'Vitamina B12',
    sources: ['Carnes', 'pescados y mariscos', 'huevos', 'lácteos y alimentos fortificados']
  },
  {
    name: 'vitaminB6',
    label: 'Vitamina B6',
    sources: ['Hortalizas ricas en almidón', 'frutas altas en grasas', 'pescados grasos y mariscos']
  },
  {
    name: 'copper',
    label: 'Cobre',
    sources: ['Mariscos con concha', 'frutos secos', 'semillas', 'chocolate y legumbres']
  },
  {
    name: 'selenium',
    label: 'Selenio',
    sources: ['Carbohidratos complejos', 'huevos', 'carnes rojas', 'pescados y mariscos']
  },
  {
    name: 'zinc',
    label: 'Zinc',
    sources: ['Carnes', 'pescados y mariscos', 'huevos', 'frutos secos', 'legumbres y semillas']
  },
  {
    name: 'calcium',
    label: 'Calcio',
    sources: ['Lácteos', 'alimentos fortificados con calcio', 'legumbres', 'hortalizas de hoja verde']
  },
  {
    name: 'sodium',
    label: 'Sodio',
    sources: ['Ultraprocesados altos en sodio', 'concentrados altos en sodio', 'embutidos y pan']
  },
  {
    name: 'potassium',
    label: 'Potasio',
    sources: ['Frutas y verduras', 'legumbres', 'carnes', 'pescados', 'lácteos y frutos secos']
  },
  {
    name: 'iron',
    label: 'Hierro',
    sources: ['Carnes', 'pescados y mariscos', 'legumbres', 'frutos secos', 'vegetales y alimentos']
  }
]

export const starsEvaluation = {
  [adaptationLevels.VL]: {
    title: 'Pocos alimentos recomendados - Aumentar',
    recommendation: 'Tu selección de alimentos no es la óptima. Intenta elegir más opciones de color verde o naranja para mejorar la calidad de tu alimentación!'
  },
  [adaptationLevels.L]: {
    title: 'Estás incorporando alimentos recomendados - Aún puedes mejorar',
    recommendation: 'Tu selección de alimentos podría mejorar. Intenta elegir más opciones de color verde para subir la calidad de tu alimentación.'
  },
  [adaptationLevels.M]: {
    title: 'Tu elección de alimentos es muy buena - Sigue así',
    recommendation: 'Estás incorporando más alimentos de mejor calidad. Mantener este enfoque hará una gran diferencia en tu salud!'
  },
  [adaptationLevels.H] : {
    title: 'Tu elección es excelente - Sigue así',
    recommendation: '¡Estás en el camino correcto para lograr grandes beneficios a largo plazo!'
  }
}

export const fiberAnalysis = {
  [adaptationLevels.VL]: {
    title: 'Intenta aumentar tu ingesta de fibra para apoyar tu salud intestinal.',
    icons: ['vegetable', 'grain', 'legume'],
    recommendation: ['Frutas y verduras', 'Granos y cereales enteros', 'Legumbres']
  },
  [adaptationLevels.M]: {
    title: '¡Alcanzaste tu objetivo!',
    recommendation: null,
    icons: null
  },
}

export const omega3Analysis = {
  [adaptationLevels.M]: {
    title: '¡Agregaste alimentos con Omega 3!',
    recommendation: null
  },
}