import { ReactNode } from "react";
import { useCurrentUser } from "@/components/AuthContext";
import { Navigate } from "react-router-dom";

type RequireAuthProps = {
  children: ReactNode
}

function ProtectedRoute({ children }: RequireAuthProps) {
  const user = useCurrentUser()

  if (user) {
    return <>{children}</>;
  }

  return <Navigate to='/login' />
};

export default ProtectedRoute;
