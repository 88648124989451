import { useMemo } from "react";

import Card from "react-bootstrap/esm/Card";
import BadgeCheck from "@/components/BadgeCheck/BadgeCheck";
import Badge from "react-bootstrap/esm/Badge";
import Stack from "react-bootstrap/esm/Stack";

import { fesFoodVarietyReportType, fesNutrientReportType } from "@/models/fes-model";

import { adaptationLevels, fiberAnalysis, omega3Analysis } from "../../constants";
import { StarFill } from "react-bootstrap-icons";

interface CriticalNutrientsProps {
  fiber?: fesNutrientReportType;
  omega3?: fesFoodVarietyReportType;
}

export default function CriticalNutrients(props: CriticalNutrientsProps) {
  const { fiber, omega3 } = props;

  const adaptationLevel = useMemo(() => {
    if (fiber && fiber.dailyLb) {
      return fiber.value < fiber.dailyLb ? adaptationLevels.VL : adaptationLevels.M;
    }
    return null;
  }, [fiber]);

  const omega3Obj = useMemo(() => {
    if (omega3 && omega3.count) {
      return 1 <= omega3.count ? adaptationLevels.M : null;
    }
    return null;
  }, [omega3]);
  return (
    fiber && adaptationLevel ?
      <Card className="mynu-shadow">
        <Card.Header>
          Nutrientes críticos
        </Card.Header>
        <Card.Body>
          <div className="flex-container flex-column">
            <BadgeCheck
              ok={fiber.value >= fiber.dailyLb}
              title={'Fibra'}
              description={fiberAnalysis[adaptationLevel].title}
              recommended={fiberAnalysis[adaptationLevel].recommendation}
              icons={fiberAnalysis[adaptationLevel].icons}
            />

            {omega3Obj ?
              <div className="font-avenir-bold my-3 p-4 w-100 d-flex flex-column align-items-center"
                style={{ borderRadius: '18px', border: '0.5px solid lightgray', backgroundColor: '#F7F3FF' }}
              >
                <Badge pill bg="primary" className="mb-3" style={{ fontSize: '1em' }}>
                  <Stack direction="horizontal" gap={2}>
                    <div>Bonus</div>
                    <StarFill size={20} className="mb-1" />
                  </Stack>
                </Badge>

                <BadgeCheck
                  title={omega3Analysis[adaptationLevels.M].title}
                  ok={true}
                  icons={null}
                />
              </div>
              : null
            }
          </div>
        </Card.Body>
      </Card>
      : null
  )
}