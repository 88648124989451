export const Categories = [
  {
    id: 'corbohydrates',
    description: 'Al consumir alimentos ricos en **carbohidratos o azúcares**, estos son transportados a la sangre para ser utilizados como **energía** por el cuerpo. La **dieta** es un factor clave para cuidar de tu salud; sin embargo, la **genética** también influye en este proceso.',
    subcategories: [
      {
        subcategory_name: "corbohydrates",
        subcategory_study_label: "1000g_mynu",
        subcategory_score: 12,
        description: "",
        prefer: ['Carbohidratos complejos 🫘🥕🫓', 'Verduras🥗🥙🌮', 'Frutas enteras 🫐🍑🍒', 'Grasas insaturadas 🥑🌻🫒', 'Canela🪵', 'Vinagre🫙'],
        reduce: ['Cereales refinados 🥣🍞🥐', 'Azúcar y productos dulces🍫🧃🍭', 'Jugos de fruta 🧃', 'Ultraprocesados altos en azúcar🥤🍫🍦', 'Alcohol 🥃🍷🍺'],
        avoid: ['Cereales refinados 🥣🍞🥐', 'Azúcar y productos dulces🍫🧃🍭', 'Jugos de fruta 🧃', 'Ultraprocesados altos en azúcar🥤🍫🍦', 'Alcohol 🥃🍷🍺'],
        prevalence_risk: 'El 12% de la población en Chile presenta diabetes tipo 2.',
        low: {
          asociation: "Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de presentar problemas asociados al metabolismo y asimilación de carbohidratos en comparación con la población general. Tu genética no afecta de gran forma la probabilidad de desarrollar resistencia a la insulina y diabetes tipo 2.",
          remember: 'El nivel de azúcar en sangre es como revisar la presión de los neumáticos: mantener una buena alimentación ayuda a controlar tu azúcar para asegurar un viaje sin preocupaciones.',
          advice: 'Esto significa que puedes disfrutar de alimentos con carbohidratos y un consumo moderado de carbohidratos refinados🥣🍞🥐 y azúcares🧃🍭, siempre y cuando sigas un estilo de vida saludable y busques mantenerte activo.',
        },
        medium: {
          asociation: "Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de presentar problemas asociados al metabolismo y asimilación de carbohidratos en comparación con la población general. Esto aumenta el riesgo de desarrollar resistencia a la insulina y diabetes tipo 2.",
          remember: "El azúcar en sangre es nuestra principal fuente de energía, mantenerla en equlibrio con ejercicio y chequeos regulares es como preocuparse de la bencina que usa el motor de tu auto.",
          advice: ['prefer', 'reduce']
        },
        high: {
          asociation: "Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de presentar problemas asociados al metabolismo y asimilación de carbohidratos en comparación con la población general. Esto aumenta el riesgo de desarrollar resistencia a la insulina y diabetes tipo 2.",
          remember: "Ignorar los niveles altos de azúcar en sangre es como no atender un ruido extraño en tu auto. Si sabes que la tienes alta o notas síntomas como fatiga o sed en exceso, deberías consultar a un médico antes de que el 'motor' tenga un problema mayor.",
          advice: ['prefer', 'avoid']
        },
        genes: ['TCF7L2', 'CDKN2A/2B', 'IGF2BP2', 'MTNR1B', 'SLC30A8', 'ADCY5', 'WFS1', 'CDC123-CAMK1D', 'KCNQ1', 'LINC00523', 'FTO', 'RNPC3-DT']
      }
    ]
  },
  {
    id: 'allergies',
    description: 'Los resultados pueden revelar **cómo reaccionas** a ciertos compuestos. Muestran **predisposiciones** genéticas y **no diagnósticos** específicos de intolerancias o alergias. Ante riesgos elevados, es importante **observar** las reacciones y síntomas frente a alérgenos.',
    subcategories: [
      // Susceptibilidad a Alergias
      {
        subcategory_name: "allergies_general",
        subcategory_study_label: "1000g_mynu",
        subcategory_score: 4,
        description: 'Tu genética puede alterar el mecanismo por el cual se desencadena una alergia, cuando esta es provocada por una sustancia llamada inmunoglobulina E.',
        prefer: ['Hortalizas rojas-naranjas-moradas 🥕🍅🫑', 'Alimentos altos en kaempferol🥕🥬☕', 'Alimentos con quercetina🧅🥬🍒', 'Ralladura de cítricos🍋🍊', 'Alimentos fermentados 🧅🥛🫚', 'Cúrcuma y pimienta 🧂🫚', 'Alimentos ricos en vitamina D🐟🥛🍞', 'Legumbres 🫘🫛🍲', 'Hierbas y Té🍵🌿', 'Vinagre🫙', 'Aceite de oliva o canola 🫒', 'Alimentos ricos en Omega-3 🥜🐠🦐'],
        reduce: ['Aceite de palma y aceite de coco 🥥🌴', 'Azúcar y productos dulces🍫🧃🍭', 'Alcohol 🥃🍷🍺',],
        avoid: ['Aceite de palma y aceite de coco 🥥🌴', 'Azúcar y productos dulces🍫🧃🍭', 'Alcohol 🥃🍷🍺',],
        prevalence_risk: "Las alergias alimentarias afectan a entre el 1% y el 10% de la población mundial. Aproximadamente, entre el 6% y el 8% de los niños menores de 3 años y entre el 2% y el 4% de los adultos presentan algún tipo de alergia alimentaria.",
        low: {
          asociation: "Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de que hayas desarrollado una reacción alérgica en la infancia o en la edad adulta, en comparación con la población general. No deberías generar una reacción frente a sustancias alérgicas como el polen, la soya, dermatitis, etc.",
          remember: 'Recuerda que las alergias tienen reacciones diversas en cada persona. El ejercicio y el manejo del estrés contribuyen a fortalecer tu sistema inmune.',
          advice: 'Mantén una dieta balanceada con frutas🍇, verduras🥙 y grasas saludables🫒🥑 para mantener tu sistema inmune fortalecido.',
        },
        medium: {
          asociation: "Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de que hayas desarrollado una reacción alérgica en la infancia o en la edad adulta, en comparación con la población general. Podrías desarrollar una alergia a distintos elementos como el polen, la soya, dermatitis, etc.",
          remember: 'Recuerda que las alergias tienen reacciones diversas en cada persona. Una alimentación adecuada y estilo de vida saludable ayuda a reforzar tu sistema inmune.',
          advice: ['prefer', 'reduce']
        },
        high: {
          asociation: "Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de que hayas desarrollado una reacción alérgica en la infancia o en la edad adulta, en comparación con la población general. Podrías desarrollar una alergia a distintos elementos como el polen, la soya, dermatitis, etc.",
          remember: 'Recuerda que las alergias tienen reacciones diversas en cada persona. Una alimentación adecuada y estilo de vida saludable ayuda a reforzar tu sistema inmune.',
          advice: ['prefer', 'avoid']
        },
        genes: ['STAT6']
      },
      // Alergia al camarón
      {
        subcategory_name: "allergies_shrimp",
        subcategory_study_label: "1000g_mynu",
        subcategory_score: 4,
        description: 'Respuesta inmune a la proteína especifíca del camarón.',
        avoid: ['Camarón 🦐', 'Crustáceos 🦐🦞🦀'],
        prevalence_risk: 'Las alergias alimentarias afectan a entre el 1% y el 10% de la población mundial. La prevalencia de la alergia a los mariscos es casi inexistente en los niños y aumenta con la edad, alcanzando al 4% de la población mundial.',
        low: {
          asociation: 'Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de que hayas desarrollado una reacción alérgica en la infancia o en la edad adulta al camarón, en comparación con una persona geneticamente sana. Es poco probable que desarrolles esta alergia, ya que no tienes alteraciones genéticas en tu respuesta inmune al camarón.',
          remember: 'El camarón es alto en proteína y bajo en calorías es una buena opción para agregar variedad a tu plato, no lo elimines si no es necesario.',
          advice: 'Incluye distintas fuentes de proteínas animales 🐠🦐🍗🥩 y/o vegetales 🫘🫛🍲 en tu dieta.',
        },
        medium: {
          asociation: 'Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de que hayas desarrollado una reacción alérgica en la infancia o en la edad adulta al camarón, en comparación con una persona geneticamente sana.. Podrías desarrollar esta alergia en la edad adulta, debido a una respuesta inmune alterada frente al camarón.',
          remember: 'La alergia al camarón está asociada a la alergia de crustáceos en general. Evalúa los síntomas al consumir estos alimentos y toma precauciones de ser necesario.',
          advice: ['avoid']
        },
        high: {
          asociation: 'Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de que hayas desarrollado una reacción alérgica en la infancia o en la edad adulta al camarón, en comparación con una persona geneticamente sana.. Podrías desarrollar esta alergia en la edad adulta, debido a una respuesta inmune alterada frente al camarón.',
          remember: 'La alergia al camarón está asociada a la alergia de crustáceos en general. Evalúa los síntomas al consumir estos alimentos y toma precauciones de ser necesario.',
          advice: ['avoid']
        },
        genes: ['Región HLA clase II']
      },
      // Alergia al maní
      {
        subcategory_name: "allergies_peanut",
        subcategory_study_label: "mynu",
        subcategory_score: 2,
        description: 'Respuesta inmune a la proteína especifíca del maní.',
        avoid: ['Maní 🥜', 'Frutos secos 🥜🌰🫘'],
        prevalence_risk: 'Las alergias alimentarias afectan a entre el 1% y el 10% de la población mundial. La alergia al maní se presenta en aproximadamente el 2% de la población infantil, siendo una de las más comunes en niños.',
        low: {
          asociation: 'Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de que hayas desarrollado una reacción alérgica en la infancia o en la edad adulta al maní, en comparación con la población general. Es poco probable que desarrolles esta alergia, ya que no tienes alteraciones genéticas en tu respuesta inmune al maní.',
          remember: 'El maní, aunque no lo creas, es una legumbre y permite incorporar sabores y texturas a las comidas; no lo elimines si no es necesario.',
          advice: 'Incluye una variada selección de frutos secos🥜🌰🫘 y legumbres🫘🫛🍲 en tu dieta.',
        },
        medium: {
          asociation: 'Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de que hayas desarrollado una reacción alérgica en la infancia o en la edad adulta al maní, en comparación con la población general. Podrías desarrollar esta alergia en la edad adulta, debido a una respuesta inmune alterada frente al maní.',
          remember: 'Si presentas alergia al maní, tienes mayor riesgo a presentar alergia a otras legumbres y frutos secos. Evalúa síntomas tras consumirlos y toma precauciones.',
          advice: ['avoid']
        },
        high: {
          asociation: 'Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de que hayas desarrollado una reacción alérgica en la infancia o en la edad adulta al maní, en comparación con la población general. Podrías desarrollar esta alergia en la edad adulta, debido a una respuesta inmune alterada frente al maní.',
          remember: 'Si presentas alergia al maní, tienes mayor riesgo a presentar alergia a otras legumbres y frutos secos. Evalúa síntomas tras consumirlos y toma precauciones.',
          advice: ['avoid']
        },
        genes: ['HLA-DQB1']
      },
      // Intolerancia a la lactosa
      {
        subcategory_name: "allergies_lactose",
        description: 'Posibilidad de digerir la lactosa en la adultez.',
        prefer: ['Lácteos sin lactosa 🥛🧀', 'Lácteos con bajo contenido de lactosa 🥛🧀', 'Alimentos fermentados 🧅🥛🫚'],
        reduce: ['Lácteos con lactosa🥛🧀', 'Postres de leche con lactosa🍮', 'Quesos untables, blancos y frescos🧀'],
        avoid: ['Lácteos con lactosa🥛🧀', 'Postres de leche con lactosa🍮', 'Quesos🧀'],
        prevalence_risk: 'Entre el 65 y 75% de la población presenta deficiencia de lactasa, de aquellos, sólo el 20% presenta molestias estomacales.',
        low: {
          asociation: 'Predisposición baja a ser intolerante a la lactosa. No deberías presentar molestias estomacales después de consumir lácteos.',
          remember: 'Si has sentido síntomas como hinchazón, gases y/o diarrea después de consumir lácteos, prueba los que no tienen lactosa. Asegúrate de tener una microbiota saludable, ya que esto podría eliminar o disminuir los síntomas.',
          advice: 'Los lácteos🥛🧀 aportan proteínas, vitaminas y minerales. Pioriza consumir los menos procesados como leche y yogurt natural sin endulzar.',
        },
        medium: {
          asociation: 'Predisposición media a ser intolerante a la lactosa. Podrías presentar molestias estomacales después de consumir lácteos.',
          remember: 'Recuerda que una microbiota saludable puede eliminar o disminuir los síntomas como hinchazón, gases, diarrea, dolor abdominal.',
          advice: ['prefer', 'reduce']
        },
        high: {
          asociation: 'Predisposición alta a ser intolerante a la lactosa. Podrías presentar molestias estomacales después de consumir lácteos.',
          remember: 'Recuerda que una microbiota saludable puede eliminar o disminuir los síntomas como hinchazón, gases, diarrea, dolor abdominal.',
          advice: ['prefer', 'avoid']
        },
        genes: ['MCM6']
      },
      // Enfermedad Celiaca
      {
        subcategory_name: "allergies_celiac",
        subcategory_study_label: "1000g_mynu",
        subcategory_score: 1,
        description: 'Respuesta inmune a la proteína llamada gluten, que se encuentra en alimentos como trigo, avena, centeno, cebada y sus derivados.',
        prefer: ['Harinas y cereales sin gluten 🫓🌽🍚', 'Alimentos procesados sin gluten 🫓🍞🥫'],
        avoid: ['Trigo y derivados 🥖🍞🌾', 'Avena no certificada sin gluten 🥣', 'Centeno y derivados 🫓', 'Cebada y derivados 🍺', 'Productos a granel 🥜🌰🫘'],
        prevalence_risk: 'El 1% de la población presenta enfermedad celíaca. Sin embargo, la sensibilidad al gluten es cada vez más común, llegando a ser hasta 10 veces más frecuente que la enfermedad celíaca.',
        low: {
          asociation: 'Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de que tu cuerpo reconozca el gluten como una amenaza, en comparación con la población general. Por lo tanto, presentas una tendencia a una tolerancia normal al gluten, por lo que no deberías tener una alteración en tu respuesta inmune.',
          remember: 'No le tengas miedo al gluten, pero sí préfierelo de alimentos naturales como el pan casero y no de ultraprocesados.',
          advice: 'Puedes incluir alimentos con gluten como trigo 🌾, cebada, centeno 🫓 y avena 🥣 en tu día a día si no te generan síntomas.'
        },
        medium: {
          asociation: 'Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de que tu cuerpo reconozca el gluten como una amenaza, en comparación con la población general. Eres más propenso a generar molestias estomacales después de consumirlo, tales como dolor abdominal e hinchazón.',
          remember: 'Evalúa tus síntomas después de comer alimentos con gluten. En el caso de que te generen molestias, consulta a un profesional para guíar una dieta libre de gluten.',
          advice: ['prefer', 'avoid'],
        },
        high: {
          asociation: 'Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de que tu cuerpo reconozca el gluten como una amenaza, en comparación con la población general. Eres más propenso a generar molestias estomacales después de consumirlo, tales como dolor abdominal e hinchazón.',
          remember: 'Evalúa tus síntomas después de comer alimentos con gluten. En el caso de que te generen molestias, consulta a un profesional para guíar una dieta libre de gluten.',
          advice: ['prefer', 'avoid'],
        },
        genes: ['CCR3/CCR2', 'UQCRC2P1', 'KIAA1109', 'IL21-AS1', 'HLA-DQB1', 'HLA-DQA1', 'BACH2', 'TAGAP', 'RBFOX1']
      }
    ]
  },
  {
    id: 'body',
    description: 'Esta categoría incluye genes asociados al **peso** y factores que influyen en su regulación, tales como **señales hormonales**, **preferencias alimentarias**, etc. Controlar el peso implica múltiples **factores**, y tu **dieta** es factor determinante.',
    subcategories: [
      // Ganancia de Peso e IMC
      {
        subcategory_name: "body_weight",
        subcategory_study_label: "1000g_mynu",
        subcategory_score: 34,
        description: 'Considera genes que pueden alterar tus preferencias a ciertos alimentos, metabolismo de nutrientes y tu reloj biológico.',
        prefer: ['Alimentos no procesados 🍇🍗🥙', 'Frutos secos 🥜🌰🫘', 'Grasas insaturadas 🥑🌻🫒', 'Alimentos ricos en Omega-3 🥜🐠🦐', 'Legumbres 🫘🫛🍲', 'Frutas y verduras 🥬🥕🍌'],
        reduce: ['Cereales refinados 🥣🍞🥐', 'Azúcar y productos dulces🍫🧃🍭', 'Embutidos 🌭🥓🍖', 'Carnes rojas altas en grasa 🥓🥩🍖', 'Ultraprocesados altos en calorías🍟🍫🧁'],
        avoid: ['Cereales refinados 🥣🍞🥐', 'Azúcar y productos dulces🍫🧃🍭', 'Embutidos 🌭🥓🍖', 'Carnes rojas altas en grasa 🥓🥩🍖', 'Ultraprocesados altos en calorías🍟🍫🧁'],
        prevalence_risk: 'En Chile, el 74,2% de la población presenta sobrepeso. Aunque el sobrepeso es una condición multifactorial que considera tanto factores genéticos como de estilo de vida, los factores genéticos podrían influir hasta en un 56% en el peso de una persona.',
        low: {
          asociation: 'Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de presentar variaciones en el peso corporal, en comparación con la población general. Tienes una tendencia genética a mantener el peso, metabolizando de buena forma los macronutrientes, junto con mantener normales las señales de apetito y saciedad.',
          remember: 'No olvides que estos mecanismos no solo se regulan por la genética, un estilo de vida saludable es clave para mantenerlos en perfecto estado.',
          advice: 'Puedes ser más flexible con tu alimentación sin generar fluctuaciones de peso, pero puede ser una arma de doble filo si quieres aumentar de peso. Esto no quiere decir que debes descuidar tu alimentación. Prioriza los alimentos naturales 🥔🍅 y evita el exceso de azúcares 🍭🍰🍪 y ultraprocesados 🍟🥤🍫 para cuidar tu salud.'
        },
        medium: {
          asociation: 'Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de presentar variaciones en el peso corporal, en comparación con la población general. Tienes una tendencia genética a aumentar de peso, experimentar un mayor apetito y menor sensación de saciedad, prefiriendo además alimentos más calóricos y ricos en grasas.',
          remember: 'Para mantener un peso saludable elige alimentos variados para que te ayuden con la saciedad y haz algún tipo de actividad física que disfrutes en tu rutina.',
          advice: ['prefer', 'reduce'],
        },
        high: {
          asociation: 'Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de presentar variaciones en el peso corporal, en comparación con la población general. Tienes una tendencia genética a aumentar de peso, experimentar un mayor apetito y menor sensación de saciedad, prefiriendo además alimentos más calóricos y ricos en grasas.',
          remember: 'Para promover tu bienestar, combina una alimentación completa y variada para que te sientas saciedado a lo largo del día, y mantén un estilo de vida activo, evitando el quedarse quieto.',
          advice: ['prefer', 'avoid'],
        },
        genes: ['BDNF', 'FTO', 'MC4R', 'ADIPOQ', 'CLOCK', 'PPARA']
      },
      // Masa Corporal Magra
      {
        subcategory_name: "body_lean",
        description: 'Se refiere al peso de músculo, huesos y órganos, excluyendo la grasa corporal. Se utiliza como indicador de cantidad de músculo en tu cuerpo.',
        prefer: ['Carnes blancas, magras, pescados y mariscos 🍗🐟🦞', 'Lácteos con lactosa🥛🧀', 'Lácteos sin lactosa🥛🧀', 'Alimentos ricos en Omega-3 🥜🐠🦐', 'Legumbres 🫘🫛🍲', 'Alimentos ricos en vitamina D🐟🥛🍞'],
        avoid: ['Embutidos 🌭🥓🍖', 'Carnes rojas altas en grasa 🥓🥩🍖', 'Alcohol 🥃🍷🍺'],
        prevalence_risk: 'El 32% de la población tiene una ventaja genética para aumentar masa muscular más fácilmente ante estímulos de ejercicio y dieta.',
        low: {
          asociation: 'Predisposición alta a tener más músculo, tienes una ventaja genética para desarrollar mayor masa muscular.',
          remember: 'El ejercicio, sobretodo de fuerza, es esencial para mantener tu musculatura, y así, prevenir enfermedades en la edad mayor.',
          advice: 'Busca una adecuada relación de proteínas animales 🍗🐟 y/o vegetales 🫘🫛 en tu dieta para mantener niveles adecuados de masa muscular 💪.'
        },
        medium: {
          asociation: 'Predispocisión a una cantidad de músculo normal, no tienes una ventaja genética para desarrollar mayor masa muscular.',
          remember: 'El ejercicio, sobretodo de fuerza, es esencial para mantener tu musculatura, y así, prevenir enfermedades en la edad mayor.',
          advice: ['prefer', 'avoid'],
        },
        high: {
          asociation: 'Predispocisión a una cantidad de músculo normal, no tienes una ventaja genética para desarrollar mayor masa muscular.',
          remember: 'El ejercicio, sobretodo de fuerza, es esencial para mantener tu musculatura, y así, prevenir enfermedades en la edad mayor.',
          advice: ['prefer', 'avoid'],
        },
        genes: ['TRHR']
      }
    ],
  },
  {
    id: 'cardiovascular',
    description: 'Vincula tu genética con alteraciones en los niveles de  **presión arterial**, **grasas en sangre**, **inflamación generalizada** y otras **enfermedades del corazón**. Esto también se ve fuertemente afectado por tu **dieta** y **estilo de vida**.',
    subcategories: [
      // Salud Cardiovascular General
      {
        subcategory_name: "cardiovascular_general",
        subcategory_study_label: "1000g_mynu",
        subcategory_score: 25,
        description: 'Considera predisposición a desarrollar complicaciones al corazón.',
        prefer: ['Aceite oliva o canola 🫒', 'Alimentos ricos en Omega-3 🥜🐠🦐', 'Frutos secos 🥜🌰🫘', 'Semillas 🌻', 'Legumbres 🫘🫛🍲'],
        reduce: ['Azúcar y productos dulces🍫🧃🍭', 'Carnes rojas altas en grasa 🥓🥩🍖', 'Concentrados altos en sodio 🥫🍜🧂', 'Aceites de maravilla, soya, maiz 🌻', 'Aceite de palma y aceite de coco 🥥🌴'],
        avoid: ['Azúcar y productos dulces🍫🧃🍭', 'Carnes rojas altas en grasa 🥓🥩🍖', 'Concentrados altos en sodio 🥫🍜🧂', 'Aceites de maravilla, soya, maiz 🌻', 'Aceite de palma y aceite de coco 🥥🌴'],
        prevalence_risk: 'El 25% de la población chilena presenta un alto riesgo cardiovascular.',
        low: {
          asociation: 'Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de desarrollar complicaciones al corazón, en comparación con la población general. Por tu genética, no deberías presentar alteraciones en la regulación de sustancias que podrían afectar la salud de tu corazón.',
          remember: 'Tu corazón late 100.000 veces por día; ayúdalo llevando un estilo de vida saludable y realiza chequeos regulares. Una dieta equilibrada, ejercicio regular y evitar el tabaquismo son los principales hábitos para mantener tu corazón saludable.',
          advice: 'Puedes ser más flexible con tu alimentación, sobre todo con los azúcares y grasas, pero esto no te asegura mantenerte sano. Prioriza pescados grasos 🐠, frutas 🫐, verduras 🥕 y lácteos con o sin lactosa 🥛🧀 de origen natural para mantener tu corazón saludable.'
        },
        medium: {
          asociation: 'Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de desarrollar complicaciones al corazón, en comparación con la población general. Por tu genética, se podría alterar la regulación de sustancias que podrían afectar la salud de tu corazón.',
          remember: 'Prioriza mantenerte activo y realiza chequeos habituales de tu salud cardiovascular con un profesional.',
          advice: ['prefer', 'reduce'],
        },
        high: {
          asociation: 'Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de desarrollar complicaciones al corazón, en comparación con la población general. Por tu genética, se podría alterar la regulación de sustancias que podrían afectar la salud de tu corazón.',
          remember: 'Prioriza mantenerte activo y realiza chequeos habituales de tu salud cardiovascular con un profesional.',
          advice: ['prefer', 'avoid'],
        },
        genes: ['APOA5', 'TGFB1', 'TGFB1', 'TNF-α', 'AGT', 'GABABR2']
      },
      // Colesterol
      {
        subcategory_name: "cardiovascular_colesterol",
        subcategory_study_label: "1000g_mynu",
        subcategory_score: 28,
        description: 'Regulación de la digestión, transporte, metabolización y acumulación de colesterol LDL (conocido como "malo") y HDL (conocido como "bueno"). El colesterol es uno de varios factores utilizados para estimar el riesgo cardiovascular.',
        prefer1: ['Alimentos ricos en Omega-3 🥜🐠🦐', 'Carbohidratos complejos 🫘🥕🫓', 'Frutas y verduras 🥬🥕🍌', 'Aceite oliva o canola 🫒'],
        prefer2: ['Alimentos ricos en Omega-3 🥜🐠🦐', 'Carbohidratos complejos 🫘🥕🫓', 'Frutas enteras 🫐🍑🍒', 'Aceite oliva o canola 🫒', 'Lácteos con fitoesteroles 🥛', 'Lácteos sin lactosa con fitoesteroles 🥛'],
        reduce: ['Carnes rojas altas en grasa 🥓🥩🍖', 'Embutidos 🌭🥓🍖', 'Tabaco 🚬'],
        avoid: ['Carnes rojas altas en grasa 🥓🥩🍖', 'Embutidos 🌭🥓🍖', 'Tabaco 🚬'],
        prevalence_risk: 'En Chile, el 28% de la población presenta alteraciones en los niveles de colesterol en sangre.',
        low: {
          asociation: 'Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de presentar alteraciones en los niveles de colesterol, en comparación con la población general. Tienes una tendencia genética a regular, de manera normal, los niveles de colesterol LDL (conocido como "malo") y/o HDL (conocido como el "bueno").',
          remember: 'El estrés crónico es perjudicial para el manejo del colesterol. Procura manejar tus niveles de estrés diarios y hacer ejercicio de forma regular.',
          advice: 'Tus niveles de colesterol no tienden a variar según tu alimentación, permitiéndote gozar de más alimentos 🥩🧈 sin preocuparte en exceso por las grasas. Sin embargo, busca que sean naturales y sin preservantes, evitando la comida rápida 🍟, ultraprocesados 🍪 y embutidos 🌭.'
        },
        medium: {
          asociation: 'Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de presentar alteraciones en los niveles de colesterol, en comparación con la población general. Tienes una tendencia genética a presentar cambios en los niveles de colesterol en la sangre. Estos cambios pueden causar estrechamiento de las arterias, lo cual es perjudicial para la salud.',
          remember: 'Controla tus niveles de colesterol con frecuencia y consulta tu médico en caso de alteraciones. Si tienes niveles elevados puedes incluir fitoesteroles en tu dieta, ya que ayudan a reducir tus niveles de colesterol. Buscalos en alimentos fortificados.',
          advice: ['prefer1', 'reduce'],
        },
        high: {
          asociation: 'Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de presentar alteraciones en los niveles de colesterol, en comparación con la población general. Tienes una tendencia genética a presentar cambios en los niveles de colesterol en la sangre. Estos cambios pueden causar estrechamiento de las arterias, lo cual es perjudicial para la salud.',
          remember: 'Controla tus niveles de colesterol con frecuencia y consulta tu médico en caso de alteraciones. Si tienes niveles elevados puedes incluir fitoesteroles en tu dieta, ya que ayudan a reducir tus niveles de colesterol. Buscalos en alimentos fortificados.',
          advice: ['prefer2', 'avoid'],
        },
        genes: ['LPL', 'MC4R', 'PCSK9', 'APOA2', 'LDLR', 'TM6SF2', 'FADS1']
      },
      // Triglicéridos
      {
        subcategory_name: "cardiovascular_triglicerides",
        subcategory_study_label: "1000g_mynu",
        subcategory_score: 36,
        description: 'Regulación de la digestión, transporte, metabolismo y acumulación de triglicéridos. Los niveles de triglicéridos son uno de varios factores utilizados para estimar el riesgo cardiovascular.',
        prefer: ['Verduras🥗🥙🌮', 'Alimentos ricos en Omega-3 🥜🐠🦐', 'Carbohidratos complejos 🫘🥕🫓', 'Grasas insaturadas 🥑🌻🫒'],
        reduce: ['Azúcar y productos dulces🍫🧃🍭', 'Alcohol 🥃🍷🍺', 'Ultraprocesados altos en azúcar🥤🍫🍦'],
        avoid: ['Azúcar y productos dulces🍫🧃🍭', 'Alcohol 🥃🍷🍺', 'Ultraprocesados altos en azúcar🥤🍫🍦'],
        prevalence_risk: 'El 36% de la población chilena presenta alteraciones en los niveles de triglicéridos en sangre.',
        low: {
          asociation: 'Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de presentar alteraciones en los niveles de triglicéridos, en comparación con la población general. Tu genética tiende a una regulación habitual de triglicéridos en sangre.',
          remember: 'Una dieta baja en azúcares y grasas saturadas, ejercicio regular y moderación en el consumo de alcohol son clave para controlar los triglicéridos en sangre.',
          advice: 'Puedes disfrutar de una mayor variedad de alimentos, pero nunca olvides apuntar hacia una alimentación natural 🥕🥩.'
        },
        medium: {
          asociation: 'Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de presentar alteraciones en los niveles de triglicéridos, en comparación con la población general. Podrías presentar niveles altos de triglicéridos en la sangre. El exceso de triglicéridos puede acumularse en el hígado y en las arterias, lo que es perjudicial para la salud.',
          remember: 'Para mantener los niveles de triglicéridos disminuye los azúcares, aumenta tu consumo de omega 3 y realiza ejercicio de forma regular. No olvides chequear tus niveles con frecuencia y consulta tu médico en caso de alteraciones.',
          advice: ['prefer', 'reduce'],
        },
        high: {
          asociation: 'Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de presentar alteraciones en los niveles de triglicéridos, en comparación con la población general. Podrías presentar niveles altos de triglicéridos en la sangre. El exceso de triglicéridos puede acumularse en el hígado y en las arterias, lo que es perjudicial para la salud.',
          remember: 'Para mantener los niveles de triglicéridos disminuye los azúcares, aumenta tu consumo de omega 3 y realiza ejercicio de forma regular. No olvides chequear tus niveles con frecuencia y consulta tu médico en caso de alteraciones.',
          advice: ['prefer', 'avoid'],
        },
        genes: ['APOA5', 'MLXIPL', 'PNPLA3', 'SLC2A2', 'PEMT', 'FGF21']
      },
      // Presión Arterial
      {
        subcategory_name: "cardiovascular_bloodpressure",
        subcategory_study_label: "mynu",
        subcategory_score: 28,
        description: 'Relación de los sistemas encargados del equilibrio de la presión arterial y el volumen de sangre total. También considera genes asociados al consumo de sal, que en exceso, puede aumentar tu presión arterial.',
        prefer: ['Alimentos no procesados 🍇🍗🥙', 'Aceite oliva o canola 🫒', 'Alimentos ricos en Omega-3 🥜🐠🦐', 'Alimentos altos en antioxidantes 🍊🫐🥬'],
        reduce: ['Ultraprocesados altos en sodio🥫🍟🌭', 'Concentrados altos en sodio 🥫🍜🧂', 'Azúcar y productos dulces🍫🧃🍭', 'Embutidos 🌭🥓🍖'],
        avoid: ['Ultraprocesados altos en sodio🥫🍟🌭', 'Concentrados altos en sodio 🥫🍜🧂', 'Azúcar y productos dulces🍫🧃🍭', 'Embutidos 🌭🥓🍖'],
        prevalence_risk: 'El 28% de la población en Chile presenta alteraciones en la presión arterial.',
        low: {
          asociation: 'Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de presentar alteraciones en la regulación de la presión arterial, en comparación con la población general. Tienes una tendencia genética a mantener normales tus niveles de presión arterial.',
          remember: 'Para mantener tus presiones normales, realiza ejercicio de forma constante y presta atención a tu consumo de sal y alimentos procesados.',
          advice: 'Si quieres no estar tan preocupado de la 🧂 sal que agregas a tus comidas, incluye 🍏 frutas y 🥦 verduras a diario.'
        },
        medium: {
          asociation: 'Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de presentar alteraciones en la regulación de la presión arterial, en comparación con la población general. Tienes una tendencia genética a presentar alteraciones en el control del volumen sanguíneo y/o presentar un elevado consumo de sal.',
          remember: 'Mantén o disminuye el consumo de sal y aumenta el uso de especias y condimentos alternativos. Es vital monitorear tu presión arterial, especialmente en el embarazo o si planeas estarlo.',
          advice: ['prefer', 'reduce'],
        },
        high: {
          asociation: 'Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de presentar alteraciones en la regulación de la presión arterial, en comparación con la población general. Tienes una tendencia genética a presentar alteraciones en el control del volumen sanguíneo y/o presentar un elevado consumo de sal.',
          remember: 'Saca el salero de la mesa, prueba la comida antes de añadirle sal y reduce la cantidad de sal en la cocina. Te recomendamos lavar los alimentos en conserva antes de consumirlos. Prueba condimentos y especias para disminuir el consumo de sal. Monitorea tu presión arterial y consulta a un médico en caso de alteraciones.',
          advice: ['prefer', 'avoid'],
        },
        genes: ['NPAS2', 'SLC15A2', 'FRYL', 'CREB3L2', 'ZFHX4', 'BICC1', 'CCDC6', 'UBE4A', 'COL4A1', 'ERG', 'TAS2R38', 'AGT', 'ACE']
      },

    ]
  },
  {
    id: 'vitamins',
    description: 'Tu genética puede alterar los niveles de **vitaminas en la sangre**, que son clave para el adecuado **funcionamiento del cuerpo**. Prioriza consumirlas a través de los **alimentos** para garantizar una dosis y absorción adecuadas.',
    subcategories: [
      // Vitamina A
      {
        subcategory_name: "vitamins_a",
        subcategory_study_label: "1000g_mynu",
        subcategory_score: 9,
        description: 'El betacaroteno es una molécula que protege de enfermedades y previene el daño celular al actuar como antioxidante. Está presente en alimentos vegetales y se convierte en vitamina A en el organismo para mantener niveles normales y cumplir sus funciones. La vitamina A es vital para la salud ocular, la inmunidad y la piel. Este gen regula la velocidad de conversión del betacaroteno a vitamina A, afectando los niveles de ambos compuestos.',
        prefer1: ['Hortalizas rojas-naranjas-moradas 🥕🍅🫑', 'Alimentos altos en antioxidantes 🍊🫐🥬'],
        prefer2: ['Pescados grasos y mariscos 🐠🦐🦪', 'Lácteos con lactosa🥛🧀', 'Lácteos sin lactosa🥛🧀', 'Hortalizas rojas-naranjas-moradas 🥕🍅🫑'],
        prefer3: ['Pescados grasos y mariscos 🐠🦐🦪', 'Lácteos con lactosa🥛🧀', 'Lácteos sin lactosa🥛🧀'],
        prevalence_risk: 'Aproximadamente el 9% de la población presenta deficiencia de vitamina A.',
        low: {
          asociation: 'Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de presentar alteraciones en los niveles de vitamina A y betacarotenos, en comparación con la población general. Tienes una tendencia a convertir betacaroteno en vitamina A de forma normal. Esto ayuda a mantener niveles adecuados tanto de betacaroteno como de vitamina A en el organismo, aprovechando las funciones beneficiosas de ambas moléculas.',
          remember: 'La vitamina A es vital para la salud ocular, la inmunidad y la piel. Gracias a que no tienes tendencia a tenerla disminuida, podrías enfocarte más en comer alimentos con antioxidantes.',
          advice: ['prefer1']
        },
        medium: {
          asociation: 'Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de presentar alteraciones en los niveles de vitamina A y betacarotenos, en comparación con la población general. Tienes una tendencia a convertir betacaroteno en vitamina A más lentamente. Esto puede aumentar el riesgo de una deficiencia de esta vitamina y resultar en mayores niveles de betacaroteno, potenciando su efecto antioxidante.',
          remember: 'La vitamina A es vital para la salud ocular, la inmunidad y la piel, pero en exceso podría ser tóxica. Cuidado con los suplementos, si quieres tomarlos consulta tu dosis con un profesional.',
          advice: ['prefer2']
        },
        high: {
          asociation: 'Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de presentar alteraciones en los niveles de vitamina A y betacarotenos, en comparación con la población general. Tienes una tendencia a convertir betacaroteno en vitamina A más lentamente. Esto puede aumentar el riesgo de una deficiencia de esta vitamina y resultar en mayores niveles de betacaroteno, potenciando su efecto antioxidante.',
          remember: 'La vitamina A es vital para la salud ocular, la inmunidad y la piel, pero en exceso podría ser tóxica. Cuidado con los suplementos, si quieres tomarlos consulta tu dosis con un profesional.',
          advice: ['prefer3']
        },
        genes: ['BCMO1']
      },
      // Vitamina C
      {
        subcategory_name: "vitamins_c",
        subcategory_study_label: "1000g_mynu",
        subcategory_score: 12,
        description: 'La vitamina C ayuda en el crecimiento y reparación de tejidos, a prevenir enfermedades crónicas, etc. Variantes genéticas en este gen se relaciona con un transporte inadecuado de la vitamina por el cuerpo, y por ende, no se utiliza de forma eficiente.',
        prefer: ['Frutas cítricas 🍊🫑🥝', 'Frutas ácidas y semiácidas 🍏🥝🍍', 'Verduras🥗🥙🌮'],
        prevalence_risk: 'Aproximadamente el 12% de la población presenta deficiencia de vitamina C.',
        low: {
          asociation: 'Tienes un transporte y utilización normal de vitamina C.',
          remember: 'Si combinas alimentos ricos en vitamina C, como naranja, pimentón y kiwi, con legumbres y vegetales, ayudas a tu cuerpo a absorber mejor el hierro de estos alimentos.',
          advice: 'Incluye 5 porciones de frutas🍊🍏 y verduras🫑🥕 al dia para asegurar el consumo de vitamina C.'
        },
        medium: {
          asociation: 'Debido a tu genética, tienes un riesgo alto a deficiencias de vitamina C, debido a un transporte y utilización deficiente de esta.',
          remember: 'La vitamina C es esencial para fortalecer el sistema inmunológico, también es una vitamina antioxidante y participa en la formación de colágeno en la piel. Preocúpate de ingerirla mediante alimentos, ya que nosotros no la sintetizamos.',
          advice: ['prefer'],
        },
        high: {
          asociation: 'Debido a tu genética, tienes un riesgo alto a deficiencias de vitamina C, debido a un transporte y utilización deficiente de esta.',
          remember: 'La vitamina C es esencial para fortalecer el sistema inmunológico, también es una vitamina antioxidante y participa en la formación de colágeno en la piel. Preocúpate de ingerirla mediante alimentos, ya que nosotros no la sintetizamos.',
          advice: ['prefer'],
        },
        genes: ['SLC23A1']
      },
      // Acído Fólico
      {
        subcategory_name: "vitamins_folicacid",
        subcategory_study_label: "1000g_mynu",
        subcategory_score: 0.6,
        description: 'Los folatos son clave para la formación y reparación de tejidos, por lo que tienen un rol en el crecimiento, función cerebral y la salud en general. Variantes genéticas pueden influir en el metabolismo de esta vitamina, alterando sus niveles en sangre.',
        prefer: ['Hortalizas de hoja verde 🥬🍃🥗', 'Pescados grasos y mariscos 🐠🦐🦪', 'Frutas altas en grasa 🥜🥑🫒', 'Frutas cítricas 🍊🫑🥝', 'Harina de trigo / pan 🥖🍞🌾'],
        prevalence_risk: 'Aproximadamente el 0.6% de la población presenta deficiencias de folatos..',
        low: {
          asociation: 'Tienes una predisposición genética **<REPLACE_NUMBER_PREDISPOSITION>** veces mayor de presentar deficiencias en tus niveles de folatos, en comparación con la población general. Tienes una tendencia genética a mantener normales los niveles de folatos en tu cuerpo.',
          remember: 'El ácido fólico es especialmente esencial en el embarazo. Si estás embarazada o planeas estarlo, consulta a tu médico sobre la suplementación adecuada.',
          advice: 'Aprovecha fuentes naturales de folatos como verduras de hoja verde🥬, frutos secos🥜 y alimentos fortificados con ácido fólico (como por ejemplo, la harina🌾) para evitar síntomas como cansancio, falta de concentración, irritabilidad e incluso anemia.'
        },
        medium: {
          asociation: 'Tienes una predisposición genética **<REPLACE_NUMBER_PREDISPOSITION>** veces mayor de presentar deficiencias en tus niveles de folatos, en comparación con la población general. Tienes una tendencia genética a presentar deficiencias en los niveles de folatos en tu cuerpo debido a una activación ineficaz en el cuerpo. Esto podría causar síntomas como cansancio, falta de concentración, irritabilidad y, en algunos casos, anemia.',
          remember: 'El ácido fólico es especialmente esencial en el embarazo. Si estás embarazada o planeas estarlo, consulta a tu médico sobre la suplementación adecuada.',
          advice: ['prefer'],
        },
        high: {
          asociation: 'Tienes una predisposición genética **<REPLACE_NUMBER_PREDISPOSITION>** veces mayor de presentar deficiencias en tus niveles de folatos, en comparación con la población general. Tienes una tendencia genética a presentar deficiencias en los niveles de folatos en tu cuerpo debido a una activación ineficaz en el cuerpo. Esto podría causar síntomas como cansancio, falta de concentración, irritabilidad y, en algunos casos, anemia.',
          remember: 'El ácido fólico es especialmente esencial en el embarazo. Si estás embarazada o planeas estarlo, consulta a tu médico sobre la suplementación adecuada.',
          advice: ['prefer'],
        },
        genes: ['MTHFR', 'PRICKL2']
      },
      // Vitamina D
      {
        subcategory_name: "vitamins_d",
        subcategory_study_label: "mynu",
        subcategory_score: 28,
        description: 'La vitamina D es esencial para mantener huesos fuertes, la función inmune y prevenir enfermedades en general. Incluso puede mejorar tu estado de ánimo y evitar la depresión.',
        prefer: ['Pescados grasos y mariscos 🐠🦐🦪', 'Huevos 🍳', 'Lácteos con lactosa🥛🧀', 'Lácteos sin lactosa🥛🧀', 'Harina de trigo / pan 🥖🍞🌾', 'Alimentos fortificados en vitamina D 🥛🥖🐟'],
        prevalence_risk: 'Aproximadamente el 28% de la población presenta deficiencias de vitamina D, ya que es difícil obtenerla a través de la alimentación.',
        low: {
          asociation: 'Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de presentar deficiencias en tus niveles de vitamina D, en comparación con la población general. Por esto, tiendes a un eficiente transporte y activación de vitamina D, riesgo bajo a presentar deficiencias.',
          remember: 'Si bien puedes encontrarla en alimentos, es muy difícil alcanzar tus requerimientos solo con la dieta. Chequea tus niveles circulantes de vitamina D en sangre para detectar deficiencias. Así lograrás mantener tus huesos sanos, un buen estado de ánimo y un sistema inmune adecuado.',
          advice: 'Aprovecha momentos de exposición al sol y consume alimentos ricos en vitamina D como pescados grasos🐠, huevo🍳, hongos shiitake🍄 y lácteos fortificados🥛.'
        },
        medium: {
          asociation: 'Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de presentar deficiencias en tus niveles de vitamina D, en comparación con la población general. Por esto, tiendes a presentar déficit de vitamina D, debido a una activación, transporte y almacenamiento alterados.',
          remember: 'Si bien puedes encontrarla en alimentos, es muy difícil alcanzar tus requerimientos solo con la dieta. Chequea tus niveles circulantes de vitamina D en sangre para detectar deficiencias. Así lograrás mantener tus huesos sanos, un buen estado de ánimo y un sistema inmune adecuado.',
          advice: ['prefer'],
        },
        high: {
          asociation: 'Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de presentar deficiencias en tus niveles de vitamina D, en comparación con la población general. Por esto, tiendes a presentar déficit de vitamina D, debido a una activación, transporte y almacenamiento alterados.',
          remember: 'Si bien puedes encontrarla en alimentos, es muy difícil alcanzar tus requerimientos solo con la dieta. Chequea tus niveles circulantes de vitamina D en sangre para detectar deficiencias. Así lograrás mantener tus huesos sanos, un buen estado de ánimo y un sistema inmune adecuado.',
          advice: ['prefer'],
        },
        genes: ['GC-globulin', 'GC', 'PSMA1', 'PDE3B', 'ACTE1P']
      },
      // Vitamina B12
      {
        subcategory_name: "vitamins_b12",
        subcategory_study_label: "1000g_mynu",
        subcategory_score: 9,
        description: 'La vitamina B12 es crucial para transportar oxígeno a tus tejidos, sentirte con energía y poder concentrarte. Variaciones genéticas pueden alterar la absorción de vitamina B12, disminuyendo sus niveles y afectando todas estas funciones.',
        prefer: ['Carnes blancas, magras, pescados y mariscos 🍗🐟🦞', 'Carnes rojas bajas en grasa 🥩🍖', 'Huevos 🍳', 'Lácteos con lactosa🥛🧀', 'Lácteos sin lactosa🥛🧀'],
        prevalence_risk: 'Aproximadamente el 9% de la población presenta deficiencias de vitamina B12.',
        low: {
          asociation: 'Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de presentar deficiencias en tus niveles de vitamina B12, en comparación con la población general. Tienes una tendencia genética a mantener normales los niveles de esta vitamina en tu cuerpo, lo cual evita deficiencias y previene síntomas asociados como fatiga, pérdida de memoria y palidez.',
          remember: 'La vitamina B12 se halla principalmente en alimentos de origen animal. Si llevas una dieta vegetariana o vegana, es vital monitorear tus niveles de B12 y consultar a un profesional en caso de dudas sobre deficiencia.',
          advice: 'Incluye proteínas animales como carnes🍗, pescados🐟, huevos🍳 y lácteos🥛🧀 de forma diaria en tu dieta. Si prefieres no consumirlos, procura alcanzar tus requerimientos.'
        },
        medium: {
          asociation: 'Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de presentar deficiencias en tus niveles de vitamina B12, en comparación con la población general. Tienes una tendencia genética a presentar menores niveles de esta vitamina en tu cuerpo, debido a una disminución en su absorción. Esto se puede asociar a síntomas como fatiga, pérdida de memoria y palidez.',
          remember: 'Si llevas una dieta vegetariana, vegana o consumes pocos productos animales, podrías tener déficit de vitamiana B12. Monitorea síntomas como fátiga, dificultad de concentración y palidez y consulta a un profesional si es necesario.',
          advice: ['prefer'],
        },
        high: {
          asociation: 'Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de presentar deficiencias en tus niveles de vitamina B12, en comparación con la población general. Tienes una tendencia genética a presentar menores niveles de esta vitamina en tu cuerpo, debido a una disminución en su absorción. Esto se puede asociar a síntomas como fatiga, pérdida de memoria y palidez.',
          remember: 'Si llevas una dieta vegetariana, vegana o consumes pocos productos animales, podrías tener déficit de vitamiana B12. Monitorea síntomas como fátiga, dificultad de concentración y palidez y consulta a un profesional si es necesario.',
          advice: ['prefer'],
        },
        genes: ['ALPL', 'CUBN', 'TCN1', 'FUT2']
      },
      // Vitamina B6
      {
        subcategory_name: "vitamins_b6",
        subcategory_study_label: "mynu",
        subcategory_score: 24,
        description: 'La vitamina B6 participa en la regulación del estado de ánimo y del sueño mediante ayudar a formar neurotransmisores y hormonas. La variante genética puede aumentar su degradación, disminuyendo sus niveles en sangre, y afectando en estos procesos.',
        prefer: ['Pescados grasos y mariscos 🐠🦐🦪', 'Frutas altas en grasa 🥜🥑🫒', 'Hortalizas ricas en almidón 🥔🌽🫛'],
        prevalence_risk: 'Aproximadamente el 24% de la población presenta deficiencia de vitamina B6.',
        low: {
          asociation: 'Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de descomponer la vitamina B6 más rápido de lo normal, en comparación con la población general. Por lo tanto, tiendes a tener niveles estables de vitamina B6 en sangre.',
          remember: 'Prefiere cocinar estos alimentos al horno o al sartén para preservar la vitamina B6, que al ser hidrosoluble, tiende a disolverse en el agua de cocción.',
          advice: 'Incluye proteínas magras🍗, pescados🐠, frutos secos🥜, papas🥔 y garbanzos🫘 en tu alimentación para asegurar niveles adecuados de vitamina B6.'
        },
        medium: {
          asociation: 'Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de descomponer la vitamina B6 más rápido de lo normal, en comparación con la población general. Por lo tanto, podrías tener un déficit de esta vitamina.',
          remember: 'La deficiencia de vitamina B6 puede generar perdidadas de cabello, problemas en la piel y hasta anemia. Chequea tus niveles en sangre y cosulta con un profesional.',
          advice: ['prefer'],
        },
        high: {
          asociation: 'Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de descomponer la vitamina B6 más rápido de lo normal, en comparación con la población general. Por lo tanto, podrías tener un déficit de esta vitamina.',
          remember: 'La deficiencia de vitamina B6 puede generar perdidadas de cabello, problemas en la piel y hasta anemia. Chequea tus niveles en sangre y cosulta con un profesional.',
          advice: ['prefer'],
        },
        genes: ['ALPL']
      },

    ]
  },
  {
    id: 'wellness',
    description: 'Existen genes que influyen en la **salud y bienestar**, afectando tu predisposición a **hábitos, salud digestiva y sistema inmunológico**. Conocerlos te ayudará a adoptar **dietas** y **estilos de vida** que potencien tu bienestar.',
    subcategories: [
      // Alcohol
      {
        subcategory_name: "wellness_alcohol",
        subcategory_study_label: "1000g_mynu",
        description: 'Analiza tu tolerancia al alcohol, debido a tu predisposición de sentir los efectos secudarios de su acumulación en el cuerpo.',
        low: {
          asociation: 'Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de sentir rápidamente los efectos del exceso de alcohol, en comparación con la población. Por ello, tiendes a una alta tolerancia al alcohol, retrasando la aparición de malestares por el consumo excesivo de alcohol, como enrojecimiento, náuseas, palpitaciones, etc.',
          remember: 'Recuerda que el consumo de alcohol es tóxico para tu cuerpo. Este afecta tu razonamiento y memoria, dificulta la quema de grasa corporal e incluso interrumpe el aumento de musculatura.',
          advice: 'Evita el exceso de alcohol 🥃🍷🍺, ya que, si bien puede que no sientas síntomas molestos, genera el mismo daño en tu organismo.'
        },
        medium: {
          asociation: 'Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de sentir rápidamente los efectos del exceso de alcohol, en comparación con la población. Algunos de estos son enrojecimiento, palpitaciones, náuseas, etc.',
          remember: 'Recuerda que el consumo de alcohol es tóxico para tu cuerpo. Este afecta tu razonamiento y memoria, dificulta la quema de grasa corporal e incluso interrumpe el aumento de musculatura.',
          advice: 'Evita el exceso de alcohol 🥃🍷🍺, ya que puede que sientas síntomas molestos de forma más rápida.',
        },
        high: {
          asociation: 'Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de sentir rápidamente los efectos del exceso de alcohol, en comparación con la población. Algunos de estos son enrojecimiento, palpitaciones, náuseas, etc.',
          remember: 'Recuerda que el consumo de alcohol es tóxico para tu cuerpo. Este afecta tu razonamiento y memoria, dificulta la quema de grasa corporal e incluso interrumpe el aumento de musculatura.',
          advice: 'Evita el exceso de alcohol 🥃🍷🍺, ya que puede que sientas síntomas molestos de forma más rápida.',
        },
        genes: ['ADH1B']
      },
      // Cobre
      {
        subcategory_name: "wellness_cupper",
        subcategory_study_label: "mynu",
        subcategory_score: 2,
        description: 'Niveles adecuados de cobre contribuyen a un adecuado funcionamiento de tu cuerpo. Niveles bajos de cobre pueden causar cansancio, aumentar el riesgo a infecciones, problemas al corazón y problemas neuronales.',
        prefer: ['Mariscos con concha🐚', 'Frutos secos 🥜🌰🫘', 'Semillas 🌻', 'Chocolate alto en cacao 🍫', 'Legumbres 🫘🫛🍲'],
        prevalence_risk: 'Aproximadamente el 2% de la población presenta deficiencias de cobre, ya que se encuentra disponible en la alimentación habitual.',
        low: {
          asociation: 'Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de presentar deficiencias en tus niveles de cobre, en comparación con la población general. Tienes una tendencia genética a mantener normales los niveles de este mineral en tu cuerpo.',
          remember: 'Recuerda que consumir un exceso de zinc en suplementos puede disminuir la absorción de cobre. Siempre consulta a un profesional en caso de necesitar suplementos.',
          advice: 'Mantén una dieta variada. Recuerda que el cobre que necesitas lo puedes encontrar en pescados 🐟🐠, mariscos 🦐, frutos secos 🥜🌰, semillas 🌻 y chocolate amargo 🍫.'
        },
        medium: {
          asociation: 'Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de presentar deficiencias en tus niveles de cobre, en comparación con la población general. Tienes una tendencia genética a presentar menores niveles de este mineral en tu cuerpo, debido a alteraciones en su metabolismo. Esto podría causar cansancio, aumentar el riesgo a infecciones, problemas al corazón y problemas neuronales.',
          remember: 'Recuerda que siempre será mejor obtener estos nutrientes a través de los alimentos. Si tienes dudas frente a tus niveles, consulta un profesional.',
          advice: ['prefer'],
        },
        high: {
          asociation: 'Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de presentar deficiencias en tus niveles de cobre, en comparación con la población general. Tienes una tendencia genética a presentar menores niveles de este mineral en tu cuerpo, debido a alteraciones en su metabolismo. Esto podría causar cansancio, aumentar el riesgo a infecciones, problemas al corazón y problemas neuronales.',
          remember: 'Recuerda que siempre será mejor obtener estos nutrientes a través de los alimentos. Si tienes dudas frente a tus niveles, consulta un profesional.',
          advice: ['prefer'],
        },
        genes: ['SMIM1', 'SELENBP1']
      },
      // Selenio 
      {
        subcategory_name: "wellness_selenium",
        subcategory_study_label: "mynu",
        subcategory_score: 1,
        description: 'El selenio es esencial para un adecuado funcionamiento de distintos procesos desde la reproducción hasta como antioxidante. Bajos niveles de selenio se asocian con alteraciones en tu salud del corazón y reproductiva, como por ejemplo, infertilidad.',
        prefer: ['Carbohidratos complejos 🫘🥕🫓', 'Carnes blancas, magras, pescados y mariscos 🍗🐟🦞', 'Huevos 🍳'],
        prevalence_risk: 'El 1% de la población presenta deficiencias de selenio, ya que es posible cubrir los requerimientos a través de la alimentación',
        low: {
          asociation: 'Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de presentar deficiencias en tus niveles de selenio, en comparación con la población general. Tienes una tendencia genética a mantener normales los niveles de selenio en tu cuerpo.',
          remember: 'El selenio ha mostrado ser una ayuda antioxidante. Si quieres potenciar esta función, preocupate de comer alimentos ricos en selenio.',
          advice: 'Para mantener niveles adecuados de selenio, lo encuentras en mariscos 🦞, carnes 🍗, granos enteros 🫘 y nueces de Brasil 🌰.'
        },
        medium: {
          asociation: 'Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de presentar deficiencias en tus niveles de selenio, en comparación con la población general. Tienes una tendencia genética a presentar menores niveles de este mineral en tu cuerpo, debido a alteraciones en su metabolismo, lo que conlleva mayores riesgos para tu salud reproductiva, el corazón e incluso aumenta la susceptibilidad a infecciones.',
          remember: 'El selenio ha demostrado ser un antioxidante útil, pero su exceso es tóxico. No te suplementes sin consultar con un profesional.',
          advice: ['prefer'],
        },
        high: {
          asociation: 'Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de presentar deficiencias en tus niveles de selenio, en comparación con la población general. Tienes una tendencia genética a presentar menores niveles de este mineral en tu cuerpo, debido a alteraciones en su metabolismo, lo que conlleva mayores riesgos para tu salud reproductiva, el corazón e incluso aumenta la susceptibilidad a infecciones.',
          remember: 'El selenio ha demostrado ser un antioxidante útil, pero su exceso es tóxico. No te suplementes sin consultar con un profesional.',
          advice: ['prefer'],
        },
        genes: ['DMGDH', 'BHMT']
      },
      // Tabaco
      {
        subcategory_name: "wellness_tobbaco",
        description: 'Este gen se relaciona con tu tendencia a ser fumador de tabaco.',
        prefer: ['Frutas cítricas 🍊🫑🥝', 'Alimentos altos en antioxidantes 🍊🫐🥬'],
        reduce: ['Tabaco 🚬'],
        avoid: ['Tabaco 🚬'],
        low: {
          asociation: 'Predisposición baja a ser fumador de tabaco.',
          remember: 'Fumar tabaco daña el corazón, los pulmones y aumenta el riesgo de cáncer. Si eres fumador, recuerda que nunca es tarde para dejarlo.',
          advice: 'Aunque genéticamente no tienes una preferencia al tabaco, busca evitarlo, ya que podrías desarrollar una dependencia. Si fumas tabaco, procura aumentar tu consumo de vitamina C🍊🫑🥝 y antioxidantes🍊🫐🥬.'
        },
        medium: {
          asociation: 'Predisposición moderada a ser fumador de tabaco.',
          remember: 'Fumar tabaco daña el corazón, los pulmones y aumenta el riesgo de cáncer. Si eres fumador, recuerda que nunca es tarde para dejarlo.',
          advice: ['prefer', 'reduce'],
        },
        high: {
          asociation: 'Predisposición alta a ser fumador de tabaco.',
          remember: 'Fumar tabaco daña el corazón, los pulmones y aumenta el riesgo de cáncer. Si eres fumador, recuerda que nunca es tarde para dejarlo.',
          advice: ['prefer', 'avoid'],
        },
        genes: ['TASR38']
      },
      // Zinc
      {
        subcategory_name: "wellness_zinc",
        subcategory_study_label: "mynu",
        subcategory_score: 4,
        description: 'El Zinc es esencial para mantenerte sano. Es clave para combatir infecciones, la cicatrización de heridas, el razonamiento y memoria. Niveles bajos en sangre incluso pueden afectar tu sentido del gusto y olfato.',
        prefer: ['Carnes blancas, magras, pescados y mariscos 🍗🐟🦞', 'Huevos 🍳', 'Frutos secos 🥜🌰🫘', 'Legumbres 🫘🫛🍲', 'Semillas 🌻'],
        prevalence_risk: 'El 4% de la población presenta deficiencias de zinc, y es más común en niños y personas mayores',
        low: {
          asociation: 'Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de presentar deficiencias en tus niveles de zinc, en comparación con la población general. Tienes una tendencia genética a mantener normales los niveles de este mineral en tu cuerpo.',
          remember: 'Incluir estas fuentes en tu dieta puede ayudar a asegurar una ingesta adecuada de zinc y potenciar tu salud.',
          advice: 'Para tener niveles normales, debes obtenerlo a través de la dieta. Búscalo en proteínas magras 🍗🐟🦞, frutos secos 🥜🌰🫘 y semillas 🌻.'
        },
        medium: {
          asociation: 'Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de presentar deficiencias en tus niveles de zinc, en comparación con la población general. Tienes una tendencia genética a presentar menores niveles de este mineral en tu cuerpo, debido a alteraciones en su metabolismo. Esto puede afectar tu cicatrización de heridas, tu capacidad de razonamiento, memoria y debilitar tu sistema inmune.',
          remember: 'Siempre prioriza los alimentos con zinc. No es necesario recurrir a suplementos sin supervisión de un profesional.',
          advice: ['prefer'],
        },
        high: {
          asociation: 'Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de presentar deficiencias en tus niveles de zinc, en comparación con la población general. Tienes una tendencia genética a presentar menores niveles de este mineral en tu cuerpo, debido a alteraciones en su metabolismo. Esto puede afectar tu cicatrización de heridas, tu capacidad de razonamiento, memoria y debilitar tu sistema inmune.',
          remember: 'Siempre prioriza los alimentos con zinc. No es necesario recurrir a suplementos sin supervisión de un profesional.',
          advice: ['prefer'],
        },
        genes: ['BHMT', 'PPCDC', 'NBDY']
      },
      // Enfermedad Inflamatoria Intestinal
      {
        subcategory_name: "wellness_ibd",
        subcategory_study_label: "mynu",
        subcategory_score: 18,
        description: 'Regulación del sistema inmune y de la respuesta inflamatoria asociada a la digestión, que, en conjunto con factores ambientales, pueden afectar tu barrera intestinal. Incluso puede desencadenar enfermedades como la de Crohn o colitis ulcerosa.',
        prefer: ['Legumbres 🫘🫛🍲', 'Proteínas vegetales🫘🫛', 'Alimentos fermentados 🧅🥛🫚', 'Lácteos con lactosa🥛🧀', 'Lácteos sin lactosa🥛🧀', 'Pescados y mariscos 🐟🐠🦐', 'Frutas y verduras 🥬🥕🍌', 'Pan de masa madre 🍞', 'Harinas y cereales sin gluten 🫓🌽🍚', 'Leche de almendras🥛', 'Aceite de oliva🫒'],
        reduce: ['Carnes rojas 🥓🥩🍖', 'Azúcar y productos dulces🍫🧃🍭', 'Productos de trigo procesados 🍞🍰🥐', 'Ultraprocesados 🍟🥤🍫'],
        avoid: ['Carnes rojas 🥓🥩🍖', 'Azúcar y productos dulces🍫🧃🍭', 'Productos de trigo procesados 🍞🍰🥐', 'Ultraprocesados 🍟🥤🍫'],
        prevalence_risk: 'Aproximadamente el 18% de la población presenta alteraciones en su salud intestinal, como el síndrome de intestino irritable.',
        low: {
          asociation: 'Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de problemas de digestión, como hinchazón o dolor abdominal, en comparación con la población. Tu genética tiende a una respuesta adecuada de tu sistema inmune.',
          remember: 'Factores como la alimentación saludable, el ejercicio y el manejo de estrés contribuyen a preveir malestares asociados a la digestión. Si tienes síntomas como hinchazón, estreñimiento, diarrea, acude a un profesional.',
          advice: 'Recuerda potenciar alimentos de origen vegetal como frutas🍏🍌🥝, verduras🥬🥕, legumbres🫘🫛🍲 y frutos secos🥜🌰🫘 para mantener tu salud intestinal.'
        },
        medium: {
          asociation: 'Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de problemas de digestión, como hinchazón o dolor abdominal, en comparación con la población. Esto se puede generar por una respuesta alterada del sistema inmune.',
          remember: 'Factores como la alimentación saludable, el ejercicio y el manejo de estrés contribuyen a preveir malestares asociados a la digestión. Si tienes síntomas como hinchazón, estreñimiento o diarrea, acude a un profesional.',
          advice: ['prefer', 'reduce'],
        },
        high: {
          asociation: 'Debido a tu genética, tienes **<REPLACE_NUMBER_PREDISPOSITION>** veces el riesgo de problemas de digestión, como hinchazón o dolor abdominal, en comparación con la población. Esto se puede generar por una respuesta alterada del sistema inmune.',
          remember: 'Factores como la alimentación saludable, el ejercicio y el manejo de estrés contribuyen a preveir malestares asociados a la digestión. Si tienes síntomas como hinchazón, estreñimiento o diarrea, acude a un profesional.',
          advice: ['prefer', 'avoid'],
        },
        genes: ['IRF6', 'FAM171B', 'FOXP1', 'IMPG2', 'CCND3', 'CUL1', 'PLCG2', 'FERMT1']
      },
    ]
  },
]

export function subcategoryToSpanish(english: keyof Record<string, string>): string {
  const dictionary: Record<string, string> = {
    corbohydrates: 'Asimilación de Carbohidratos',
    allergies_celiac: 'Enfermedad Celiaca',
    allergies_shrimp: 'Alergia al camarón',
    allergies_general: 'Susceptibilidad a alergias',
    allergies_peanut: 'Alergia al maní',
    allergies_lactose: 'Intolerancia a la lactosa',
    body_weight: 'Ganancia de Peso e IMC',
    body_lean: 'Masa Corporal Magra',
    cardiovascular_bloodpressure: 'Presión Arterial',
    cardiovascular_colesterol: 'Colesterol',
    cardiovascular_triglicerides: 'Triglicéridos',
    cardiovascular_general: 'Salud Cardiovascular General',
    vitamins_a: 'Vitamina A',
    vitamins_d: 'Vitamina D',
    vitamins_b12: 'Vitamina B12',
    vitamins_c: 'Vitamina C',
    vitamins_folicacid: 'Acído Fólico',
    vitamins_b6: 'Vitamina B6',
    wellness_ibd: 'Salud Intestinal',
    wellness_tobbaco: 'Tabaco',
    wellness_alcohol: 'Alcohol',
    wellness_selenium: 'Selenio',
    wellness_cupper: 'Cobre',
    wellness_zinc: 'Zinc'
  };

  return dictionary[english] || "";
}

export function categoryToSpanish(english: keyof Record<string, string>): string {
  const dictionary: Record<string, string> = {
    corbohydrates: 'Asimilación de Carbohidratos',
    allergies: 'Intolerancias y Alergias',
    body: 'Composición Corporal',
    cardiovascular: 'Salud Cardiovascular',
    vitamins: 'Metabolismo de vitaminas',
    wellness: 'Bienestar'
  }
  return dictionary[english] || "";
};