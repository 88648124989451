import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStateStore } from "@/store/store";

import Button from "react-bootstrap/Button";
import Container from 'react-bootstrap/Container';
import Pagination from "react-bootstrap/Pagination";
import Row from 'react-bootstrap/Row';
import Stack from "react-bootstrap/Stack";

import { validatePortions, foodGroupFilter } from "@/utils/fes";
import { LOWERCASE_DAY, MEAL, UTC_OFFSET, lowerDays, weekViewIndex } from "@/utils/constants";
import { dailyPortionsType, FesMeal, foodGroupHierarchy, FoodGroups, RecommendedFoodsType, weeklyViewType } from "@/models/fes-model";
import { mealIdsOrder } from "@/models/diet-model";

import { fesCalculatePortions, fesGetFoodList, fesGetPortions, fesGetRecommendedGroups } from "@/services/diet";

import FesCardComponent from "../FesCard";
import Spinner from "@/components/Spinner/Spinner";

import './FesComponent.scss';
import { FileEarmarkBarGraphFill } from 'react-bootstrap-icons';

export default function FesComponent() {
  const store = useStateStore();

  const navigate = useNavigate();

  const [mealTypes, setMealTypes] = useState<MEAL[] | null>(null);

  const [recommendedMealGroups, setRecommendedMealGroups] = useState<RecommendedFoodsType[] | null>(null);
  const [extraMealGroups, setExtraMealGroups] = useState<RecommendedFoodsType[] | null>(null)
  const [loadingGroups, setLoadingGroups] = useState<boolean>(false);

  const [allgroups, setAllgroups] = useState<string[]>();

  const [foodList, setFoodList] = useState<FesMeal[]>();
  const [loadingFoods, setLoadingFoods] = useState<boolean>(false);

  const [loadingPortions, setLoadingPortions] = useState<{ mealType: MEAL | null, day: LOWERCASE_DAY | null, loading: boolean }>({
    mealType: null, day: null, loading: false
  })

  const [portionsState, setPortionsState] = useState<dailyPortionsType>(
    lowerDays.reduce((acc, day) => {
      acc[day] = {
        breakfast: { portions: null },
        lunch: { portions: null },
        dinner: { portions: null },
        'morning-snack': { portions: null },
        'afternoon-snack': { portions: null },
      };
      return acc;
    }, {} as dailyPortionsType)
  );

  const {
    questionnaireData,
  } = store;

  const [currentViewIndex, setCurrentViewIndex] = useState<number | null>(null);
  const itemsPerPage = 1;

  const currentViewItem = useMemo(() => {
    if (!currentViewIndex) return null;

    // Calculate the index range for the current view
    const lastItemIndex = currentViewIndex * itemsPerPage;
    const firstItemIndex = lastItemIndex - itemsPerPage;
    return weekViewIndex.slice(firstItemIndex, lastItemIndex)[0]
  }, [currentViewIndex]);

  // Set the current view index to the current day
  useEffect(() => {
    let d = new Date().toLocaleDateString('es-CL', { weekday: 'long' }).toLowerCase() as LOWERCASE_DAY;
    setCurrentViewIndex(lowerDays.indexOf(d) + 1);
  }, [])


  // Handle page change
  const handlePageChange = (index: number) => {
    setCurrentViewIndex(index);
  };

  const canEdit = (d: LOWERCASE_DAY) => {
    let today = new Date().toLocaleDateString('es-CL', { weekday: 'long' }) as LOWERCASE_DAY;
    // return false if d is before today
    return lowerDays.indexOf(d) >= lowerDays.indexOf(today);
  }

  const canSeeDailyResults = (d: LOWERCASE_DAY) => {
    let today = new Date().toLocaleDateString('es-CL', { weekday: 'long' }) as LOWERCASE_DAY;
    return lowerDays.indexOf(d.toLowerCase() as LOWERCASE_DAY) <= lowerDays.indexOf(today);
  }

  const canSeeWeeklyResults = useMemo(() => {
    let today = new Date().toLocaleDateString('es-CL', { weekday: 'long' }) as LOWERCASE_DAY;
    return lowerDays.indexOf(today) === 6;
  }, []);

  const monday = useMemo(() => {
    const today = new Date();
    const dayOfWeek = today.getDay();
    const diff = (dayOfWeek === 0 ? -6 : 1) - dayOfWeek;
    const monday = new Date(today);
    monday.setDate(monday.getDate() + diff);
    return monday;
  }, []);

  const sunday = useMemo(() => {
    const sunday = new Date(monday);
    sunday.setDate(sunday.getDate() + 6);
    return sunday;
  }, [monday]);


  useEffect(() => {
    (async function () {
      if (currentViewItem) {
        await fesGetPortions(
          monday.toISOString().slice(0, 10),
          sunday.toISOString().slice(0, 10)
        )
          .then((response) => {
            let newPortionsState = { ...portionsState };
            response.forEach((portion) => {
              let d = new Date(portion.date + UTC_OFFSET).toLocaleDateString('es-CL', { weekday: 'long' }).toLowerCase() as LOWERCASE_DAY;
              newPortionsState[d][portion.mealType] = {
                portions: portion.portions,
                skipped: false,
              }
            })
            setPortionsState(newPortionsState);
          })
          .catch((e) => console.error(e));

        setLoadingGroups(true);

        try {
          const response = await fesGetRecommendedGroups(currentViewItem.ld)

          if (response && response.length > 0) {
            const filteredGroups = response.map(({ mealType, recommendedGroups }) => {
              return {
                mealType,
                recommendedGroups: recommendedGroups.filter((recommendedGroup: string) => {
                  return foodGroupFilter(mealType, recommendedGroup);
                })
              }
            })

            const sorted = (filteredGroups ?? response)
              .map(({ mealType, recommendedGroups }: RecommendedFoodsType) => {
                return {
                  mealType,
                  recommendedGroups: [
                    ...recommendedGroups.sort((a, b) =>
                      foodGroupHierarchy.indexOf(a as FoodGroups) - foodGroupHierarchy.indexOf(b as FoodGroups)),
                    'Todo'
                  ]
                }
              })
            setRecommendedMealGroups(sorted ?? response);
          }
        } catch (e) {
          console.error(`Error fetching groups ${e}`);
        }

        setLoadingGroups(false);

        setLoadingFoods(true);

        try {
          const response = await fesGetFoodList()

          setFoodList(response);

          let dto = response
            .map(food => {
              return [food.foodGroup1, food.foodGroup2, food.foodGroup3];
            })
            .flatMap(x => x)
            .filter(x => x !== null && x !== undefined && x.length > 0)

          let allfoods: string[] = []
          for (const group of dto) {
            if (group && !(allfoods.includes(group))) {
              allfoods.push(group)
            }
          }

          const sortedFoodGroups = allfoods.sort((a, b) =>
            foodGroupHierarchy.indexOf(a as FoodGroups) - foodGroupHierarchy.indexOf(b as FoodGroups)
          )

          setAllgroups(sortedFoodGroups ?? allfoods);

          setLoadingFoods(false);
        } catch (e) {
          console.error(e);
        }

      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentViewItem]);

  useEffect(() => {
    if (questionnaireData?.questionnaire?.mealsPerDayIds) {
      let sortedMeals = questionnaireData.questionnaire.mealsPerDayIds.sort((a, b) => mealIdsOrder.indexOf(a) - mealIdsOrder.indexOf(b))
      setMealTypes(sortedMeals as MEAL[]);
    }
  }, [questionnaireData]);

  const handleCalculatePortions = async (day: LOWERCASE_DAY, mealType: MEAL, recipeIds?: string[]) => {
    setLoadingPortions({ mealType, day, loading: true });

    try {
      let foodList = null;
      const calculatedDate = new Date(monday);
      calculatedDate.setDate(calculatedDate.getDate() + lowerDays.indexOf(day.toLowerCase() as LOWERCASE_DAY));

      foodList = await fesCalculatePortions(
        calculatedDate.toISOString().slice(0, 10),
        mealType,
        recipeIds ?? []
      );

      setPortionsState({
        ...portionsState,
        [day]: {
          ...portionsState?.[day],
          [mealType]: {
            portions: foodList,
            skipped: !foodList || foodList?.length === 0,
          }
        }
      });
      setLoadingPortions({ mealType: null, day: null, loading: false });

    } catch (error) {
      console.error(error);
    }
  }

  const setMealEditable = (day: LOWERCASE_DAY, mealType: MEAL) => {
    setPortionsState({
      ...portionsState,
      [day]: {
        ...portionsState?.[day],
        [mealType]: {
          portions: null,
          skipped: false,
        }
      }
    });
  }

  const RenderPaginationItems = () => {
    return (
      <Pagination>
        {
          weekViewIndex.map(({ ix, s, ld, cd }: weeklyViewType) => (
            <Pagination.Item
              key={ix + ""}
              active={ix === currentViewIndex}
              onClick={() => handlePageChange(ix)}
            >
              {s}
            </Pagination.Item>
          ))
        }
      </Pagination>
    )
  };

  const handleGetDailyReport = () => {
    try {
      if (!currentViewItem) return;

      let day = currentViewItem?.ld;

      if (!validatePortions(portionsState, day)) {
        alert('Tienes que haber seleccionado al menos un alimento por día para ver tu resumen');
        return;
      }

      const dayId = currentViewItem.ix;

      const reportDate = new Date(monday);
      reportDate.setDate(reportDate.getDate() + dayId - 1);

      const reportDateStr = reportDate.toISOString().slice(0, 10);

      navigate(`/diets/guide/report/daily`, {
        state: {
          goalId: questionnaireData?.questionnaire.goalId,
          date: reportDateStr,
          day: currentViewItem.cd,
        }
      });

    } catch (error: any) {
      console.error(error);
    }
  }

  const changeRecommendedGroups = (group: string, mealType: MEAL, extra: boolean, add: boolean) => {
    if (recommendedMealGroups) {
      if (!extra) {
        setRecommendedMealGroups(
          recommendedMealGroups.map(rg => {
            if (rg.mealType !== mealType) return rg;
            else {
              return {
                mealType: rg.mealType,
                recommendedGroups: add ? [...(rg.recommendedGroups), group] : rg.recommendedGroups.filter(g => g !== group)
              }
            }
          })
        )
      } else {
        if (!extraMealGroups) {
          let newExtra = recommendedMealGroups.map(rg => {
            if (mealType === rg.mealType) {
              return {
                mealType: rg.mealType,
                recommendedGroups: [group]
              }
            }
            return {
              mealType: rg.mealType,
              recommendedGroups: []
            }
          })
          setExtraMealGroups(newExtra);

        } else {
          setExtraMealGroups(
            extraMealGroups.map(rg => {
              if (rg.mealType !== mealType) return rg;
              else {
                return {
                  mealType: rg.mealType,
                  recommendedGroups: add ? [...(rg.recommendedGroups), group] : rg.recommendedGroups.filter(g => g !== group)
                }
              }
            })
          )
        }
      }
    }
  }

  return (
    <div className="d-flex flex-column align-items-center">
      <RenderPaginationItems />

      <div className='w-100 p-2'>
        {canSeeWeeklyResults ?
          <div className="options-container">
            <Button
              variant="primary"
              // onClick={() => props.saveMeals && props.saveMeals(selectedIds)}
              disabled={true}
              className="font-avenir-bold fes-button ms-auto"
            >
              <Stack direction="horizontal" gap={2}>
                <FileEarmarkBarGraphFill size={24} />
                <div>
                  Reporte Semanal (Próximamente)
                </div>
              </Stack>
            </Button>
          </div>
          : null
        }
        {currentViewItem && mealTypes && mealTypes?.length > 0 && !loadingFoods && !loadingGroups ?
          <div className='fes diet-day'>
            {mealTypes.map((meal, index) => {
              return (
                recommendedMealGroups && recommendedMealGroups.length > 0 &&
                  foodList && foodList?.length > 0 ?
                  <FesCardComponent
                    accordionElementId={'fes-accordion-item-' + index}
                    key={'fes-accordion-item-' + index}
                    mealType={meal}
                    recommendedMealGroups={recommendedMealGroups.filter(m => m.mealType === meal)}
                    day={currentViewItem.ld}
                    meals={foodList.sort((a, b) => b.score - a.score)}
                    calculatePortions={handleCalculatePortions}
                    portions={portionsState}
                    canEdit={canEdit(currentViewItem.ld)}
                    loadingPortions={loadingPortions}
                    setMealEditable={setMealEditable}
                    changeRecommendedGroups={changeRecommendedGroups}
                    allgroups={allgroups}
                    extraMealGroups={extraMealGroups}
                  />
                  : null
              )
            })}
            {currentViewItem && canSeeDailyResults(currentViewItem.ld)
              && mealTypes && mealTypes?.length > 0
              && !loadingFoods && !loadingGroups ?
              <div className="fes-button-row mx-2">
                <Button
                  id={`fes-calculate-daily-report-btn`}
                  onClick={handleGetDailyReport}
                  className="font-avenir-bold fes-button mx-2"
                >
                  <Stack direction="horizontal" gap={2}>
                    <FileEarmarkBarGraphFill size={24} />
                    <div>
                      Reporte Diario
                    </div>
                  </Stack>
                </Button>
              </div>
              : null
            }
          </div>
          :
          <Container>
            <Row className="justify-content-center">
              <Spinner size='medium' rotate={true} />
            </Row>
            <Row >
              <h4 className="font-avenir-bold" style={{ textAlign: 'center' }}>
                Cargando...
              </h4>
            </Row>
          </Container>
        }

      </div>

    </div>
  );
}
