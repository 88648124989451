import Button from 'react-bootstrap/Button';

type MynuButtonComponentProps = {
  text: string;
  variant?: string;
  isDisabled?: boolean;
  minWidth?: string;
  minHeight?: string;
  onClick?: () => void;
}

export default function MynuButtonComponent(props: MynuButtonComponentProps) {
  const {
    text,
    variant,
    onClick,
    isDisabled = false
  } = props;

  return (
    <Button
      variant={variant}
      type="submit"
      className="landing-button m-3 spaced-2 subsub-text"
      disabled={isDisabled}
      onClick={onClick}
    >
      {text}
    </Button>
  )
}

