import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useCurrentUser } from "@/components/AuthContext";
import { useStateStore } from "@/store/store";
import { loadUserInfo } from "@/store/loader";

import Stack from 'react-bootstrap/Stack';

import MainLayout from "@/components/MainLayout/MainLayout";
import Spinner from "@/components/Spinner/Spinner";
import { NotFoundPage } from "@/router/404Page";
import BackButton from "@/components/BackButton/BackButtton";

import DailyConsumptionScoreComponent from "./components/fesReportPage/DailyConsumptionScore";
import Macronutrients from "./components/fesReportPage/MacroNutrients";
import MicroNutrients from "./components/fesReportPage/MicroNutrients";
import CriticalNutrients from "./components/fesReportPage/CriticalNutrients";
import CaloriesComponent from "./components/fesReportPage/Calories";
import IngestedFoodQuality from "./components/fesReportPage/IngestedFoodQuality";

import { fesReportType } from "@/models/fes-model";
import { fesGetDailyReport } from "@/services/diet";

import {objectives} from "./constants";
import { DAY, UTC_OFFSET } from "@/utils/constants";

export default function FesReportsPage() {
  const currentUser = useCurrentUser();
  const store = useStateStore();
  const [loading, setLoading] = useState<boolean>(false);

  // params from navigation
  const { state } = useLocation();

  const [fesReport, setFesReport] = useState<fesReportType | null>(null);
  const [goalId, setGoalId] = useState<objectives | null>(null);
  const [day, setDay] = useState<DAY | null>(null);

  useEffect(() => {
    if (currentUser) {
      loadUserInfo(store);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  useEffect(() => {
    setLoading(true);
    if (state && state.hasOwnProperty('goalId') && state.hasOwnProperty('date')) {
      fesGetDailyReport(state.date)
        .then((response) => {
          setGoalId(state.goalId);
          setFesReport(response);
          setDay(state.day);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [state]);
  return (
    !loading && fesReport &&
      fesReport.nutrients && fesReport.nutrients.length > 0
      && goalId && day ?
      <MainLayout>
        <Stack direction="vertical" gap={2} className="my-4 pt-3 px-0 reports">
          <div className="reports-header" >
            <div className="d-flex flex-row gap-2 align-items-center">
              <BackButton text={""} />
              <h4> Mi Reporte Diario </h4>
            </div>
            <div className="px-2 header-title-right">
              <div className="m-1" style={{ fontSize: '1.4em' }}>{day}</div>
              <div className="m-1"> {new Date(state.date + UTC_OFFSET).toLocaleDateString('es-CL',)} </div>
            </div>
          </div>

          <div className="reports-main-container px-2">
            <Stack direction="vertical" gap={2} className="item1">
              <DailyConsumptionScoreComponent
                score={fesReport.overallScore.toFixed(1)}
                lackingSubcategories={fesReport.improvementSections}
              />
              <Macronutrients
                goal={goalId}
                protein={fesReport.nutrients.find(n => n.name === 'protein') ?? null}
                carbs={fesReport.nutrients.find(n => n.name === 'carbs') ?? null}
                fat={fesReport.nutrients.find(n => n.name === 'fat') ?? null}
              />
            </Stack>
            <Stack direction="vertical" gap={2} className="item2">
              <CaloriesComponent
                goal={goalId}
                calories={fesReport.nutrients.find(n => n.name === 'calories')}
              />
              <MicroNutrients
                nutrients={fesReport.nutrients}
              />
            </Stack>
            <Stack direction="vertical" gap={2} className="item3">
              <IngestedFoodQuality
                score={fesReport.recipeScoresSummary.average ?? 0}
              />
              <CriticalNutrients
                fiber={fesReport.nutrients.find(n => n.name === 'fiber')}
                omega3={fesReport.foodVariety.find(f => f.label === 'omega3')}
              />
            </Stack>
          </div>

        </Stack>
      </MainLayout >
      : loading ?
        <MainLayout>
          <div className="d-flex flex-column align-items-center">
            <Spinner size="large" rotate />
            <h1>
              Cargando
            </h1>
          </div>
        </MainLayout>
        :
        <NotFoundPage />
  );
}