import { Check2Circle, X } from "react-bootstrap-icons";
import Stack from "react-bootstrap/esm/Stack";

interface BadgeCheckProps {
  title: string;
  ok: boolean;
  description?: string | null;
  recommended?: string[] | null;
  icons: string[] | null;
}

export default function BadgeCheck(props: BadgeCheckProps) {
  const { title, ok, description, recommended, icons } = props;
  return (
    title ?
      <div className="flex-container flex-column">
        <div
          style={{ borderRadius: '18px', border: '0.5px solid lightgray', backgroundColor: '#FAFAFA' }}
          className="font-avenir-bold py-1 px-3 mb-3 d-flex flex-row align-items-center"
        >
          <div className="mynu-stars" style={{ 'borderRadius': '100%' }}>
            {ok ?
              <Check2Circle size={24} className="mx-1" />
              : <X size={24} />
            }
          </div>
          <div > {title} </div>
        </div>
        {description ?
          <>{description}</>
          : null
        }
        {!ok && recommended && icons ?
          <Stack direction="horizontal" gap={2} className="my-3 fes-daily-report-stack">
            {recommended.map((r, i) => (
              <Stack direction='vertical' gap={2} key={`recommended-foods-badges${i}`} className="d-flex flex-column align-items-center">
                <div className={`fiber-img-${icons[i]}`} />
                <span>{r}</span>
              </Stack>
            ))}
          </Stack>
          : null
        }
      </div>
      : null
  )
}
