import Form from 'react-bootstrap/Form';

import MynuTooltip from "@/components/Tooltip/Tooltip";
import { BuyKitFormProps, FormIds } from "../../BuyKitPage";

import { comunas_regiones } from "../../comunas-regiones";

const DEFAULT_REGION_OPTION = 'Región Metropolitana de Santiago';

export default function DeliveryStep(props: BuyKitFormProps) {

  const {
    register,
    errors
  } = props;

  const provinceOptions = comunas_regiones?.regiones.find(r => r.region === DEFAULT_REGION_OPTION)?.comunas ?? [];

  return (
    <div className="delivery">
      <Form.Group className="my-2" controlId={'buy-kit-form-city'}>
        <Form.Label className="buy-kit-form-label mb-1">Ciudad</Form.Label>
        <MynuTooltip description="Por el momento solo se entregan kits en Santiago">
          <Form.Control
            disabled
            name={'city'}
            type={'text'}
            placeholder={'Santiago'}
          />
        </MynuTooltip>
      </Form.Group>
      <Form.Group className="my-2" controlId={'buy-kit-form-reg'}>
        <Form.Label className="buy-kit-form-label mb-1">Región</Form.Label>
        <MynuTooltip description="Por el momento solo se entregan kits en la Región Metropolitana">
          <Form.Control
            disabled
            name={'region'}
            type={'text'}
            placeholder={'Región Metropolitana de Santiago'}
          />
        </MynuTooltip>
      </Form.Group>

      <Form.Group className="my-2" controlId={'buy-kit-form-address'} key={'address'}>
        <Form.Label className="buy-kit-form-label mb-1">Dirección</Form.Label>
        <Form.Control
          {...register(FormIds.ADDRESS)}
          type={'text'}
          placeholder={'Av. Américo Vespucio 123'}
          className={errors?.[FormIds.ADDRESS]?.message ? 'is-invalid' : ''}
        />
        {errors?.[FormIds.ADDRESS]?.message ?
          <Form.Text className="text-danger form-assistive-text">
            {errors?.[FormIds.ADDRESS]?.message}
          </Form.Text>
          : null
        }
      </Form.Group>

      <Form.Group className="my-2" controlId={'buy-kit-form-address2'} key={'address2'}>
        <Form.Label className="buy-kit-form-label mb-1">Info adicional (ej: depto/oficina)</Form.Label>
        <Form.Control
          {...register(FormIds.ADDRESS2)}
          type={'text'}
          placeholder={'Oficina 204'}
        />
      </Form.Group>

      <Form.Group className="mb-2" controlId={'buy-kit-form-province'}>
        <Form.Label className="buy-kit-form-label mb-1">Comuna</Form.Label>
        <Form.Select
          {...register(FormIds.PROVINCE)}
          name={'province'}
          className={errors?.[FormIds.PROVINCE]?.message ? 'is-invalid' : ''}
          defaultValue={''}
        >
          <option disabled value={''}>Selecciona una comuna</option>
          {
            provinceOptions?.map((c, ix) => {
              return <option key={ix} value={c}>{c}</option>
            })
          }
        </Form.Select>
        {errors?.[FormIds.PROVINCE]?.message ?
          <Form.Text className="text-danger form-assistive-text">
            {errors?.[FormIds.PROVINCE]?.message}
          </Form.Text>
          : null
        }
      </Form.Group>
    </div>
  )
}