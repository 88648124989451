import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import ArrowIcon from '@/assets/icons/arrow.svg';

import './SubscriptionBanner.scss';

type BannerProps = {
  type: string;
  onClick?: () => void;
}

function SubscriptionBanner({ type, onClick }: BannerProps) {
  if (type === 'freemium') {
    return (
      <Row className='banner-alert'>
        <Col md={{ span: 11 }}>
          <span className="text">¡Transforma tu bienestar! Suscríbete hoy y disfruta de acceso ilimitado a todas nuestras dietas exclusivas.</span>
          <span className="text"><br />Pruébalo gratis durante 7 días, y luego continua con beneficios ilimitados en tu suscripción.</span>
        </Col>
      </Row>
    )
  }
  if (type === 'expiredAlert') {
    return (
      <Row className='banner-expired-alert'>
        <Col md={{ span: 11 }}>
          <span className="text">¡No te quedes atrás! Suscríbete y desbloquea dietas ilimitadas ahora.</span>
        </Col>
      </Row>
    )
  }
  if (type === 'expiredWarning') {
    return (
      <Row className='banner-warning-alert'>
        <Col md={{ span: 11 }}>
          <span className="text">Parece que tu suscripción ha caducado, para seguir disfrutando de los beneficios suscríbete</span>
        </Col>
        <Col md={{ span: 1 }}>
          <img src={ArrowIcon} alt="arrow" className='button' onClick={onClick} />
        </Col>
      </Row>
    )
  }

  return <></>
}

export default SubscriptionBanner;
