import { useMemo, useState } from "react";

import Form from "react-bootstrap/esm/Form";
import Button from "react-bootstrap/esm/Button";

import RenderRecipeScore from "./RenderRecipeScore";

import { FesMeal } from "@/models/fes-model";
import { MYNU_PRIMARY_COLOR } from "@/utils/constants";

import { SortAlphaDown, SortAlphaUpAlt, XCircleFill } from "react-bootstrap-icons";

interface RenderResultsProps {
  id: string,
  renderOptions: FesMeal[] | null,
  handleSelect: (id: string) => void,
  selectedIds: string[],
}

export default function RenderResults(props: RenderResultsProps) {
  const { id, renderOptions, handleSelect, selectedIds } = props;

  const [sortValue, setSortValue] = useState<boolean | null>(null);

  const optionsState = useMemo(() => {
    let sorted = renderOptions ?? [];
    if (sorted) {
      if (sortValue === null) {
        return sorted?.sort((a, b) => b.score - a.score);
      };

      if (sortValue) {
        sorted = sorted?.sort((a, b) => a.recipeName < b.recipeName ? 1 : -1);
      } else {
        sorted = sorted?.sort((a, b) => a.recipeName > b.recipeName ? 1 : -1);
      }
    }
    return sorted;
  }, [renderOptions, sortValue]);

  return (
    <div className='p-2'>
      {optionsState && optionsState.length > 0 ?
        <div className='p-1 m-1 search-filters' >
          <div className='ms-auto'>
            {!sortValue ?
              <Button variant='outline-primary' className='mx-1' onClick={() => setSortValue(true)} >
                <SortAlphaDown size={20} />
              </Button>
              :
              <Button variant='outline-primary' className='mx-1' onClick={() => setSortValue(false)} >
                <SortAlphaUpAlt size={20} />
              </Button>
            }
            {sortValue !== null ?
              <XCircleFill size={20} onClick={() => setSortValue(null)} color={MYNU_PRIMARY_COLOR} />
              : null
            }
          </div>
        </div>
        : null
      }
      {optionsState && optionsState.length > 0 ?
        optionsState.map(({ recipeId, recipeName, score }: FesMeal, ix: number) => (
          <div id={`${id}-textbox-result-${ix}`} key={ix}
            className='d-flex flex-row align-items-center mynu-outline point p-1 m-1'
            onClick={() => handleSelect(recipeId)} >
            <div className='m-1 px-2 d-flex'>
              <Form.Check
                required={false}
                name={recipeName}
                type={'checkbox'}
                id={recipeId}
                onChange={() => { }}
                {
                ...{ checked: selectedIds.includes(recipeId), }
                }
              />
            </div>
            <div className='m-1 d-flex flex-grow-1'> {recipeName} </div>

            <RenderRecipeScore score={score} size={'15px'} />
          </div>
        ))
        :
        <div className='text-center' style={{ margin: '2rem' }}>
          <span className='mb-3'><h1 className='mynu-stars'>{":'("}</h1></span>
          <div className='mt-3'>No se encontraron resultados similares </div>
        </div>
      }
    </div>
  )
}


