import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { useIsIOSWebView } from "@/hooks/mobilePlatforms";

import SubscriptionImage from '@/assets/images/subscription-adn.svg';
import SubscriptionImageMobile from '@/assets/images/subscription-adn-mobile.svg';
import SubscriptionIcon from '@/assets/icons/subscription-icon.svg';

export const MoreInfoKit = () => {
  const isNativeIOS = useIsIOSWebView();

  return (
    <Card className="mb-4 home-card">
      <Container className="card-mobile">
        <Row>
          <Col lg="auto" xs={12} className="px-0 image-container me-3">
            <img src={SubscriptionImage} alt="" className="adn-image d-none d-lg-block" width={366} />
            <img src={SubscriptionImageMobile} alt="" className="adn-image d-flex d-lg-none" />
          </Col>
          <Col>
            <Row>
              <Col lg={8} xs={12} className="lg-0 pt-3">
                <h2 className="home-title subtitle card-title">
                  <img src={SubscriptionIcon} alt="" className="me-2" />
                  ¿Por qué comprar un kit genético?
                </h2>
              </Col>
              <Col className="home-button-container pt-3 mb-2">
                {isNativeIOS ?
                  <div>Más Info en mynu.ai/ciencia-conciencia</div>
                  :
                  <a href='https://mynu.ai/ciencia-conciencia/' target="_blank" rel="noreferrer">
                    Quiero Saber Más!
                  </a>
                }
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Card>
  )
}