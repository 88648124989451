import React, { useState, useEffect, useMemo, useRef } from 'react';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Popover from 'react-bootstrap/Popover';
import Overlay from 'react-bootstrap/Overlay';

import { DashCircleFill, X } from 'react-bootstrap-icons';

import { FesMeal } from '@/models/fes-model';
import { normalizeString } from '@/utils';
import { RenderFoodRecommendation } from './RenderFoodRecommendation';
import RenderResults from './RenderSearchResults';

const { Control } = Form;

interface SearchComponentProps {
  id: string,
  group: string;
  options: FesMeal[],
  rootSelectedIds: { [group: string]: string[] },
  handleRootSelection: (group: string, clickedItemId: string) => void;
  changeRecommendedGroups: (group: string, add: boolean) => void;
  extra?: boolean
}

export default function FesSearchComponent(props: SearchComponentProps) {
  const {
    id,
    group,
    options,
    rootSelectedIds,
    handleRootSelection,
    changeRecommendedGroups,
    extra
  } = props;

  const selectedIds = useMemo(() => rootSelectedIds[group] ?? [], [rootSelectedIds, group]);

  const [renderOptions, setRenderOptions] = useState<FesMeal[] | null>(null);

  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (options && options.length > 0) {
      setRenderOptions(options);
    }
  }, [options]);

  const filterOptions = (search: string) => {
    const normalizedSearch = normalizeString(search);

    const filtered = options.filter(({ recipeName, tags }: FesMeal) => {
      const normalizedRecipeName = normalizeString(recipeName);
      const parsedTags = tags.map(s => s.replaceAll('_', ' '))
      return normalizedRecipeName.includes(normalizedSearch) || parsedTags.some(t => t.includes(normalizedSearch));
    });

    if (search.length === 0) {
      setRenderOptions(options);
    } else {
      setRenderOptions(filtered);
    }
  }

  const handleTextChange = (searchString: string) => {
    setSearchText(searchString);
    setTimeout(() => {
      filterOptions(searchString);
    }, 200);
  }

  const clearInput = () => {
    setSearchText('');
    filterOptions('');
  }

  // overlay setup
  const [showOverlay, setShowOverlay] = useState(false);
  const inputSearchRef = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);

  // Close the popover if clicked outside
  const handleClickOutside = (event: MouseEvent) => {
    if (inputSearchRef.current && !inputSearchRef.current.contains(event.target as Node) &&
      popoverRef.current && !popoverRef.current.contains(event.target as Node)
    ) {
      setShowOverlay(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div id={id} className={`${extra ? 'extra' : undefined} pb-2 fes-search-component mynu-shadow`}>
      <div className={`fes-search-component-header`}>
        <div className='item1'>
          {group}
        </div>
        <div className='d-flex flex-column align-items-end item2'>
          <DashCircleFill
            size={21}
            className="ms-auto mynu-stars point"
            onClick={() => changeRecommendedGroups(group, false)}
          />
        </div>

        <div className={`item3`} ref={inputSearchRef}>
          <InputGroup >
            <Control
              id={`${id}-textbox`}
              placeholder={'🔎 Escoge un alimento'}
              type="text"
              onFocus={() => setShowOverlay(true)}
              value={searchText}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTextChange(e.target.value)}
            />
            {searchText && (
              <Button variant="outline-primary" onClick={clearInput} size='sm'
                style={{
                  backgroundColor: '#fff',
                  border: 'none',
                  padding: '0px',
                  margin: '0px',
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
              >
                <InputGroup.Text
                  style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} >
                  <X size={25} />
                </InputGroup.Text>
              </Button>
            )}

          </InputGroup>

        </div>
        <Overlay target={inputSearchRef.current} show={showOverlay} placement='bottom-start' >
          {(props) =>
            <Popover {...props} id='search-results' className='popover-container'>
              <div ref={popoverRef} className='m-1 popover-content'>
                <RenderResults
                  id={`${id}-textbox`}
                  renderOptions={renderOptions}
                  handleSelect={id => handleRootSelection(group, id)}
                  selectedIds={selectedIds}
                />
              </div>
            </Popover>
          }
        </Overlay>
      </div>
      {
        selectedIds.length > 0 ?
          <RenderFoodRecommendation
            meals={options}
            selectedIds={selectedIds}
            group={group}
            handleClick={(id) => handleRootSelection(group, id)}
          />
          : null
      }
    </div>
  )
}
