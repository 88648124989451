import { memo } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

import { DisplayChatMessage, MessageRate } from '@/models';
import { MessagesListProps } from "@/models";
import { days } from '@/utils/constants';

import './ChatPage.scss';
import {
  HandThumbsDown,
  HandThumbsDownFill,
  HandThumbsUp,
  HandThumbsUpFill
} from 'react-bootstrap-icons';
import MynuAvatar from '@/assets/images/mynu-avatar.svg';
import { WELCOME_MESSAGE_CONTENT } from './default-messages';


const MessagesList: React.FC<MessagesListProps> = memo((props: MessagesListProps) => {
  const { messages, dots, handleSendMessageDay, messageRate } = props;

  const navigate = useNavigate();

  return (
    <>
      {messages.map((message: DisplayChatMessage, index: number) => {
        return (<Row key={index} className="p-2 px-2 message-container d-flex">
          {message.role === 'user' ? (
            <div className="text-start d-flex flex-row justify-content-end align-items-center w-100">
              <div className="message user-message">
                <span> {message.content} </span>
              </div>
            </div>
          ) : (
            <Row className="align-self-center">
              <Col xs>
                <Image src={MynuAvatar || ''} roundedCircle width={24} />
              </Col>
              <Col xs={10} md={10} lg={11} className="text-start align-self-start">
                {message.type === 'buyTest' ?
                  <div>
                    <Button className="btn btn-primary button-subscribe" onClick={() => { navigate('/buy-kit') }}
                    >
                      Comprar test
                    </Button>
                  </div>
                  : null}
                {message.type === 'days' ?
                  days.map((day) =>
                    <Button key={day} className="btn btn-primary button-subscribe" onClick={() => handleSendMessageDay(day)}>
                      {day}
                    </Button>
                  )
                  : null
                }
                {!message.type ?
                  <div className='bot-message-container '>
                    <div className={`message assistant-message`}>
                      {message.content.includes('Escribiendo...') ?
                        <div className={`fixed-width`}>Escribiendo{dots}</div>
                        :
                        <Markdown remarkPlugins={[remarkGfm]}>{message.content}</Markdown>
                      }
                    </div>
                    {!(message.content.includes('Escribiendo') && !(message.content.includes(WELCOME_MESSAGE_CONTENT))) ?
                      <div className='rate-msg'>
                        <Button variant='outline' onClick={
                          () => { messageRate(MessageRate.LIKE, message) }}
                        >
                          {message.rate && message.rate === MessageRate.LIKE ?
                            <HandThumbsUpFill className='like' />
                            : <HandThumbsUp className='like' />
                          }
                        </Button>
                        <Button variant='outline' onClick={
                          () => { messageRate(MessageRate.DISLIKE, message) }}
                        >
                          {message.rate && message.rate === MessageRate.DISLIKE ?
                            <HandThumbsDownFill className='dislike' />
                            : <HandThumbsDown className='dislike' />
                          }
                        </Button>
                      </div>
                      : null}
                  </div>
                  : null}
              </Col>
            </Row>
          )}
        </Row>)
      })}
    </>
  );
});



export default MessagesList;
